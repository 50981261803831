// CalendarCopyFromHistory.js

import React, { useState, useContext } from 'react';
import { Dialog, Button, Checkbox, Select, MenuItem } from '@material-ui/core';
import { CartContext } from 'contexts/cart_context';
import './calendar_copy_from_history.css';
import { CloseRounded } from '@material-ui/icons';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import AddFromHistoryIcon from 'images/add-from-history-icon.png';

const CalendarCopyFromHistory = (props) => {
    const [open, setOpen] = useState(false);
    const [selectedMeals, setSelectedMeals] = useState({});
    const [selectedCategory, setSelectedCategory] = useState(props.calendarCategory);
    const cartContext = useContext(CartContext);
    const { cartObject, addMealsFromCopy } = cartContext;

    const handleOpen = (event) => {
        event.stopPropagation(); // Prevent click from propagating to parent components
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedMeals({});
        setSelectedCategory(props.calendarCategory); // Reset selected category
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        // Do not reset selected meals when category changes
    };

    // Get the current date to filter out future meals
    const currentDate = new Date(props.calendarDate);
    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    // Get all past meals within the last 30 days
    let pastMeals = cartObject.filter((meal) => {
        const mealDate = new Date(meal.calendarDate);
        return (
            mealDate >= thirtyDaysAgo &&
            mealDate < currentDate
        );
    });

    // Remove duplicates by meal content
    const uniqueMeals = [];
    const mealSignatures = new Set();

    pastMeals.forEach((meal) => {
        // Use meal.id or generate a unique signature
        const signature = meal.id || JSON.stringify({
            name: meal.name,
            ingredients: meal.ingredients,
            // Include other attributes that define a unique meal
        });

        if (!mealSignatures.has(signature)) {
            mealSignatures.add(signature);
            uniqueMeals.push({ ...meal, signature });
        }
    });

    // Sort past meals by most recent date
    uniqueMeals.sort(
        (a, b) => new Date(b.calendarDate) - new Date(a.calendarDate)
    );

    const handleMealSelection = (mealSignature) => {
        setSelectedMeals((prevState) => ({
            ...prevState,
            [mealSignature]: !prevState[mealSignature],
        }));
    };

    const handleCopyMeals = () => {
        const mealsToCopy = uniqueMeals.filter(meal => selectedMeals[meal.signature]);

        if (mealsToCopy.length > 0) {
            // Create new meal objects without mutating the originals
            const newMeals = mealsToCopy.map((meal) => {
                const newMeal = { ...meal };
                newMeal.calendarDate = props.calendarDate;
                // Keep the original category
                // newMeal.calendarCategory = props.calendarCategory;
                newMeal.id = undefined; // Let addMealsFromCopy assign new IDs
                return newMeal;
            });

            FirebaseAnalytics.logEvent({
                name: 'added_meals_to_calendar_from_history',
            });

            // Use addMealsFromCopy to add meals in bulk
            addMealsFromCopy(newMeals, props.calendarDate, props.calendarCategory);
        }

        handleClose();
    };

    // Filter meals for the selected category for display purposes
    const filteredMeals = uniqueMeals.filter(meal => meal.calendarCategory === selectedCategory);

    // Render the icon with appropriate styling
    return (
        <div className="calendar-copy-from-history">

            <img
                src={AddFromHistoryIcon}
                alt="Copy from history"
                className="calendar-copy-from-history-icon"
                onClick={handleOpen}
                style={{
                    cursor: 'pointer',
                    height: '21px',
                    marginLeft: '15px',
                    marginRight: '15px',
                }}
            />

            <Dialog open={open} onClose={handleClose} maxWidth="md">

                <div className="calendar-copy-from-history-dialog">
                    <Button className="calendar-meal-delete-button" onClick={handleClose}>
                        <CloseRounded style={{ width: '17px', height: '17px', marginTop: '9px', marginRight: '5px' }} />
                    </Button>
                    <div style={{ marginTop: '0px', marginBottom: '5px', marginLeft: '10px', fontSize: '18px', color: '#222' }}>Add From Recent</div>

                    <div style={{ marginTop: '0px', marginBottom: '5px', marginLeft: '10px', fontSize: '14px', color: '#444' }}>
                        Showing 30 day history for{' '}
                        <Select
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                            style={{ minWidth: '100px' }}
                        >
                            <MenuItem value="breakfast">Breakfast</MenuItem>
                            <MenuItem value="lunch">Lunch</MenuItem>
                            <MenuItem value="dinner">Dinner</MenuItem>
                            <MenuItem value="snack">Snack</MenuItem>
                        </Select>
                    </div>

                    {filteredMeals.length === 0 ? (
                        <div style={{ marginTop: '20px', marginLeft: '10px', marginRight: '10px', fontSize: '14px', color: '#888' }}>
                            No past meals found in the last 30 days
                        </div>
                    ) : (
                        <div className="meal-history-list">
                            {filteredMeals.map((meal) => (
                                <div key={meal.signature} className="meal-history-item">
                                    <div className="meal-history-checkbox">
                                        <Checkbox
                                            checked={!!selectedMeals[meal.signature]}
                                            onChange={() => handleMealSelection(meal.signature)}
                                        />
                                    </div>
                                    {meal.picture && (
                                        <div className="history-dialog-meal-image-wrapper">

                                            <img
                                                src={meal.picture}
                                                alt={meal.name}
                                                className="history-dialog-meal-picture"
                                            />
                                        </div>
                                    )}
                                    <div className="history-dialog-meal-info">
                                        <div className="history-dialog-meal-name">{meal.name}</div>
                                        <div className="history-dialog-meal-date">
                                            Last eaten on:{' '}
                                            {new Date(meal.calendarDate).toLocaleDateString()}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="dialog-actions">
                        <Button onClick={handleClose} style={{ marginRight: '10px' }}>
                            Cancel
                        </Button>
                        <button
                            className={Object.values(selectedMeals).filter(Boolean).length === 0 ? "disabled-button" : "submit-button"}
                            style={{ alignItems: 'center' }}
                            onClick={handleCopyMeals}
                            disabled={
                                Object.values(selectedMeals).filter(Boolean).length === 0
                            }
                        >
                            Add Selected Meals
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default CalendarCopyFromHistory;
