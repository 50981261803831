import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function SwipeableTemporaryDrawer({ actionSheetOpen, setActionSheetOpen, onChange, list, selected }) {
    const classes = useStyles();

    const listItem = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={() => setActionSheetOpen(false)}
            onKeyDown={() => setActionSheetOpen(false)}
        >
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                <CloseIcon onClick={() => setActionSheetOpen(false)} />
            </div>

            <List>
                {list.map((text, index) => (
                    
                    <div
                        key={text}
                        onClick={() => { onChange(index) }}
                        style={{ padding: '10px',marginLeft: '10px' }}
                    >
                        <div style={{display:'flex', alignItems:'center'}}>
                            {<><DoneIcon style={{color:selected && selected.includes(text)?'#3C8241':'white', marginRight:'6px'}}></DoneIcon>{text}</>}
                        </div>
                    </div>
                ))}
            </List>
        </div>
    );

    return (
        <div>
            <SwipeableDrawer
                anchor={'bottom'}
                open={actionSheetOpen}
                onClose={() => setActionSheetOpen(false)}
                onOpen={() => setActionSheetOpen(true)}
            >
                {listItem('bottom')}
            </SwipeableDrawer>
        </div>
    );
}
