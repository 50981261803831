import './SwipeableItem.css';
import React, { useContext, useState, useRef, useEffect } from 'react';

import { CartContext } from 'contexts/cart_context';
import Button from '@material-ui/core/Button';
import CaloriesBadge from 'images/calories_badge.png';
import ContentCopy from 'images/content_copy.svg';

import MealDetailsCard from '../meal_details_card/meal_details_card';
import { Dialog, Tooltip } from '@material-ui/core';
import { decimalToFraction, getMealNutrientAmount } from 'helpers/recipe_helpers';
import _ from 'lodash';
import CopyMealsToDay from '../copy_meals_to_day/copy_meals_to_day';
import DeleteIcon from '@material-ui/icons/Delete';

// Add macro units and styles
const macroUnits = {
  Protein: 'g',
  Carbohydrates: 'g',
  Fat: 'g',
  Sodium: 'mg',
  Cholesterol: 'mg',
  Sugar: 'g',
  Fiber: 'g',
  'Net Carbohydrates': 'g',
  'Saturated Fat': 'g',
};

const macroStyles = {
  Protein: { background: '#24C581' },
  Carbohydrates: { background: '#25ABFF' },
  Fat: { background: '#FF802C' },
  Sodium: { background: '#9F48E2' },
  Cholesterol: { background: '#E751CF' },
  Sugar: { background: '#86D1FF' },
  Fiber: { background: '#8D9FFF' },
  'Net Carbohydrates': { background: '#2344BB' },
  'Saturated Fat': { background: '#C72100' },
};

const CalendarMealNoDrag = (props) => {
  const deleteMeal = useContext(CartContext).deleteMeal;
  const setCartMeal = useContext(CartContext).setCartMeal;

  const [copyToDayDialogOpen, setCopyToDayDialogOpen] = useState(false);

  const getMealAttributeWithOptions = useContext(CartContext).getMealAttributeWithOptions;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [portion, setPortion] = useState(props.cartMeal.portion ? props.cartMeal.portion : 1);
  const [startX, setStartX] = useState(null);
  const [swipeDistance, setSwipeDistance] = useState(0);

  const childRef = useRef(null);
  const parentRef = useRef(null);
  const [parentHeight, setParentHeight] = useState(0);

  useEffect(() => {
    if (childRef.current) {
      const childHeight = childRef.current.offsetHeight;
      setParentHeight(childHeight);
    }
  }, [props.cartMeal]); // Update when props.cartMeal changes

  useEffect(() => {
    if (parentRef.current) {
      parentRef.current.style.height = `${parentHeight}px`;
    }
  }, [parentHeight]);

  function openCopyDaysDialog() {
    setCopyToDayDialogOpen(true);
  }

  function exitCopyDaysDialog() {
    setCopyToDayDialogOpen(false);
  }

  function handleDeleteClick(event) {
    deleteMeal(props.cartMeal);
    event.stopPropagation();
  }

  function handleDialogOpen(event) {
    if (event.target.tagName !== 'SPAN' && event.target.id !== 'calendar-meal-duplicate') {
      setIsDialogOpen(true);
    }
  }

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  const setMealAndRecalculateCalories = (meal) => {
    for (let mealNutrient of meal.nutrition.nutrients) {
      mealNutrient['amount'] = 0;
      for (let ingredient of meal.ingredients) {
        for (let ingredientNutrient of ingredient.nutrition.nutrients) {
          if (mealNutrient.name === ingredientNutrient.name) {
            if (ingredientNutrient['amount'] && ingredient['amount']) {
              mealNutrient['amount'] += ingredientNutrient['amount'] * ingredient['amount'];
            }
          }
        }
      }
    }
    setCartMeal(meal);
  };

  useEffect(() => {
    if (portion !== props.cartMeal.portion) {
      setPortion(props.cartMeal.portion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cartMeal]);

  useEffect(() => {
    const updatePortion = setTimeout(() => {
      if (props.cartMeal.portion === portion) {
        return;
      }

      let mealClone = _.cloneDeep(props.cartMeal);

      if (mealClone.areNutrientsManual) {
        for (let mealNutrient of mealClone.nutrition.nutrients) {
          if (mealNutrient.name === 'Calories') {
            mealNutrient.amount =
              Math.round((mealNutrient.amount * portion) / props.cartMeal.portion * 10) / 10;
          }
          if (mealNutrient.name === 'Fat') {
            mealNutrient.amount =
              Math.round((mealNutrient.amount * portion) / props.cartMeal.portion * 10) / 10;
          }
          if (mealNutrient.name === 'Carbohydrates') {
            mealNutrient.amount =
              Math.round((mealNutrient.amount * portion) / props.cartMeal.portion * 10) / 10;
          }
          if (mealNutrient.name === 'Protein') {
            mealNutrient.amount =
              Math.round((mealNutrient.amount * portion) / props.cartMeal.portion * 10) / 10;
          }
        }
        setCartMeal({ ...mealClone, portion: portion });
      } else {
        if (props.cartMeal.portion) {
          for (let ingredient of mealClone.ingredients) {
            ingredient.amountString = decimalToFraction(
              (ingredient.amount * portion) / props.cartMeal.portion
            );
            ingredient.amount = (ingredient.amount * portion) / props.cartMeal.portion;
          }
        } else {
          for (let ingredient of mealClone.ingredients) {
            ingredient.amountString = decimalToFraction(ingredient.amount * portion);
            ingredient.amount = ingredient.amount * portion;
          }
        }
        setMealAndRecalculateCalories({ ...mealClone, portion: portion });
      }
    }, 100);
    return () => clearTimeout(updatePortion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portion]);

  let cals = getMealNutrientAmount(props.cartMeal, 'Calories');

  // Get the selected macros from props
  const { selectedMacros } = props;

  const handleTouchStart = (e) => {
    e.preventDefault(); // Prevent default scroll behavior
    setStartX(e.touches[0].clientX);
    setSwipeDistance(0);
  };

  const handleTouchMove = (e) => {
    if (!startX) return;

    const currentX = e.touches[0].clientX;
    const distance = currentX - startX;
    setSwipeDistance(distance);
  };

  const handleTouchEnd = () => {
    if (swipeDistance < -50) {
      // Swipe left
      setSwipeDistance(-55); // Slide out the extra content
    } else {
      // No significant swipe left distance, reset to initial state
      setSwipeDistance(0);
    }
  };

  return (
    <>
      <div className="swipeable-item">
        <div
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          ref={parentRef}
        >
          <div
            className="calendar-meal-extra-content"
            style={{ transform: `translateX(${swipeDistance}px)` }}
          >
            <div className="calendar-meal" onClick={handleDialogOpen} ref={childRef}>
              <div className="calendar-meal-top">
                <div className="calendar-meal-left">
                  <img
                    className="calendar-meal-thumbnail"
                    src={props.cartMeal.picture}
                    alt={props.cartMeal.name}
                    onClick={handleDialogOpen}
                  />

                  <div className="calendar-meal-text">
                    <div
                      className="calendar-meal-label"
                      style={{ zIndex: '3000' }}
                      onClick={handleDialogOpen}
                    >
                      {getMealAttributeWithOptions(props.cartMeal, 'name')}
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        {cals > 0 && (
                          <>
                            {/* Always display Calories */}
                            <div
                              style={{ color: 'black' }}
                              className="calendar-meal-calorie-label"
                            >
                              {cals} Cals
                              {/* <img
                                src={CaloriesBadge}
                                alt="calorie badge"
                                style={{ width: '14px', marginLeft: '3px' }}
                              /> */}
                            </div>
                            {/* Display selected macros */}
                            {selectedMacros &&
                              selectedMacros.map((macro) => {
                                const macroValue = getMealNutrientAmount(props.cartMeal, macro);
                                return (
                                  macroValue !== undefined && (
                                    <div
                                      key={macro}
                                      style={{ ...macroStyles[macro], margin: '2px' }}
                                      className="calendar-meal-calorie-chip"
                                    >
                                      {macroValue}
                                      {macroUnits[macro]}
                                    </div>
                                  )
                                );
                              })}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <Tooltip title="Duplicate meal">
                  <Button className="duplicate-button" onClick={openCopyDaysDialog}>
                    <img
                      id="calendar-meal-duplicate"
                      className="landing-upsell-image"
                      src={ContentCopy}
                      alt={'content copy'}
                      style={{ height: '18px', opacity: '0.4', marginRight: '-2px', marginBottom: '4px' }}
                    />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>

          {/* Move the delete button outside of calendar-meal-extra-content */}

        </div>
        <div
          className="calendar-meal-side-content"
          onClick={handleDeleteClick}
        >
          <DeleteIcon style={{ color: 'white' }} />
        </div>
      </div>

      {isDialogOpen && (
        <Dialog maxWidth={'xl'} open={isDialogOpen} onClose={handleDialogClose}>
          <MealDetailsCard
            isReadOnly={false}
            handleExitClick={handleDialogClose}
            meal={props.cartMeal}
            portion={portion}
            setPortion={setPortion}
          />
        </Dialog>
      )}

      <Dialog open={copyToDayDialogOpen} maxWidth={'xl'} onClose={exitCopyDaysDialog}>
        <CopyMealsToDay
          exitCopyDaysDialog={exitCopyDaysDialog}
          meals={[props.cartMeal]}
          calendarCategory={props.cartMeal.calendarCategory}
        />
      </Dialog>
    </>
  );
};

export default CalendarMealNoDrag;
