import React, { useEffect, useState } from 'react';
import {
  getFirestore,
  collectionGroup,
  getDocs,
  query,
  limit,
  startAfter,
  orderBy,
  where,
  Timestamp,
} from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

const AdminPortal = () => {
  const [consultationThreads, setConsultationThreads] = useState([]);
  const [mealPlannerThreads, setMealPlannerThreads] = useState([]);
  const [lastConsultationDoc, setLastConsultationDoc] = useState(null);
  const [lastMealPlannerDoc, setLastMealPlannerDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('consultation');
  const [dateFilter, setDateFilter] = useState({ startDate: '', endDate: '' });

  const THREAD_BATCH_SIZE = 10;
  const db = getFirestore();

  // Fetch a batch of consultation threads
  const fetchConsultationThreads = async (startAfterDoc = null) => {
    setIsLoading(true);
    let threadQuery;
    try {
      // Build query with potential date filter
      if (dateFilter.startDate && dateFilter.endDate) {
        const startDate = Timestamp.fromDate(new Date(dateFilter.startDate));
        const endDate = new Date(dateFilter.endDate);
        endDate.setHours(23, 59, 59, 999);
        const endTimestamp = Timestamp.fromDate(endDate);

        threadQuery = query(
          collectionGroup(db, 'consultationThreads'),
          where('createdAt', '>=', startDate),
          where('createdAt', '<=', endTimestamp),
          orderBy('createdAt'),
          limit(THREAD_BATCH_SIZE)
        );
      } else {
        threadQuery = query(
          collectionGroup(db, 'consultationThreads'),
          orderBy('createdAt'),
          limit(THREAD_BATCH_SIZE)
        );
      }

      // Apply pagination
      if (startAfterDoc) {
        threadQuery = query(threadQuery, startAfter(startAfterDoc));
      }
    } catch (error) {
      console.error('Error constructing consultation thread query:', error);
      setIsLoading(false);
      return;
    }

    try {
      const threadSnapshot = await getDocs(threadQuery);
      const fetchedThreads = threadSnapshot.docs.map((doc) => ({
        id: doc.id,
        userId: doc.ref.parent.parent.id, // Include userId from document reference
        ...doc.data(),
      }));
      setConsultationThreads([...fetchedThreads]);

      if (!threadSnapshot.empty) {
        setLastConsultationDoc(
          threadSnapshot.docs[threadSnapshot.docs.length - 1]
        );
      }
    } catch (error) {
      console.error('Error fetching consultation threads:', error);
    }

    setIsLoading(false);
  };

  // Fetch a batch of meal planner threads (unchanged)
  const fetchMealPlannerThreads = async (startAfterDoc = null) => {
    setIsLoading(true);
    let threadQuery;
    try {
      // Build query with potential date filter
      if (dateFilter.startDate && dateFilter.endDate) {
        const startDate = Timestamp.fromDate(new Date(dateFilter.startDate));
        const endDate = new Date(dateFilter.endDate);
        endDate.setHours(23, 59, 59, 999);
        const endTimestamp = Timestamp.fromDate(endDate);

        threadQuery = query(
          collectionGroup(db, 'mealPlannerThreads'),
          where('createdAt', '>=', startDate),
          where('createdAt', '<=', endTimestamp),
          orderBy('createdAt'),
          limit(THREAD_BATCH_SIZE)
        );
      } else {
        threadQuery = query(
          collectionGroup(db, 'mealPlannerThreads'),
          orderBy('createdAt'),
          limit(THREAD_BATCH_SIZE)
        );
      }

      // Apply pagination
      if (startAfterDoc) {
        threadQuery = query(threadQuery, startAfter(startAfterDoc));
      }
    } catch (error) {
      console.error('Error constructing meal planner thread query:', error);
      setIsLoading(false);
      return;
    }

    try {
      const threadSnapshot = await getDocs(threadQuery);
      const fetchedThreads = threadSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMealPlannerThreads([...fetchedThreads]);

      if (!threadSnapshot.empty) {
        setLastMealPlannerDoc(
          threadSnapshot.docs[threadSnapshot.docs.length - 1]
        );
      }
    } catch (error) {
      console.error('Error fetching meal planner threads:', error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (activeTab === 'consultation') {
      setConsultationThreads([]); // Reset threads state
      fetchConsultationThreads();
    } else {
      setMealPlannerThreads([]); // Reset threads state
      fetchMealPlannerThreads();
    }
  }, [dateFilter, activeTab]); // Re-fetch on date filter or tab change

  const renderers = {
    link: ({ href, children }) => {
      if (href.startsWith('/')) {
        return <Link to={href}>{children}</Link>;
      }
      return <a href={href}>{children}</a>;
    },
  };

  // Handle tab switching
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Handle date filter change
  const handleDateChange = (e) => {
    setDateFilter({
      ...dateFilter,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Admin Dashboard - AI Engagements</h1>

      {/* Tabs for switching between consultation and meal planner threads */}
      <div style={styles.tabs}>
        <button
          style={activeTab === 'consultation' ? styles.activeTab : styles.tab}
          onClick={() => handleTabChange('consultation')}
        >
          Consultation Threads
        </button>
        <button
          style={activeTab === 'mealPlanner' ? styles.activeTab : styles.tab}
          onClick={() => handleTabChange('mealPlanner')}
        >
          Meal Planner Threads
        </button>
      </div>

      {/* Date Filter */}
      <div style={styles.dateFilter}>
        <input
          type="date"
          name="startDate"
          value={dateFilter.startDate}
          onChange={handleDateChange}
          placeholder="Start Date"
        />
        <input
          type="date"
          name="endDate"
          value={dateFilter.endDate}
          onChange={handleDateChange}
          placeholder="End Date"
        />
      </div>

      {activeTab === 'consultation' && (
        <div style={styles.section}>
          <h2 style={styles.subHeader}>Consultation Threads</h2>
          {consultationThreads.length === 0 ? (
            <p>No consultation threads available.</p>
          ) : (
            consultationThreads.map((thread) => (
              <div key={thread.id} style={styles.threadCard}>
                <h3 style={styles.threadUser}>
                  User: {thread.userId || 'N/A'}
                </h3>
                <p style={styles.threadInfo}>
                  <strong>Thread ID:</strong> {thread.id}
                </p>
                <p style={styles.threadInfo}>
                  <strong>Created At:</strong>{' '}
                  {thread.createdAt?.toDate().toString()}
                </p>
                <h4 style={styles.messagesHeader}>Messages:</h4>
                <ul style={styles.messagesList}>
                  {thread.messages &&
                    thread.messages.map((message, index) => (
                      <li key={index} style={styles.messageItem}>
                        <strong>{message.role}:</strong>
                        <ReactMarkdown components={renderers}>
                          {message.content}
                        </ReactMarkdown>
                      </li>
                    ))}
                </ul>
              </div>
            ))
          )}
          <button
            style={styles.loadMoreButton}
            onClick={() => fetchConsultationThreads(lastConsultationDoc)}
            disabled={isLoading}
          >
            Load More Consultation Threads
          </button>
        </div>
      )}

      {activeTab === 'mealPlanner' && (
        <div style={styles.section}>
          <h2 style={styles.subHeader}>Meal Planner Threads</h2>
          {mealPlannerThreads.length === 0 ? (
            <p>No meal planner threads available.</p>
          ) : (
            mealPlannerThreads.map((thread) => (
              <div key={thread.id} style={styles.threadCard}>
                <h3 style={styles.threadUser}>
                  User: {thread.userId || 'N/A'}
                </h3>
                <p style={styles.threadInfo}>
                  <strong>Thread ID:</strong> {thread.id}
                </p>
                <p style={styles.threadInfo}>
                  <strong>Created At:</strong>{' '}
                  {thread.createdAt?.toDate().toString()}
                </p>
                <h4 style={styles.messagesHeader}>Messages:</h4>
                <ul style={styles.messagesList}>
                  {thread.messages &&
                    thread.messages.map((message, index) => (
                      <li key={index} style={styles.messageItem}>
                        <strong>{message.role}:</strong>
                        <ReactMarkdown components={renderers}>
                          {message.content}
                        </ReactMarkdown>
                      </li>
                    ))}
                </ul>
              </div>
            ))
          )}
          <button
            style={styles.loadMoreButton}
            onClick={() => fetchMealPlannerThreads(lastMealPlannerDoc)}
            disabled={isLoading}
          >
            Load More Meal Planner Threads
          </button>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  },
  header: {
    textAlign: 'center',
    color: '#333',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  tab: {
    padding: '10px 20px',
    cursor: 'pointer',
    backgroundColor: '#f1f1f1',
    border: 'none',
    borderRadius: '4px',
    margin: '0 5px',
  },
  activeTab: {
    padding: '10px 20px',
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    margin: '0 5px',
  },
  dateFilter: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  section: {
    marginBottom: '40px',
  },
  subHeader: {
    fontSize: '24px',
    borderBottom: '2px solid #ddd',
    paddingBottom: '10px',
    color: '#444',
  },
  threadCard: {
    backgroundColor: '#f9f9f9',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '15px',
    marginBottom: '20px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  threadUser: {
    fontSize: '18px',
    margin: '0 0 10px 0',
    color: '#555',
  },
  threadInfo: {
    fontSize: '14px',
    color: '#666',
  },
  messagesHeader: {
    fontSize: '16px',
    marginTop: '15px',
    color: '#333',
  },
  messagesList: {
    listStyleType: 'none',
    paddingLeft: '0',
    margin: '10px 0',
  },
  messageItem: {
    backgroundColor: '#fff',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #eaeaea',
    marginBottom: '8px',
  },
  loadMoreButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '20px',
    fontSize: '16px',
  },
};

export default AdminPortal;
