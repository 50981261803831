import React from 'react';
import { Button } from '@material-ui/core'
import _ from 'lodash';
import ModifyRecipeInstructionsRow from './modify_recipe_instructions_row';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const ModifyRecipeInstructions = (props) => {
    let instructionSteps = props.recipe.analyzedInstructions ? props.recipe.analyzedInstructions.steps : null

    const handleAddButtonClick = () => {
        let recipeClone = _.cloneDeep(props.recipe)
        let instructionStepsClone = instructionSteps.concat([])
        instructionStepsClone.push({
            step: ""
        })
        recipeClone['analyzedInstructions']['steps'] = instructionStepsClone
        props.setRecipe(recipeClone)
        props.setStagedChanges(true)


    }

    const handleOnDragEnd = (result) => {
        if (result.destination) {
            let recipeClone = _.cloneDeep(props.recipe)
            const toIndex = result.destination.index
            const fromIndex = result.source.index
            let instructionStepsClone = instructionSteps.concat([])
            const element = instructionStepsClone[fromIndex];
            instructionStepsClone.splice(fromIndex, 1);
            instructionStepsClone.splice(toIndex, 0, element);
            recipeClone['analyzedInstructions']['steps'] = instructionStepsClone
            props.setRecipe(recipeClone)
        }
    }

    if (instructionSteps) {
        let rows = []
        for (let instructionStepIndex in instructionSteps) {
            let instructionStep = instructionSteps[instructionStepIndex]
            rows.push(
                <ModifyRecipeInstructionsRow setStagedChanges={props.setStagedChanges} key={instructionStepIndex} isReadOnly={props.isReadOnly} recipe={props.recipe} setRecipe={props.setRecipe} instructionStep={instructionStep} instructionStepIndex={instructionStepIndex} />
            )
        }

        return (

            <><div className="modify-recipe-instructions-header"> Instructions </div>

                {!props.isReadOnly && <Button className="modify-recipe-instructions-add-button" variant="outlined" onClick={handleAddButtonClick}> Add Instruction</Button>}
                <DragDropContext onDragEnd={handleOnDragEnd}>

                    <Droppable droppableId="instructions">
                        {(provided) => (
                            <div className="modify-recipe-instructions" {...provided.droppableProps} ref={provided.innerRef}>{rows}
                                {provided.placeholder}
                            </div>
                        )}

                    </Droppable>
                </DragDropContext>



            </>
        )
    }
    return <></>
}
export default ModifyRecipeInstructions