import { Dialog } from '@material-ui/core'
import React from 'react'

import { RecipeBooksContext } from 'contexts/recipe_books_context';

import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';


export default function CopyRecipeDialogBiteTracker(props) {

    const copyRecipeToBiteTrackerRecipeBook = React.useContext(RecipeBooksContext).copyRecipeToBiteTrackerRecipeBook
    let recipe = props.recipe

    async function handleCopy() {
        props.setAttemptedCopy(false)
        props.close && props.close()
        let preparedRecipe = recipe
        if(recipe.spoonacularRecipeId){
            preparedRecipe = await props.prepareWebRecipe(recipe)
        }

        
        let recipeBookToOpen = await copyRecipeToBiteTrackerRecipeBook({ ...preparedRecipe, name: preparedRecipe.name})
        props.setCreatingNewRecipe && props.setCreatingNewRecipe(false)
        props.setRecipeBookBeingModified && props.setRecipeBookBeingModified(recipeBookToOpen)
        props.scrollToTopOfRecipeBookList && props.scrollToTopOfRecipeBookList()
        props.setRecipeFilterText && props.setRecipeFilterText("")
        
        FirebaseAnalytics.logEvent({
            name: "saved_recipe",
            params: { recipe_name:preparedRecipe.name }
        })
    }


    // const handleIntChange = (e) => {
    //     setRecipe({ ...recipe, [e.target.name]: parseInt(e.target.value) })
    // }

    // const handleBoolChange = (e) => {
    //     setRecipe({ ...recipe, [e.target.name]: Boolean(e.target.checked) })
    // }


    return (
        <Dialog maxWidth={'xl'} open={Boolean(props.attemptedCopy)} >
            <div style={{ textAlign: 'center', margin: '30px' }}> Confirm copy
                <div style={{ color: 'grey', fontSize: '14px', margin: '10px 0px 12px 0px' }}>This action will add this recipe into your personal recipe book for you to customize</div>
                <div><button className="calendar-add-menu-meal-button" onClick={handleCopy}>Save</button> <button className="modify-recipe-cancel-button" onClick={() => { props.setAttemptedCopy(false) }}>Cancel</button> </div>
            </div>

        </Dialog>

    )


}
