import './log_weight.css'
import React from 'react';

import { InputAdornment, TextField, MenuItem, Select, FormControl, InputLabel, Button } from '@material-ui/core';

import Calendar from 'react-calendar'
import { getDateMonthYear, getDateString } from 'helpers/date_helpers';
import { UserContext } from 'contexts/user_context';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

const LogWeight = (props) => {
    const [dateSelected, setDateSelected] = React.useState(props.calendarDate)
    const userObject = React.useContext(UserContext).userObject
    const [weight, setWeight] = React.useState('')
    const [weightUnit, setWeightUnit] = React.useState('lbs')
    const [bodyFat, setBodyFat] = React.useState('')
    let setUserObject = React.useContext(UserContext).setUserObject

    const minDate = new Date();
    minDate.setDate(new Date().getDate() - 365)
    const maxDate = new Date();
    maxDate.setDate(new Date().getDate())

    const handleDayChange = (date) => {
        setDateSelected(date)
    }

    const handleWeightChange = (e) => {
        setWeight(e.target.value)
    }

    const handleWeightUnitChange = (e) => {
        setWeightUnit(e.target.value)
    }

    const handleBodyFatChange = (e) => {
        setBodyFat(e.target.value)
    }

    const handleSubmitClick = () => {
        let weightObject = userObject.weight ? userObject.weight : {}
        let bodyFatObject = userObject.bodyFat ? userObject.bodyFat : {}
        let dateMonthYear = getDateMonthYear(dateSelected)

        if (weight) {
            weightObject[dateMonthYear] = { unit: weightUnit, value: parseFloat(weight) }
        }
        if (bodyFat) {
            bodyFatObject[dateMonthYear] = parseFloat(bodyFat)
        }
        setUserObject({ ...userObject, weight: weightObject, bodyFat: bodyFatObject })
        FirebaseAnalytics.logEvent({
            name: "logged_weight",
            params: {}
        })
        props.handleLogWeightExit()
    }

    return (
        <>
            <div style={{ margin: '20px 30px', textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div style={{ marginBottom: '18px', fontSize: '16px', fontFamily: 'Montserrat-Semibold' }}>Input your weight</div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px', marginTop: '8px' }}>
                    <TextField InputLabelProps={{ shrink: true }} style={{ maxWidth: '170px' }} className="log-weight-input" 
                        InputProps={{ endAdornment: <InputAdornment position="end">
                            <FormControl>
                                <Select
                                    name="weightUnit"
                                    value={weightUnit}
                                    onChange={handleWeightUnitChange}
                                    disableUnderline
                                >
                                    <MenuItem value="lbs">lbs</MenuItem>
                                    <MenuItem value="kg">kg</MenuItem>
                                </Select>
                            </FormControl>
                        </InputAdornment> }} 
                        required={true} label="weight" type="number" variant="outlined" placeholder="e.g. 180.0" value={weight} onChange={handleWeightChange}></TextField>
                    <TextField InputLabelProps={{ shrink: true }} style={{ maxWidth: '170px' }} className="log-weight-input" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} label="body fat" type="number" variant="outlined" placeholder="e.g. 20.0" value={bodyFat} onChange={handleBodyFatChange}></TextField>
                </div>

                <Calendar style={{ marginLeft: 'auto', marginRight: 'auto' }} value={dateSelected} minDate={minDate} maxDate={maxDate} onChange={handleDayChange} />
                <div style={{ margin: '10px', fontFamily: 'Montserrat-Medium', fontSize: '14px' }}>Weighed on {getDateString(dateSelected)}</div>

                <Button className={isNaN(parseFloat(weight)) ? "modify-recipe-cancel-button" : "copy-meals-to-day-button"} disabled={isNaN(parseFloat(weight))} onClick={handleSubmitClick}> Log Weight </Button>
            </div>
        </>
    )
}
export default LogWeight;
