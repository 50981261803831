// CalendarChart.js

import React from 'react';
import CalorieCard from '../calorie_card/calorie_card';
import { Button } from '@material-ui/core';

const CalendarChart = (props) => {
  const { activeIndex, setActiveIndex } = props;

  function determineDay() {
    let date = props.calendarDate;
    return [date.getDate(), date.toLocaleDateString('en-US', { weekday: 'long' }), date.getMonth() + 1];
  }

  let [date, day, month] = determineDay();

  return (
    <>
      {window.innerWidth > 1123 && (
        <div style={{ textAlign: 'center', marginTop: '10px', color: 'white' }}>
          {day} {month}/{date}
        </div>
      )}

      <CalorieCard
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        userObject={props.userObject}
        totalProteins={props.totalProteins}
        totalCarbs={props.totalCarbs}
        totalFats={props.totalFats}
        totalSodium={props.totalSodium}
        totalSugar={props.totalSugar}
        totalCholesterol={props.totalCholesterol}
        totalFiber={props.totalFiber}
        totalNetCarbs={props.totalNetCarbs}
        totalSaturatedFat={props.totalSaturatedFat}
        totalCalories={props.totalCalories}
      />
    </>
  );
};

export default CalendarChart;
