import React from "react";

import { UserContext } from 'contexts/user_context';
import { parseDateMonthYear } from 'helpers/date_helpers';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Brush, Scatter } from 'recharts';


const WeightOverTimeGraph = () => {
    const userObject = React.useContext(UserContext).userObject

    const formatDate = (tick) => {
        const date = new Date(tick);

        const month = date.toLocaleString('default', { month: 'short' }); // Get short month name (e.g., "Apr")

        const day = date.getDate(); // Get day of the month (e.g., 3)

        return `${month} ${day}`;
    };
    let weightData = [];


    let dataMax = 0;
    let dataMin = 1000;
    if (userObject.weight && Object.keys(userObject.weight).length) {
        for (let key of Object.keys(userObject.weight)) {
            let date = parseDateMonthYear(key)
            if (userObject.weight[key].value) {
                if (userObject.weight[key].unit === "kg") {
                    weightData.push({ date: date, value: Math.round(10 * userObject.weight[key].value * 2.20462) / 10 })
                }
                else {
                    weightData.push({ date: date, value: userObject.weight[key].value })

                }

            }
            else {
                if (userObject.weight[key].value == null) {

                    weightData.push({ date: date, value: userObject.weight[key] })
                }
            }
            if (userObject.weight[key] > dataMax) {
                dataMax = userObject.weight[key]
            }
            if (userObject.weight[key] < dataMin) {
                dataMin = userObject.weight[key]
            }
        }


        weightData.sort((a, b) => (a.date - b.date));

        weightData.forEach((d) => {
            d.date = d.date.getTime(); // date -> epoch
        });

        return (<>
            <ResponsiveContainer width="98%" height={300}>
                <AreaChart data={weightData}>
                    <XAxis style={{ fontSize: '12px' }} interval={'preserveStartEnd'} dataKey="date" type="number" domain={['dataMin', 'dataMax']} tickFormatter={formatDate} />
                    <YAxis width={40} dataKey="value" domain={[dataMin => dataMin - 10, dataMax => dataMax + 10]} />
                    <Tooltip labelFormatter={(label) => new Date(label).toLocaleDateString()} formatter={(value) => value + ' lbs'} />
                    <Area type="monotone" dataKey="value" stroke="rgb(23, 161, 76)" fill="rgb(230, 239, 218)" dot={{ stroke: 'rgb(23, 161, 76)', strokeWidth: 1, r: 4, strokeDasharray: '' }} />
                    <Brush dataKey="date" height={30} stroke="rgb(210, 233, 198)" fillOpacity={1} tickFormatter={formatDate} />
                    <Scatter dataKey="value" fill="#8884d8" />
                </AreaChart>
            </ResponsiveContainer>

        </>)
    }
    else {
        return (<ResponsiveContainer width="98%" height={300}>
            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', fontSize: '24px', color: 'black', marginTop: '70px' }}>No data found</div>

            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', color: 'grey', marginTop: '10px' }}>Log weight to see data</div>
        </ResponsiveContainer>

        )


    }

}

export default WeightOverTimeGraph;