import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth_context';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { getMealNutrientAmount } from 'helpers/recipe_helpers';
import { groupDataByWeek, aggregateWeeklyData } from 'helpers/data_helpers';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import './weekly_report_page.css'; // Import CSS for styling if needed

const WeeklyReportPage = () => {
  const { currentUser } = useContext(AuthContext);
  const [weeklyData, setWeeklyData] = useState([]);

  useEffect(() => {
    const fetchWeeklyData = async () => {
      try {
        const db = getFirestore();
        const calendarMealCollectionRef = collection(db, 'users', currentUser.uid, 'calendarMeals');

        // Fetch data from the last 12 weeks (or desired range)
        const twelveWeeksAgo = new Date();
        twelveWeeksAgo.setDate(twelveWeeksAgo.getDate() - 84); // 12 weeks * 7 days

        const mealsQuery = query(
          calendarMealCollectionRef,
          where('calendarDate', '>', twelveWeeksAgo)
        );

        const querySnapshot = await getDocs(mealsQuery);

        let data = [];

        querySnapshot.forEach((doc) => {
          const tempDoc = doc.data();
          if (tempDoc.calendarDate) {
            tempDoc.calendarDate = new Date(tempDoc.calendarDate.seconds * 1000);
            data.push({
              date: tempDoc.calendarDate,
              calories: getMealNutrientAmount(tempDoc, 'Calories'),
              protein: getMealNutrientAmount(tempDoc, 'Protein'),
              carbs: getMealNutrientAmount(tempDoc, 'Carbohydrates'),
              fat: getMealNutrientAmount(tempDoc, 'Fat'),
              // Include other variables if needed
            });
          }
        });

        const groupedData = groupDataByWeek(data);
        const aggregatedData = aggregateWeeklyData(groupedData);
        setWeeklyData(aggregatedData);
      } catch (error) {
        console.error('Error fetching weekly data:', error);
      }
    };

    fetchWeeklyData();
  }, [currentUser.uid]);

  const renderChart = () => {
    if (weeklyData.length === 0) {
      return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h2>No data available for the selected period.</h2>
          <p>Please track your meals to see weekly reports.</p>
        </div>
      );
    }

    return (
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart data={weeklyData}>
          <XAxis dataKey="weekLabel" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="calories" stroke="#8884d8" fill="#8884d8" />
          <Area type="monotone" dataKey="protein" stroke="#82ca9d" fill="#82ca9d" />
          <Area type="monotone" dataKey="carbs" stroke="#ffc658" fill="#ffc658" />
          <Area type="monotone" dataKey="fat" stroke="#ff7300" fill="#ff7300" />
          {/* Add more Area components for other variables */}
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="weekly-report-page" style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      <h1 style={{ textAlign: 'center' }}>Weekly Report</h1>
      {renderChart()}
    </div>
  );
};

export default WeeklyReportPage;
