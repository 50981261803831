import './dietary_preferences.css'
import React from "react";
import { UserContext } from 'contexts/user_context';
import SwipeableTemporaryDrawer from '../calendar/SwipeableTemporaryDrawer';
import { config } from 'Constants';
import DislikedIngredientsSearchBox from './disliked_ingredients_search_box';

const DietaryPreferences = (props) => {
    const { userObject, setUserObject } = React.useContext(UserContext);
    const [isGenderActionSheetOpen, setIsGenderActionSheetOpen] = React.useState(false);

    const handleChange = (restriction) => {
        if (!userObject.dietaryRestrictions) {
            userObject.dietaryRestrictions = []
        }
        const updatedRestrictions = userObject.dietaryRestrictions.includes(restriction)
            ? userObject.dietaryRestrictions.filter((r) => r !== restriction)
            : [...userObject.dietaryRestrictions, restriction];
        setUserObject({ ...userObject, dietaryRestrictions: updatedRestrictions });
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        props.skip()
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", padding:'20px 10px', width:'calc(-20px + 100vw)', maxWidth:'400px' }}>
            <form onSubmit={handleSubmit}>
                <div className="calorie-calculator-form-line-item">

                    <div style={{ width: "266px", margin:'auto' }}>
                        <div style={{ padding: '0px 0px 8px 5px' }} className='modify-recipe-unit-text-normal'>Dietary Restrictions</div>
                        <div
                            onClick={() => setIsGenderActionSheetOpen(true)}
                            style={{
                                border: "1px solid lightgray",
                                padding: "17px",
                                fontSize: "13px",
                                width: '230px'
                            }}
                        >
                            {/* Display selected restrictions */}
                            {userObject.dietaryRestrictions && userObject.dietaryRestrictions.length > 0
                                ? userObject.dietaryRestrictions.join(', ')
                                : 'Select restrictions'}
                        </div>
                    </div>

                </div>
                <DislikedIngredientsSearchBox />
                <div style={{ marginTop: '10px', fontSize: '12px', color: 'grey', textAlign: 'center', maxWidth: '300px' }}> We will use these preferences for our AI-powered planning features</div>


                <div style={{ paddingTop: '5px' }} className="onboarding-wizard-form-submit-button">
                    {props.skip && (
                        <>
                            <div onClick={props.skip} style={{ color: '#999999', fontSize: '14px', textAlign: 'center', fontFamily: 'Montserrat-SemiBold' }}>
                                <span onClick={props.skip}>SKIP</span>
                            </div>
                            <div style={{ backgroundColor: '#259B36', opacity: '0.5', margin: '0px 0px 0px 15px' }} className="calorie-chart-green-dot" />
                            <div style={{ backgroundColor: '#259B36', opacity: '0.65', margin: '0px 3px 0px 3px' }} className="calorie-chart-green-dot" />
                            <div style={{ backgroundColor: '#259B36', opacity: '0.8', margin: '0px 10px 0px 0px' }} className="calorie-chart-green-dot" />
                        </>
                    )}


                    {props.skip && <button className="calendar-add-menu-meal-button" value="Submit">Next</button>}
                </div>
            </form>
            <SwipeableTemporaryDrawer
                actionSheetOpen={isGenderActionSheetOpen}
                setActionSheetOpen={setIsGenderActionSheetOpen}
                list={config.dietaryRestrictionList}
                onChange={(ind) => {
                    const restriction = config.dietaryRestrictionList[ind];
                    handleChange(restriction);
                }}
            />
        </div>
    );
};

export default DietaryPreferences;
