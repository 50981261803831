import './login.css'
import { Button, TextField } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { AuthContext } from "contexts/auth_context"
import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import LoopIcon from '@material-ui/icons/Loop';
import { googleProvider } from "../../../firebase"
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { Capacitor } from '@capacitor/core'
import AppleIcon from '@material-ui/icons/Apple';


(function() {
    const originalSetTimeout = window.setTimeout;
    window.setTimeout = function(fn, delay, ...args) {
      // Check if the delay matches Firebase's _Timeout.AUTH_EVENT
      if (delay === 8000) {
        delay = 1000; 
      }
      return originalSetTimeout(fn, delay, ...args);
    };
  })();

export default function Login() {
    let [loginObject, setLoginObject] = React.useState({});
    const { login, loginSocial, appleLogin } = useContext(AuthContext)
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [signinLoading, setSigninLoading] = useState(false)
    const history = useHistory()
    const { currentUser } = React.useContext(AuthContext)

    const handleSocialClick = async (provider) => {
        setError('')
        setSigninLoading(true)
        try {
            await loginSocial(provider)
            history.push("/calendar")
        } catch (error) {
            setError("Failed to sign in with Google")
        } finally {
            setSigninLoading(false)
        }
    }

    const loginWithApple = async () => {
        setSigninLoading(true)
        try {
            await appleLogin()
            history.push("/calendar")
        } catch (error) {
            setError("Failed to sign in with Apple")
        } finally {
            setSigninLoading(false)
        }
    }

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            setError("")
            setLoading(true)
            let result = await login(loginObject.email, loginObject.password)
            console.log(result)
            if (result.length === 0) {
                history.push("/calendar")
            } else {
                setError(result)
            }
        } catch {
            setError("Failed to log in")
        } finally {
            setLoading(false)
        }
    }

    if (currentUser !== null && error.length === 0) {
        history.push("/calendar")
    }

    const handleChange = (e) => {
        setLoginObject({ ...loginObject, [e.target.name]: e.target.value })
    }
    
    return (
        <>
            <div className="contact-us-page-top">
                {signinLoading ?
                    <div className="login-frame">
                        Loading...
                    </div>
                    :
                    <div className="login-frame">
                        <div className="login-header">Sign In</div>
                        {error && <Alert severity='error'>{error}</Alert>}
                        <form onSubmit={handleSubmit}>
                            <div className="login-form-line-item">
                                <TextField className="login-form-input-wide" type="text" variant="outlined" required={true} label="email" name='email' value={loginObject.email} onChange={handleChange}></TextField>
                            </div>
                            <div className="login-form-line-item">
                                <TextField className="login-form-input-wide" type="password" variant="outlined" required={true} label="password" name='password' value={loginObject.password} onChange={handleChange}></TextField>
                            </div>
                            <Button variant="contained" disabled={loading} className="login-button" type="submit">
                                Log In {loading && <LoopIcon style={{ animation: 'spin 2s linear infinite' }}></LoopIcon>}
                            </Button>
                        </form>
                        <div style={{ marginTop: '10px' }}>OR</div>
                        <Button className="login-google-button" onClick={() => handleSocialClick(googleProvider)}>
                            <img className="google-logo" alt="google logo" src="/googleLogo.jpeg" />
                            Sign in with Google
                        </Button>
                        {Capacitor.getPlatform() === 'ios' &&
                            <Button
                                className="login-apple-button"
                                onClick={() => loginWithApple()}
                                startIcon={<AppleIcon />}
                            >
                                Sign in with Apple
                            </Button>
                        }
                        <div className="signup-form-switch">Need to sign-up <NavLink to="signup">Sign up</NavLink></div>
                    </div>
                }
            </div>
        </>
    )
}
