import './instacart.css';

import React from 'react';


import { Helmet } from 'react-helmet';


const Instacart = () => {

    return (<>


        <Helmet>

            <script>
                {(function (d, s, id, a) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) { return; } js = d.createElement(s); js.id = id;
                    js.src = "https://widgets.instacart.com/widget-bundle-v2.js"; js.async = true;
                    js.dataset.source_origin = "affiliate_hub"; fjs.parentNode.insertBefore(js, fjs);
                })
                    (document, "script", "standard-instacart-widget-v1")}
            </script>

            {/*          
         <script dangerouslySetInnerHTML={{__html: `{(function (d, s, id, a) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { return; } js = d.createElement(s); js.id = id;
                js.src = "https://widgets.instacart.com/widget-bundle-v2.js"; js.async = true;
                js.dataset.source_origin = "affiliate_hub"; fjs.parentNode.insertBefore(js, fjs);
            })
                (document, "script", "standard-instacart-widget-v1")}`}}>
         
        </script> */}


        </Helmet>


        <div className="instacart-page-top">



            <div className="instacart-page-top-header">Order ingredients via instacart</div>
            <div className="instacart-page-top-header-description">Click the button below to load your selected recipes into instacart to get the ingredients delivered to your doorstep at no extra cost for you!</div>
            <div style={{ padding: '30px', marginBottom: '100px' }}>
                <div onClick={()=>window.close()}><div style={{}} id="shop-with-instacart-v1" data-affiliate_id="3165" data-source_origin="affiliate_hub" data-affiliate_platform="recipe_widget" ></div></div>
            </div>
        </div>
    </>

    );
}

export default Instacart;