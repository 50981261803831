import './create_new_recipe.css'

import React from "react";
import { Dialog } from "@material-ui/core";

import ExtractRecipeFromUrl from '../extract_recipe_from_url/extract_recipe_from_url';
import ModifyRecipe from "components/shared/modify_recipe/modify_recipe";
import { AuthContext } from 'contexts/auth_context';
import { RecipeBooksContext } from 'contexts/recipe_books_context';
import _ from "lodash";
import { config } from 'Constants'


const CreateNewRecipe = (props) => {
    // const menu = React.useContext(MenuContext).getActiveMenu();
    const getCustomRecipeBookId = React.useContext(RecipeBooksContext).getCustomRecipeBookId
    const [extractRecipeDialogOpen, setExtractRecipeDialogOpen] = React.useState(false)
    const { currentUser } = React.useContext(AuthContext)
    const [recipeBeingCreated, setRecipeBeingCreated] = React.useState(null)


    function handleExtractRecipeDialogExitClick() {
        setExtractRecipeDialogOpen(false)
    }

    function extractRecipeFromURL() {
        setExtractRecipeDialogOpen(true)
        props.setRecipeFilterText("")
    }




    const handleExitClick = () => {
        setRecipeBeingCreated(null)
        props.handleExitClick()
    }

    function addNewRecipe() {
        let newRecipe = _.cloneDeep(config.emptyRecipe)
        newRecipe['recipeBooks'].push(getCustomRecipeBookId())
        newRecipe['owners'] = [currentUser.uid]
        newRecipe['areNutrientsManual'] = props.areNutrientsManual ? props.areNutrientsManual : false
        setRecipeBeingCreated(newRecipe)
        props.setRecipeFilterText("")

    }


    return (<>
        {props.extractRecipe?        <button className="import-new-recipe-button" onClick={extractRecipeFromURL}>URL Import</button>:
        <button className="create-new-recipe-button" onClick={addNewRecipe}>Create New</button>


        }

        <Dialog open={extractRecipeDialogOpen} onClose={handleExtractRecipeDialogExitClick} >
            <ExtractRecipeFromUrl handleExitClick={handleExtractRecipeDialogExitClick} />
        </Dialog>

        <Dialog maxWidth={'xl'} open={Boolean(recipeBeingCreated)} >
            {<ModifyRecipe recipeBeingModified={recipeBeingCreated} close={handleExitClick} creatingNewRecipe={true} setRecipeBookBeingModified={setRecipeBeingCreated} />}
        </Dialog>
    </>)

}

export default CreateNewRecipe;