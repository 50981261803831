import React, { useState, useContext, useEffect } from 'react';
import { TextField, Chip, makeStyles, Paper, Typography } from '@material-ui/core';
import { UserContext } from 'contexts/user_context';
import commonIngredients from './common_ingredients.json';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  suggestionItem: {
    padding: theme.spacing(1, 2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const DislikedIngredientsSearchBox = () => {
  const classes = useStyles();
  const { userObject, setUserObject } = useContext(UserContext);
  const [ingredientsText, setIngredientsText] = useState("");
  const [suggestedIngredients, setSuggestedIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState(userObject.dislikedIngredients ? userObject.dislikedIngredients : []);

  useEffect(() => {
    setSelectedIngredients(userObject.dislikedIngredients ? userObject.dislikedIngredients : []);
  }, [userObject.dislikedIngredients]);

  const handleIngredientsTextChange = (e) => {
    const ingredientText = e.target.value;
    setIngredientsText(ingredientText);
    
    if (ingredientText) {
      const filteredIngredients = commonIngredients.dislikedIngredients.filter(ingredient =>
        ingredient.toLowerCase().includes(ingredientText.toLowerCase())
      );
      setSuggestedIngredients(filteredIngredients);
    } else {
      setSuggestedIngredients([]);
    }
  };

  const handleChipDelete = (ingredientToDelete) => () => {
    const updatedIngredients = selectedIngredients.filter((ingredient) => ingredient !== ingredientToDelete);
    setSelectedIngredients(updatedIngredients);
    setUserObject({ ...userObject, dislikedIngredients: updatedIngredients });
  };

  const handleIngredientSelect = (ingredient) => {
    const updatedIngredients = [...selectedIngredients, ingredient];
    setSelectedIngredients(updatedIngredients);
    setUserObject({ ...userObject, dislikedIngredients: updatedIngredients });
    setIngredientsText('');
    setSuggestedIngredients([]);
  };

  return (
    <div style={{ padding: '5px', width: '266px', margin: 'auto' }}>
      <div style={{ padding: '10px 0px 8px 5px' }} className='modify-recipe-unit-text-normal'>Search Disliked ingredients</div>

      <TextField
        placeholder="e.g. broccoli"
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        value={ingredientsText}
        onChange={handleIngredientsTextChange}
        style={{ minWidth: '266px' }}
      />
      {suggestedIngredients.length > 0 && (
        <Paper elevation={3} style={{ marginTop: 8, padding: 8, maxHeight: 150, overflowY: 'auto' }}>
          {suggestedIngredients.map((ingredient, index) => (
            <Typography
              key={index}
              className={classes.suggestionItem}
              onClick={() => handleIngredientSelect(ingredient)}
            >
              {ingredient}
            </Typography>
          ))}
        </Paper>
      )}

      <div className={classes.root}>
        {selectedIngredients.map((ingredient, index) => (
          <Chip key={index} label={ingredient} onDelete={handleChipDelete(ingredient)} />
        ))}
      </div>
    </div>
  );
};

export default DislikedIngredientsSearchBox;
