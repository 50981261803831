import React from "react";
import "./landing_mobile_simple.css";
import appStoreBadge from "images/app_store_badge.png";
import playStoreBadge from "images/play_store_badge.png";
import Navigation from 'components/shared/navigation/navigation';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import ReactPixel from 'react-facebook-pixel';

export const LandingMobileSimple = () => {

    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      
    function logAppstoreClick(){
        FirebaseAnalytics.logEvent({
            name: "clicked_app_store_link",
            params: { windowWidth: window.innerWidth }
        })
        ReactPixel.trackCustom('visitAppStore', {'store':'Apple AppStore'})
    }

    function logPlaystoreClick(){
        FirebaseAnalytics.logEvent({
            name: "clicked_play_store_link",
            params: { windowWidth: window.innerWidth }
        })
        ReactPixel.trackCustom('visitAppStore', {'store':'Google PlayStore'})
    }

    ReactPixel.init('981117496755244', options);


    return (
        <>                <Navigation />
            <div className="home-mobile-simple">
                <div className="div-simple">
                    <img className="ellipse-simple" alt="Ellipse" src="ellipse-180.png" />
                    <div className="overlap-group-simple">
                        <p className="plan-your-meals-for-simple">
                        Reach Your Nutrition Goals with BiteTracker

                        </p>
                        <p className="text-wrapper-simple">
                        BiteTracker is a completely free app that allows you to <b>track calories and macros</b> of <b>both generic and branded foods</b>.  With our app you can also plan your meals by adding recipes and ordering ingredients automatically via Instacart, AmazonFresh or WholeFoods. 

                        </p>
                        <img className="group-simple" alt="Group" src="group-18441.png" />
                        <a onClick={logAppstoreClick} href="https://apps.apple.com/us/app/thebitetracker/id6473445195"><img className="landing-mobile-appstore-badge-simple" alt="Group" src={appStoreBadge} /></a>
                        <a onClick={logPlaystoreClick} href="https://play.google.com/store/apps/details?id=com.thebitetracker.com"><img className="landing-mobile-playstore-badge-simple" alt="Group" src={playStoreBadge} /></a>


                        <img className="image-simple" alt="" src="image-4.png" />
                        <img className="group-2-simple" alt="Group" src="group-48095625.png" />
                        <img className="group-3-simple" alt="Group" src="group-48095631.png" />

                        <div className="text-wrapper-12-simple">Support by</div>
                        <img className="group-10-simple" alt="Group" src="amazonAndWholeFoods.png" />
                        <img className="rectangle-2-simple" alt="Rectangle" src="rectangle-39442.png" />
                    

                    </div>

                    {/* 
                    <NavLink to="/calendar">
                        <img className="img-bottom" alt="Rectangle" src="rectangle-73.png" />
                        <div className="text-wrapper-2-bottom">Try it now</div>
                        <img className="arrow-bottom" alt="Arrow" src="arrow-2.png" />

                    </NavLink> */}

                    {/* <img className="vector" alt="Vector" src="vector.png" />
                    <img className="vector-2" alt="Vector" src="image.png" />
                    <img className="vector-3" alt="Vector" src="vector-2.png" /> */}
                </div>
            </div>        </>

    );
};
