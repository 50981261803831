
import { TextField } from '@material-ui/core'
import React from 'react'



export const NarrowTextField = (props) => {
    
    let FONT_SIZE = 7
    let DEFAULT_INPUT_WIDTH = 10

    const [inputWidth, setInputWidth] = React.useState(DEFAULT_INPUT_WIDTH)

    function getLength(val) {
        if(!val){
            return 0;
        }
        return val.toString().length
    }

    React.useEffect(() => {
        if (getLength(props.value) * FONT_SIZE > DEFAULT_INPUT_WIDTH) {
            setInputWidth((getLength(props.value) + 1) * FONT_SIZE)
        } else {
            setInputWidth(DEFAULT_INPUT_WIDTH)
        }
        
    }, [props.value, DEFAULT_INPUT_WIDTH, FONT_SIZE])

    return (
        <div>
            <TextField
                
                className={props.className}
                value={props.value.toString()}
                onChange={props.onChange}
                name={props.name}
                type={props.type}
                InputProps={{
                    style: { width: `${inputWidth}px` }, readOnly:props.readOnly, disableUnderline:props.readOnly
                }}
            />
        </div>
    )
}