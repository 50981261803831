import { Button, Dialog, MenuItem, Select } from '@material-ui/core'
import React from 'react'
import './modify_recipe.css'
import { NarrowTextField } from '../reusables/narrow_text_field'
import axios from 'axios'
import { Alert } from '@material-ui/lab';
import LoopIcon from '@material-ui/icons/Loop';
import { Draggable } from 'react-beautiful-dnd'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { cleanUpNutrients } from 'helpers/recipe_helpers'
import { Capacitor } from '@capacitor/core'
import SwipeableTemporaryDrawer from '../calendar/SwipeableTemporaryDrawer'




export default function ModifyRecipeIngredientRow(props) {
    let recipe = props.recipe
    let setRecipeAndRecalculateCalories = props.setRecipeAndRecalculateCalories
    const [errorState, setErrorState] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [amountString, setAmountString] = React.useState(props.ingredient.amountString)
    const [amount, setAmount] = React.useState(props.ingredient.amount)
    const [isActionSheetOpen, setIsActionSheetOpen] = React.useState(false)

    React.useEffect(() => {
        function updateAmountAfterSort() {
            setAmountString(props.ingredient.amountString)
            setAmount(props.ingredient.amount)
        };
        updateAmountAfterSort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.ingredient.amountString, props.ingredient.amount])

    React.useEffect(() => {
        const updateIngredients = setTimeout(() => {
            let ingredientsClone = recipe.ingredients.concat([])
            let ingredientId = props.ingredient.id
            for (let ingredientItem of ingredientsClone) {
                if (ingredientId === ingredientItem.id) {
                    if (ingredientItem.nutrition) {
                        if (ingredientItem.amountString !== amountString) {
                            ingredientItem['amount'] = amount
                            ingredientItem['amountString'] = amountString
                            setRecipeAndRecalculateCalories({ ...recipe, ingredients: ingredientsClone })
                        }
                    }
                }
            }
        }, 500);
        return () => clearTimeout(updateIngredients);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amountString, amount])

    const handleAmountChange = (event) => {
        const regexToMatch = /\b\d+(?:\.\d+)?(?:\/\d+(?:\.\d+)?)?\b/g;
        let matchedStringArray = event.target.value.match(regexToMatch)
        let ingredientsClone = recipe.ingredients.concat([])
        let ingredientId = parseInt(event.target.name)

        if (!matchedStringArray) {
            //handling empty string and wrong strings
            for (let ingredientItem of ingredientsClone) {
                if (ingredientId === ingredientItem.id) {
                    setAmountString("")
                    setAmount(0)
                    return
                }
            }
        }

        let matchedString = matchedStringArray[0]

        let amountVal;

        // handle the case where we have a fraction
        if (matchedString.includes('/')) {
            const splitString = matchedString.split('/')
            amountVal = parseFloat(splitString[0]) / parseFloat(splitString[1])
        }
        else {
            //otherwise its just a float
            amountVal = parseFloat(matchedString)
        }


        for (let ingredientItem of ingredientsClone) {
            if (ingredientId === ingredientItem.id) {
                setAmountString(event.target.value)
                setAmount(amountVal)
            }
        }


    }


    async function handleUnitSelectChange(event) {

        let ingredientId = parseInt(event.currentTarget.getAttribute('ingredientid'))
        let ingredient;
        let ingredientsClone = recipe.ingredients.concat([])
        for (let ingredientItem of ingredientsClone) {
            if (ingredientId === ingredientItem.id) {
                ingredient = ingredientItem
            }
        }
        setLoading(true)
        axios.get('/ingredient/' + ingredientId, { params: { unit: event.target.value }, timeout: 3000 }).then(response => {

            if (response.data) {
                //need to only grab the nutrient information inside nutrition (i.e. not flavonoids)
                let ingredientNutrients = cleanUpNutrients(response.data.nutrition.nutrients)

                ingredient['nutrition'] = { nutrients: ingredientNutrients, weightPerServing: response.data.nutrition.weightPerServing }

                ingredient['selectedUnit'] = event.target.value
                setRecipeAndRecalculateCalories({ ...recipe, ingredients: ingredientsClone })
                setLoading(false)
            }
        }).catch(err => {
            setErrorState(2)
            setLoading(false)
        });

    }

    async function handleUnitActionSheetChange(id, value) {
        let ingredientId = id
        let ingredient;
        let ingredientsClone = recipe.ingredients.concat([])
        for (let ingredientItem of ingredientsClone) {

            if (ingredientId === ingredientItem.id) {
                ingredient = ingredientItem
            }
        }
        setLoading(true)
        axios.get('/ingredient/' + ingredientId, { params: { unit: value }, timeout: 3000 }).then(response => {

            if (response.data) {
                //need to only grab the nutrient information inside nutrition (i.e. not flavonoids)
                let ingredientNutrients = cleanUpNutrients(response.data.nutrition.nutrients)

                ingredient['nutrition'] = { nutrients: ingredientNutrients, weightPerServing: response.data.nutrition.weightPerServing }

                ingredient['selectedUnit'] = value
                setRecipeAndRecalculateCalories({ ...recipe, ingredients: ingredientsClone })
                setLoading(false)
            }
        }).catch(err => {
            setErrorState(2)
            setLoading(false)
        });

    }

    function deleteIngredient(e) {
        let ingredientId = parseInt(e.currentTarget.getAttribute('ingredientid'))
        let newIngredients = recipe.ingredients.filter(ingredient => ingredient.id !== ingredientId)
        setRecipeAndRecalculateCalories({ ...recipe, ingredients: newIngredients })
    }

    // const showActions = async (options) => {
    //     const result = await ActionSheet.showActions({
    //         title: 'Options',
    //         message: 'Select an option to perform',
    //         options: options.map((key) => {
    //             return {
    //                 title: key
    //             }
    //         }),
    //     });
    //     let id = recipe.ingredients[0].id
    //     let value = options[result.index]
    //     handleUnitActionSheetChange(id, value)
    // };

    return (
        <Draggable key={props.ingredient.id} draggableId={props.ingredient.name} index={parseInt(props.index)} isDragDisabled={props.isReadOnly}>
            {(provided) => (
                <div className="modify-recipe-ingredient-row" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                    {loading ? <LoopIcon style={{ animation: 'spin 2s linear infinite' }} /> : props.isReadOnly ? <></> : <DragIndicatorIcon className="modify-recipe-ingredient-row-drag-indicator" style={{ color: 'grey' }} />}

                    <div className="modify-recipe-ingredient-image-div"><img className="modify-recipe-ingredient-image" src={'https://spoonacular.com/cdn/ingredients_100x100/' + props.ingredient.image} alt={props.ingredient.name} /></div>
                    <div className="modify-recipe-ingredient-column">

                        <div className="modify-recipe-ingredient-label">{props.ingredient.name}</div>
                        <div className="modify-recipe-ingredient-column-inner">
                            <NarrowTextField readOnly={props.isReadOnly} className="modify-recipe-amount-text-field" type="text" name={props.ingredient.id.toString()} onChange={handleAmountChange} value={amountString}></NarrowTextField>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}>
                                {Capacitor.getPlatform() !== 'web' || props.isReadOnly?
                                    <Select
                                        label="unit"
                                        value={props.ingredient.selectedUnit}
                                        name="unit"
                                        onChange={handleUnitSelectChange}
                                        className="modify-recipe-unit-select"
                                        inputProps={props.isReadOnly ? { readOnly: props.isReadOnly, IconComponent: () => null, } : null}
                                        disableUnderline

                                    >
                                        {props.ingredient.possibleUnits.map((unit) => (
                                            <MenuItem ingredientid={props.ingredient.id} key={unit} value={unit}>
                                                {unit}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    :
                                    <div
                                        onClick={() => {
                                            setIsActionSheetOpen(true)
                                        }}
                                        className='modify-recipe-unit-text-normal'
                                    >
                                        {props.ingredient.selectedUnit}
                                    </div>
                                }
                                {errorState === 1 && <Dialog open={true}><Alert severity="warning" onClose={() => { setErrorState(0) }}>Ingredient already in recipe!</Alert></Dialog>}
                                {errorState === 2 && <Dialog open={true} ><Alert severity="error" onClose={() => { setErrorState(0) }} >Unable to get data for this ingredient. Try again in a few minutes.</Alert></Dialog>}
                                {/* //code to convert amount to grams */}
                                {!isNaN(props.ingredient.amount) && props.ingredient.selectedUnit !== "g" && props.ingredient.nutrition && Math.round(props.ingredient.nutrition.weightPerServing.amount * props.ingredient.amount) > 0 && <span className='modify-recipe-amount-in-grams1'>
                                    {Math.round(props.ingredient.nutrition.weightPerServing.amount * props.ingredient.amount * 10) / 10}{props.ingredient.nutrition.weightPerServing.unit}</span>}
                            </div>
                        </div>
                        {/* {//code to convert amount to grams
                            !isNaN(props.ingredient.amount) && props.ingredient.selectedUnit !== "g" && props.ingredient.nutrition && <span className='modify-recipe-amount-in-grams1'>{Math.round(props.ingredient.nutrition.weightPerServing.amount * props.ingredient.amount * 10) / 10}{props.ingredient.nutrition.weightPerServing.unit}</span>} */}
                    </div>
                    {!props.isReadOnly && <Button className="modify-recipe-ingredient-delete-button" ingredientid={props.ingredient.id} variant="outlined" onClick={deleteIngredient}>X</Button>}

                    <SwipeableTemporaryDrawer
                        actionSheetOpen={isActionSheetOpen}
                        setActionSheetOpen={setIsActionSheetOpen}
                        list={props?.ingredient?.possibleUnits}
                        onChange={(ind) => {
                            let id = recipe.ingredients[props.index].id
                            let value = props?.ingredient?.possibleUnits[ind]
                            handleUnitActionSheetChange(id, value)
                        }}
                    />
                </div>
            )}
        </Draggable>
    )

}
