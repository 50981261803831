import React from "react";

import FoodSearchBox from "../food_search_box/food_search_box";

const CalendarAddFromSearch = (props) => {
  
    return (<div className="calendar-add-branded-product">
        <FoodSearchBox calendarDate={props.calendarDate} calendarCategory={props.calendarCategory} handleExitClick={props.handleExitClick} />
    </div>)

}

export default CalendarAddFromSearch;