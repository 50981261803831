import React from "react";
import "./landing_mobile_simple_planning.css";
import appStoreBadge from "images/app_store_badge.png";
import playStoreBadge from "images/play_store_badge.png";
import Navigation from 'components/shared/navigation/navigation';

export const LandingMobileSimplePlanning = () => {
    return (
        <>                <Navigation />
            <div className="home-mobile-simple-planning">
                <div className="div-simple">
                    <img className="ellipse-simple" alt="Ellipse" src="ellipse-180.png" />
                    <div className="overlap-group-simple">
                        <p className="plan-your-meals-for-simple">
                        Manage your Recipes with BiteTracker

                        </p>
                        <p className="text-wrapper-simple">
                        BiteTracker is a completely free app that allows you to <b>store and import recipes </b>as well as access our “BT Basics” recipe book. 
                        <br></br>
                        <br></br>
                        With our app you can plan your days by adding recipes to your calendar and then <b>ordering ingredients automatically</b> via Instacart, AmazonFresh or WholeFoods. BiteTracker also allows you to track nutritional information of recipes, generic and branded foods. 

                        </p>
                        <img className="group-simple" alt="Group" src="group-18441.png" />
                        <a href="https://apps.apple.com/us/app/thebitetracker/id6473445195"><img className="landing-mobile-appstore-badge-simple" alt="Group" src={appStoreBadge} /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.thebitetracker.com"><img className="landing-mobile-playstore-badge-simple" alt="Group" src={playStoreBadge} /></a>


                        <img className="image-simple" alt="" src="image-4.png" />
                        <img className="group-2-simple" alt="Group" src="group-48095625.png" />
                        <img className="group-3-simple" alt="Group" src="group-48095631.png" />

                        <div className="text-wrapper-12-simple">Support by</div>
                        <img className="group-10-simple" alt="Group" src="amazonAndWholeFoods.png" />
                        <img className="rectangle-2-simple" alt="Rectangle" src="rectangle-39442.png" />
                    

                    </div>

                    {/* 
                    <NavLink to="/calendar">
                        <img className="img-bottom" alt="Rectangle" src="rectangle-73.png" />
                        <div className="text-wrapper-2-bottom">Try it now</div>
                        <img className="arrow-bottom" alt="Arrow" src="arrow-2.png" />

                    </NavLink> */}

                    {/* <img className="vector" alt="Vector" src="vector.png" />
                    <img className="vector-2" alt="Vector" src="image.png" />
                    <img className="vector-3" alt="Vector" src="vector-2.png" /> */}
                </div>
            </div>        </>

    );
};
