// helpers/data_helpers.js

export const groupDataByWeek = (data) => {
    const weeks = {};
  
    data.forEach((entry) => {
      const date = new Date(entry.date);
      const weekNumber = getWeekNumber(date);
      const year = date.getFullYear();
      const weekKey = `${year}-W${weekNumber}`;
  
      if (!weeks[weekKey]) {
        weeks[weekKey] = [];
      }
      weeks[weekKey].push(entry);
    });
  
    return weeks;
  };
  
  export const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear =
      (date - firstDayOfYear) / (24 * 60 * 60 * 1000);
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };
  
  export const aggregateWeeklyData = (groupedData) => {
    return Object.keys(groupedData).map((week) => {
        const weekData = groupedData[week];
        const numDaysWithData = weekData.length;

        const totalCaloriesFromProtein = weekData.reduce(
            (sum, day) => sum + (day.caloriesFromProtein || 0),
            0
        );
        const totalCaloriesFromCarbs = weekData.reduce(
            (sum, day) => sum + (day.caloriesFromCarbs || 0),
            0
        );
        const totalCaloriesFromFat = weekData.reduce(
            (sum, day) => sum + (day.caloriesFromFat || 0),
            0
        );
        const totalCalories = weekData.reduce(
            (sum, day) => sum + (day.totalCalories || 0),
            0
        );

        // Compute averages and round to nearest integer
        const avgCaloriesFromProtein = Math.round(totalCaloriesFromProtein / numDaysWithData);
        const avgCaloriesFromCarbs = Math.round(totalCaloriesFromCarbs / numDaysWithData);
        const avgCaloriesFromFat = Math.round(totalCaloriesFromFat / numDaysWithData);
        const avgTotalCalories = Math.round(totalCalories / numDaysWithData);

        return {
            week,
            caloriesFromProtein: avgCaloriesFromProtein,
            caloriesFromCarbs: avgCaloriesFromCarbs,
            caloriesFromFat: avgCaloriesFromFat,
            totalCalories: avgTotalCalories,
        };
    });
};

