import './select_food_vendor.css'
import React from 'react';

import { UserContext } from 'contexts/user_context';


import axios from 'axios';
import { config } from 'Constants'
import { MenuContext } from 'contexts/menu_context';
import { CartContext } from 'contexts/cart_context';

axios.defaults.baseURL = config.API_URL;






const SelectFoodVendor = (props) => {
    const [foodVendorData, setFoodVendorData] = React.useState(null)
    const foodVendorID = React.useContext(UserContext).userObject.foodVendorID
    const setFoodVendor = React.useContext(UserContext).setFoodVendor
    const refreshMenuData = React.useContext(MenuContext).refreshMenuData
    const cartObject = React.useContext(CartContext).cartObject
    const setCartObject = React.useContext(CartContext).setCartObject

    React.useEffect(() => {
        const getVendors = async () => {
            try {
                const resp = await axios.get('/vendorOffer');
                setFoodVendorData(resp.data);
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        }
        getVendors()


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function selectMenu(e) {
        if (e.currentTarget.getAttribute('vendorid') !== foodVendorID) {
            setCartObject([])
            setFoodVendor(e.currentTarget.getAttribute('name'), e.currentTarget.getAttribute('vendorid'), e.currentTarget.getAttribute('deliveryRate'))
            await refreshMenuData(e.currentTarget.getAttribute('vendorid'), e.currentTarget.getAttribute('minimummeals'))
        }
        //using setTimeout to avoid race conditions
        props.afterSelect()
    }

    let rows = []


    for (let vendorIndex in foodVendorData) {
        let vendor = foodVendorData[vendorIndex]
        if (!vendor.Offer.DeliveryDates || vendor.Offer.DeliveryDates.length===0) {
            rows.push(
                <div key={"food-vendor-" + vendor.ID} className="food-vendor-row" vendorid={vendor.ID} name={vendor.Name} deliveryRate={vendor.DeliveryRate} minimummeals={vendor.MinimumOrderMeals} style={{  opacity: '0.6'}}>
                    <img className="food-vendor-picture" src={vendor.PictureURL} alt={vendor.Name} />
                    <div className="food-vendor-column">

                        <div className="food-vendor-name">{vendor.Name}</div>
                      
                        <div className="food-vendor-details">{vendor.UnavailableMessage}</div>
                       
                    </div>
                </div>

            )
        }
        else{
            rows.unshift(
                <div key={"food-vendor-" + vendor.ID} className="food-vendor-row" vendorid={vendor.ID} name={vendor.Name} deliveryRate={vendor.DeliveryRate} minimummeals={vendor.MinimumOrderMeals} onClick={selectMenu} style={{
                    cursor: 'pointer'
                }}>
                    <div className="food-vendor-delivery-cost">${vendor.DeliveryRate} Delivery Fee</div>
                    <img className="food-vendor-picture" src={vendor.PictureURL} alt={vendor.Name} />
                    <div className="food-vendor-column">

                        <div className="food-vendor-name">{vendor.Name}</div>
                        {/* <div className="food-vendor-rating"><Rating name="read-only" size="small" value={4.5} precision={0.5} readOnly /> (23 reviews)</div> */}
                        {/* <div className="food-vendor-details">
                    <b>Price: </b>$$<span style={{ color: "rgb(0,0,0,0.3)", margin: '0' }}>$$</span>
                </div> */}
                        <div className="food-vendor-details">
                            <b>Cals:</b> {vendor.RecommendedDailyCalories}
                        </div>
                        <div className="food-vendor-details">
                            <b>Delivery:</b> {vendor.DeliveryDays}
                        </div>
                        <div className="food-vendor-details">
                            <b>Minimum Order:</b> {vendor.MinimumOrderMeals} meals
                        </div>

                        <div></div>
                    </div>
                </div>

            )
        }

    }


    return (
        <div className="select-food-vendor">
            <div className="select-food-vendor-header">Select meal-prep service</div>

            {cartObject.length > 0 && <div className="select-food-vendor-warning">Switching vendors will clear {cartObject.length} item(s) in your cart</div>}

            <div className="select-food-vendor-selector">

                {rows}


            </div>
        </div>
    )
}



export default SelectFoodVendor;