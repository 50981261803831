import React from "react";
import "./landing_desktop_simple.css";
import wholeFoods from "images/whole-foods.png";
import appStoreBadge from "images/app_store_badge.png";
import playStoreBadge from "images/play_store_badge.png";

import ReactPixel from 'react-facebook-pixel';

import { CartContext } from 'contexts/cart_context';
import { AuthContext } from 'contexts/auth_context';

export const LandingDesktopSimple = () => {
  const loadUsers = React.useContext(CartContext).loadUsers;
  const isNewlyWrittenUser = React.useContext(AuthContext).isNewlyWrittenUser

  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  ReactPixel.init('981117496755244', options);

  React.useEffect(() => {
    loadUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewlyWrittenUser]);


  return (
    <div className="home-page-version-simple">
      <div className="div">
        <img className="page" alt="Page" src="https://c.animaapp.com/DVqGB5xQ/img/page-1@2x.png" />

        <div className="overlap">
          <p className="plan-your-meals-for">
            Reach Your Nutrition Goals with BiteTracker

          </p>
          <div className="overlap-group">
            <p className="text-wrapper-10">
              BiteTracker is a completely free app that allows you to <b>track calories and macros</b> of <b>both generic and branded foods</b>.  With our app you can also plan your meals by adding recipes and ordering ingredients automatically via Instacart, AmazonFresh or WholeFoods.
            </p>
            <div className="overlap-2">
              <div className="overlap-wrapper">
                <div className="element-free-wrapper">
                  <div className="element-free">
                    100%
                    <br />
                    free
                  </div>
                </div>
              </div>
              <img className="image" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-1@2x.png" />
              <img className="image-2" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-3@2x.png" />
              <img className="image-3" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-4@2x.png" />
              <img className="image-4" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-7@2x.png" />
              <img className="image-5" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-17@2x.png" />
              <img className="image-6" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-11@2x.png" />
              <img
                className="set-of-slice"
                alt="Set of slice"
                src="https://c.animaapp.com/DVqGB5xQ/img/set-of-slice-tomatoes-isolated-png-02-1@2x.png"
              />
              <img className="image-7" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-18@2x.png" />
              <img className="image-8" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-15@2x.png" />
              <img className="image-9" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-14@2x.png" />
              <img
                className="clip-path-group"
                alt="Clip path group"
                src="https://c.animaapp.com/DVqGB5xQ/img/clip-path-group-9@2x.png"
              />
              <img
                className="clip-path-group-2"
                alt="Clip path group"
                src="https://c.animaapp.com/DVqGB5xQ/img/clip-path-group-8@2x.png"
              />
              <img
                className="mariana-medvedeva"
                alt="Mariana medvedeva"
                src="https://c.animaapp.com/DVqGB5xQ/img/mariana-medvedeva-inwco9ycblc-unsplash-1.png"
              />
            </div>
            <img className="group-4" alt="Group" src="https://c.animaapp.com/DVqGB5xQ/img/group-6356290@2x.png" />
            <img className="landing-whole-foods" alt="Group" src={wholeFoods} />

            <a onClick={()=>{ReactPixel.trackCustom('visitAppStore', {'store':'Apple AppStore'})}} href="https://apps.apple.com/us/app/thebitetracker/id6473445195"><img className="landing-appstore-badge" alt="Group" src={appStoreBadge} /></a>
            <a onClick={()=>{ReactPixel.trackCustom('visitAppStore', {'store':'Google PlayStore'})}} href="https://play.google.com/store/apps/details?id=com.thebitetracker.com"><img className="landing-playstore-badge" alt="Group" src={playStoreBadge} /></a>

          </div>
          <div className="text-wrapper-11">Support by</div>
          <img className="capa" alt="Capa" src="https://c.animaapp.com/DVqGB5xQ/img/capa-1-1.svg" />
        </div>
        <img className="image-58" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-2@2x.png" />



      </div>
    </div>
  );
};
