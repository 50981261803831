// DateCircle.js
import React from 'react';
import './date_circle.css'

const DateCircle = ({ date, selected, onClick }) => {
    const dayOfMonth = date.getDate(); // Get the day of the month
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' }); // Get the short name of the weekday

    return (
        <div style={{ margin:'0px auto'}}>
            <div style={{ fontSize: '12px', marginBottom:'2px' }}>{dayOfWeek}</div> {/* Day name */}

            <div
                className={`date-circle ${selected ? 'selected' : ''}`}
                onClick={onClick}

            >
                <div style={{ fontSize: '14px'}}>{dayOfMonth}</div> {/* Day number */}
            </div>
        </div>
    );
};

export default DateCircle;
