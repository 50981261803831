import React from "react";

import { CartContext } from 'contexts/cart_context';

import { useMultiDrop } from 'react-dnd-multi-backend'
import { ItemTypes } from '../item_types/item_types'

const CalendarEmptyColumn = (props) => {
    const addOrMoveMealToColumn=React.useContext(CartContext).addOrMoveMealToColumn;
    const [[dropProps,drop]] = useMultiDrop({
      accept: ItemTypes.MEAL,
      drop: (item,monitor) => addOrMoveMealToColumn(props.calendarDate,item.meal),
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop()
      }),
    })
    
    return (<div ref={drop} className="calendar-empty-column" style={{
      backgroundColor:dropProps.isOver && dropProps.canDrop? "rgb(220, 220, 220)":"",
    }}>
      Drag & drop from recipes below
  </div>) 
    
}

export default CalendarEmptyColumn;      