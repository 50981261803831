import React, { useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth_context';
import { getAuth, signOut, signInWithCustomToken } from 'firebase/auth';
import { useHistory } from 'react-router-dom';

const AdvancedLogin = () => {
  const [userEmail, setUserEmail] = useState('');
  const { currentUser } = useContext(AuthContext);
  const [error, setError] = useState('');
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const auth = getAuth();
      
      // Get the current admin's ID token
      const idToken = await currentUser.getIdToken(true);

      console.log(idToken)

      // Send a request to the backend to get the custom token
      const response = await fetch('https://us-central1-z-com-thymeless.cloudfunctions.net/getCustomToken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ email: userEmail }),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }

      const data = await response.json();
      const customToken = data.customToken;

      // Sign out the admin user
      await signOut(auth);

      // Sign in as the target user using the custom token
      await signInWithCustomToken(auth, customToken);

      // Redirect to the desired page
      history.push('/dashboard');
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
  };

  // Ensure only admins can access this page
  if (!currentUser) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>Advanced Login</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <label>
          User Email:
          <input
            type="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            required
          />
        </label>
        <button type="submit">Login as User</button>
      </form>
    </div>
  );
};

export default AdvancedLogin;
