import React from "react";
import "./landing_tablet.css";
import calendario from "images/calendario.mp4";
// import wholeFoods from "images/whole-foods.png";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

import { CartContext } from 'contexts/cart_context';
import { AuthContext } from 'contexts/auth_context';
import appStoreBadge from "images/app_store_badge.png";
import playStoreBadge from "images/play_store_badge.png";


export const LandingTablet = () => {
  const loadUsers = React.useContext(CartContext).loadUsers;
  const isNewlyWrittenUser = React.useContext(AuthContext).isNewlyWrittenUser
  const { currentUser } = React.useContext(AuthContext)

  React.useEffect(() => {
    loadUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewlyWrittenUser]);


  return (
    <div className="ipad-pro">
      <div className="div">
        <div className="text-wrapper">How it works</div>
        <div className="overlap">
          <div className="text-wrapper-2">PROCESS</div>
          <div className="overlap-group">
            <p className="plan-your-meals-for">
              Plan your meals
              <br />
              for the week
            </p>
            <p className="p">
              Meet your calorie/macro goals with a few clicks using our built-in food calendar, recipe books and
              AmazonFresh integration
            </p>
            <div className="ipad-pro-top-right">
              <div className="group-6">
                <div className="overlap-4">
                  <img className="rectangle-2" alt="Rectangle" src="https://c.animaapp.com/DVqGB5xQ/img/rectangle-73-2.svg" />
                  <div className="group-7">
                    <div className="overlap-group-3">
                      <NavLink to="/calendar" ><div className="text-wrapper-13">Your Calendar</div></NavLink>
                      <img className="arrow-2" alt="Arrow" src="https://c.animaapp.com/DVqGB5xQ/img/arrow-2-2.svg" />
                    </div>
                  </div>
                </div>
              </div>
              {currentUser ? <div className="group-8-account">
                <div className="group-9-account">
                  <NavLink to="/planning" ><div className="text-wrapper-14-account">Recipes</div></NavLink>
                  <img className="arrow-3-account" alt="Arrow" src="https://c.animaapp.com/DVqGB5xQ/img/arrow-2.svg" />
                </div>
              </div> :
                <div className="group-8">
                  <div className="group-9">
                    <NavLink to="/account" ><div className="text-wrapper-14">Log in</div></NavLink>
                    <img className="arrow-3" alt="Arrow" src="https://c.animaapp.com/DVqGB5xQ/img/arrow-2.svg" />
                  </div>
                </div>
              }
            </div>

            <img className="group-2" alt="Group" src="group-18441.png" />
            <img className="image" alt="" src="image-1.png" />
            <img className="image-2" alt="" src="image-3.png" />
            <img className="image-3" alt="" src="image-4.png" />
            <img className="image-4" alt="" src="image-7.png" />
            <img className="image-5" alt="" src="image-17.png" />
            <img className="image-6" alt="" src="image-11.png" />
            <img className="set-of-slice" alt="Set of slice" src="set-of-slice-tomatoes-isolated-png-02-1.png" />
            <img className="image-7" alt="" src="image-18.png" />
            <img className="image-8" alt="" src="image-15.png" />
            <img className="image-9" alt="" src="image-14.png" />
            <img className="clip-path-group" alt="Clip path group" src="clip-path-group-9.png" />
            <img className="clip-path-group-2" alt="Clip path group" src="clip-path-group-8.png" />
            <img
              className="mariana-medvedeva"
              alt="Mariana medvedeva"
              src="mariana-medvedeva-iNwCO9ycBlc-unsplash-1.png"
            />
            <img className="group-3" alt="Group" src="text.svg" />
          </div>
          <div className="text-wrapper-3">Support by</div>
          <img className="capa" alt="Capa" src="capa-1-2.png" />

          <div className="ipad-pro-top-button">
            <div className="overlap-group-wrapper">
              <div className="overlap-3">
                <img className="rectangle" alt="Rectangle" src="https://c.animaapp.com/DVqGB5xQ/img/rectangle-73-1.svg" />
                <div className="group-5">
                  <div className="overlap-group-2">
                    <NavLink to="/calendar" ><div className="text-wrapper-12">Use on Web</div></NavLink>
                    <img className="arrow" alt="Arrow" src="https://c.animaapp.com/DVqGB5xQ/img/arrow-2-1.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href="https://apps.apple.com/us/app/thebitetracker/id6473445195"><img className="landing-tablet-appstore-badge" alt="Group" src={appStoreBadge} /></a>
            <a href="https://play.google.com/store/apps/details?id=com.thebitetracker.com"><img className="landing-tablet-playstore-badge" alt="Group" src={playStoreBadge} /></a>

          <img className="image-10" alt="" src="image-2.png" />
        </div>
        <p className="prepare-and-enjoy">
          Prepare and enjoy <br />
          your meals.
        </p>
        <div className="overlap-2">
          <div className="overlap-3">
            <div className="overlap-4">
              <img
                className="follow-our-easy-to"
                alt="Follow our easy to"
                src="follow-our-easy-to-follow-recipes-and-relish-in-the-joy-of-prepa.png"
              />
              <p className="eat-healthier">
                Eat healthier without
                <br />
                loosing time on planning
              </p>
              <img className="clip-path-group-3" alt="Clip path group" src="clip-path-group-6.png" />
            </div>
            <div className="overlap-5">
              <img className="clip-path-group-4" alt="Clip path group" src="clip-path-group-5.png" />
              <img className="clip-path-group-5" alt="Clip path group" src="clip-path-group-4.png" />
              <img className="clip-path-group-6" alt="Clip path group" src="clip-path-group-2.png" />
              <img
                className="set-of-slice-2"
                alt="Set of slice"
                src="set-of-slice-tomatoes-isolated-png-02-3.png"
              />
              <img className="image-11" alt="" src="image-22.png" />
              <img className="image-12" alt="" src="image-31.png" />
              <img className="image-13" alt="" src="image-23.png" />
              <img className="image-14" alt="" src="image-24.png" />
              <img
                className="set-of-slice-3"
                alt="Set of slice"
                src="set-of-slice-tomatoes-isolated-png-02-4.png"
              />
              <img className="image-15" alt="" src="image-33.png" />
              <video className="landing-tablet-video" width="100%" height="100%" loop autoPlay={true} muted src={calendario}>

              </video>
              <div className="text-wrapper-4">Master Your Meal Planning</div>
              <p className="text-wrapper-5">
                Start crafting your perfect meal plan today and take charge of your nutrition.
              </p>
            </div>
            <div className="overlap-6">
              <img className="clip-path-group-7" alt="Clip path group" src="clip-path-group-1.png" />
              <img className="image-16" alt="" src="image-20.png" />
              <img
                className="set-of-slice-4"
                alt="Set of slice"
                src="set-of-slice-tomatoes-isolated-png-02-5.png"
              />
              <img className="image-17" alt="" src="image-26.png" />
              <img
                className="set-of-slice-5"
                alt="Set of slice"
                src="set-of-slice-tomatoes-isolated-png-02-6.png"
              />
              <img className="image-18" alt="" src="image-32.png" />
              <img className="image-19" alt="" src="image-25.png" />
            </div>
            <img className="image-20" alt="" src="image-27.png" />
            <img className="image-21" alt="" src="image-21.png" />
            <div className="overlap-7">
              <img
                className="set-of-slice-6"
                alt="Set of slice"
                src="set-of-slice-tomatoes-isolated-png-02-2.png"
              />
              <img className="frame" alt="Frame" src="frame-6356123.png" />
            </div>
            <img className="image-22" alt="" src="image-30.png" />
            <img className="image-23" alt="" src="image-28.png" />
            <img className="image-24" alt="" src="image-29.png" />
            <div className="overlap-8">
              <img className="feta-omelette" alt="Feta omelette" src="feta-omelette-1.png" />
              <img className="ellipse" alt="Ellipse" src="ellipse-311.png" />
              <img className="ellipse-2" alt="Ellipse" src="ellipse-312.png" />
              <img className="ellipse-3" alt="Ellipse" src="ellipse-309.png" />
              <img className="ellipse-4" alt="Ellipse" src="ellipse-310.png" />
            </div>
            <div className="save-time-on">
              Save time on
              <br />
              ingredient shopping
            </div>
            <p className="reduce-your-food">
              Reduce your food <br />
              waste to ZERO
            </p>
            <p className="text-wrapper-6">Feel more organized and in control of your eating habits</p>
            <img className="set-of-slice-7" alt="Set of slice" src="set-of-slice-tomatoes-isolated-png-02-7.png" />
            <p className="text-wrapper-7">Get control of your health</p>
          </div>
          <img className="element" alt="Element" src="3857131-1.png" />
        </div>
        <div className="text-wrapper-8">Create Your Own Recipes</div>
        <p className="our-platform-allows">
          Our platform allows you to become the chef of your own kitchen. Mix and match ingredients, experiment with
          flavors, and create dishes that resonate with your unique palate. <br />
          <br />
          Ready to put your personal touch on your meals?
        </p>
        <div className="overlap-9">
          <div className="text-wrapper-9">Get your food same-day</div>
          <p className="as-soon-as-you-ve">
            As soon as you&#39;ve crafted your meal plan, simply order your ingredients, and let AmazonFresh or
            Instacart handle the rest. <br />
            <br />
            Your ingredients will be meticulously picked and
            <br />
            delivered with care.
          </p>
          <div className="overlap-group-2">
            <p className="text-wrapper-10">200+ macro-friendly recipes</p>
            <div className="text-wrapper-11">SNEAK PEAK</div>
            <img className="clip-path-group-8" alt="Clip path group" src="clip-path-group-7.png" />
            <img className="clip-path-group-9" alt="Clip path group" src="clip-path-group-3.png" />
            <img className="image-26" alt="" src="image-67.png" />

            <img className="cauliflower-puree" alt="Cauliflower puree" src="cauliflower-puree.png" />
            <img className="oatmeal-pancakes" alt="Oatmeal pancakes" src="oatmeal-pancakes.png" />
            <img className="zucchini-wedges" alt="Zucchini wedges" src="zucchini-wedges.png" />
            <img className="sweet-potato-fries" alt="Sweet potato fries" src="sweet-potato-fries.png" />
            <img className="sauteed-shrimp" alt="Sauteed shrimp" src="saute-ed-shrimp.png" />
            <img className="feta-omelette-2" alt="Feta omelette" src="feta-omelette.png" />
            <img className="image-27" alt="" src="image-64.png" />
            <img className="image-28" alt="" src="image-65.png" />
            <img className="image-29" alt="" src="image-63.png" />
            <img className="image-30" alt="" src="image-62.png" />
            <img className="image-31" alt="" src="image-69.png" />
            <img className="image-32" alt="" src="image-70.png" />
            <img className="image-33" alt="" src="image-71.png" />
            <img className="group-6" alt="Group" src="group-6356318.png" />
            <img className="vector-2" alt="Vector" src="vector-51.png" />
            <img
              className="sliced-mushroom"
              alt="Sliced mushroom"
              src="sliced-mushroom-isolated-on-a-transparent-background-png-1.png"
            />
            <img
              className="mushroom-slice"
              alt="Mushroom slice"
              src="mushroom-slice-isolated-on-a-transparent-background-png-1.png"
            />
            <img className="ellipse-5" alt="Ellipse" src="ellipse-1.png" />
            <img className="image-34" alt="" src="image-72.png" />
          </div>
          <img className="grilled-salmon" alt="Grilled salmon" src="grilled-salmon.png" />
          <img className="BT-shiitake" alt="Bt shiitake" src="bt-shiitake-mushrooms.png" />
          <img className="capa-2" alt="Capa" src="capa-1.png" />
          <img className="group-8" alt="Group" src="group-6356319.png" />
        </div>
        <p className="text-wrapper-13">Add recipe books into your profile</p>
        <p className="text-wrapper-14">
          Discover a world of delightful recipes and save them all in one place. You can easily add your favorite
          recipes to your personalized meal planning adventure!
        </p>
        <img className="group-9" alt="Group" src="group-18400.png" />
        <p className="plan-your-week">
          Plan your week, accounting
          <br />
          for your calories/macros
        </p>
        <p className="text-wrapper-15">
          Craft your perfect week by selecting from our diverse range of recipes, all while keeping your calorie and
          macronutrient goals in check.
        </p>
        <img className="group-10" alt="Group" src="group-18401.png" />
        <div className="order-ingredients">
          Order ingredients
          <br />
          via AmazonFresh
        </div>
        <p className="text-wrapper-16">
          Grocery shopping made effortless! Seamlessly integrate your meal plan with AmazonFresh and get your
          ingredients delivered to your doorstep.
        </p>
        <div className="capa-wrapper">
          <img className="capa-3" alt="Capa" src="capa-1-1.svg" />
        </div>
        <div className="overlap-10">
          <div className="overlap-11">
            <img className="clip-path-group-10" alt="Clip path group" src="clip-path-group.png" />
            <img className="image-35" alt="" src="image-34.png" />
            <img className="image-36" alt="" src="image-35.png" />
            <img className="image-37" alt="" src="image-54.png" />
            <img className="image-38" alt="" src="image-55.png" />
            <img className="image-39" alt="" src="image-57.png" />
            <img className="image-40" alt="" src="image-58.png" />
            <img className="group-11" alt="Group" src="group-6356310.png" />
            <img className="group-12" alt="Group" src="group-6356314.png" />
            <img className="image-41" alt="" src="image-52.png" />
            <img className="image-42" alt="" src="image-56.png" />
          </div>
          <div className="overlap-12">
            <img className="image-43" alt="" src="image-46.png" />
            <img className="image-44" alt="" src="image-53.png" />
            <img className="image-45" alt="" src="image-59.png" />
          </div>
        </div>
        <img className="vector-3" alt="Vector" src="set-of-slice-tomatoes-isolated-png-02-9.png" />
        <img className="drag-drop-from" alt="Drag drop from" src="set-of-slice-tomatoes-isolated-png-02-9.png" />
        <div className="text-wrapper-17">Craft Your Perfect Plate</div>
        <p className="manage-your-meals-by">
          Manage your meals by tracking calories, proteins, fats, and carbs, allowing you to craft a personalized,
          <br />
          well-balanced weekly meal plan that aligns perfectly with your nutritional goals.
        </p>
        <img className="set-of-slice-8" alt="Set of slice" src="set-of-slice-tomatoes-isolated-png-02-9.png" />
        <div className="overlap-13">
          <img className="group-13" alt="Group" src="group-48095555.png" />
          <img className="group-14" alt="Group" src="group-48095556.png" />
          <img className="image-46" alt="" src="image-49.png" />
        </div>
        <img className="image-47" alt="" src="image-66.png" />

        <div className="ipad-pro-top-button">
          <div className="overlap-group-wrapper-bottom">
            <div className="overlap-3">
              <img className="rectangle" alt="Rectangle" src="https://c.animaapp.com/DVqGB5xQ/img/rectangle-73-1.svg" />
              <div className="group-5">
                <div className="overlap-group-2">
                  <NavLink to="/calendar" ><div className="text-wrapper-12">Try it now</div></NavLink>
                  <img className="arrow" alt="Arrow" src="https://c.animaapp.com/DVqGB5xQ/img/arrow-2-1.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-tablet-footer">
          <div className="landing-tablet-footer-inner">
            <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=823334ce-e563-427c-a0bf-93463b10dea3"><div className="terms-conditions">Terms of Use</div></a>
            <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=713d822d-3691-46a8-a4f7-5a538d76124b"> <div className="terms-conditions">Privacy Policy</div></a>
            <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=f52f48b4-b14d-4771-8156-f35d484da8dc"> <div className="terms-conditions">Cookie Policy</div></a>

            <NavLink to="/blog" ><div className="terms-conditions">Blog</div></NavLink>
            <NavLink to="/contact_us" ><div className="terms-conditions">Contact Us</div></NavLink>


          </div>
        </div>
      </div>
    </div>
  );
};
