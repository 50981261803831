import React from 'react';
import axios from 'axios'
import { Alert } from '@material-ui/lab';
import LoopIcon from '@material-ui/icons/Loop';
import { cleanUpNutrients } from 'helpers/recipe_helpers';
import DoneIcon from '@material-ui/icons/Done';

const IngredientSearchRow = (props) => {
    const selectedUnit = props.ingredient.possibleUnits ? props.ingredient.possibleUnits[0] : 'g'
    let ingredient = props.ingredient
    let [errorState, setErrorState] = React.useState(0)
    let [loading, setLoading] = React.useState(false)
    const [showCheckmark, setShowCheckmark] = React.useState(null); // Change this line


    const handleIngredientClick = (e) => {
        if (props.recipe.ingredients.find(i => i.id === ingredient.id)) {
            //do nothing as the ingredient is already in the list
            setErrorState(1)
            return

        }

        let tempIngredients = props.recipe.ingredients.concat([])
        setLoading(true)
        axios.get('/ingredient/' + ingredient.id, { params: { unit: selectedUnit }, timeout: 3000 }).then(response => {
            if (response.data.id) {
                //need to only grab the nutrient information inside nutrition
                let newIngredient = { ...response.data, amount: 1, amountString: '1', selectedUnit: selectedUnit, name: ingredient.name }
                let ingredientNutrients = cleanUpNutrients(response.data.nutrition.nutrients)
                newIngredient['nutrition'] = { nutrients: ingredientNutrients, weightPerServing: newIngredient.nutrition.weightPerServing }
                tempIngredients.push(newIngredient)
                props.setRecipe({ ...props.recipe, ingredients: tempIngredients })
                setLoading(false)
                setShowCheckmark(true);
                setTimeout(() => {
                    setShowCheckmark(null);
                }, 800); // Duration of the animation
            }



        }).catch(err => {
            setErrorState(2)
            setLoading(false)
        });

    }


    return (
        <>
            <div key={`recipe-search-ingredient-row-${ingredient.id}`} className="recipe-search-ingredient-row">

                <div className='search-ingredient-image-div'>
                    <img className="search-ingredient-image" src={'https://spoonacular.com/cdn/ingredients_100x100/' + ingredient.image} alt={ingredient.name} />
                </div>

                <div className="search-ingredient-name">{ingredient.name}</div>
                {/* <TextField className="food-vendor-portal-textfield" label="amount" type="number" value={amount} onChange={handleAmountChange}>1</TextField>
                <Select
                    label="unit"
                    value={selectedUnit}
                    name="unit"
                    onChange={handleUnitSelectChange}
                >
                    {ingredient.possibleUnits.map((unit) => (
                        <MenuItem key={unit} value={unit}>
                            {unit}
                        </MenuItem>
                    ))}
                </Select> */}
                <button className="calendar-add-menu-meal-button" onClick={handleIngredientClick}>{loading ? <LoopIcon style={{ animation: 'spin 2s linear infinite' }} /> : showCheckmark ? <div className="checkmark-animation"><DoneIcon /></div> : "Add"}</button>
            </div>
            {errorState === 1 && <Alert severity="warning" onClose={() => { setErrorState(0) }}>Ingredient already in recipe!</Alert>}
            {errorState === 2 && <Alert severity="error" onClose={() => { setErrorState(0) }}>Error getting ingredient info, please try again in a few minutes</Alert>}
        </>
    )
}
export default IngredientSearchRow