import './modify_recipe_book.css'

import React from "react";
import { config } from 'Constants'
import Button from '@material-ui/core/Button';
import { Dialog, TextField } from "@material-ui/core";
import ModifyRecipe from "components/shared/modify_recipe/modify_recipe";
import { getMealNutrientAmount } from "helpers/recipe_helpers";
import { RecipeBooksContext } from "contexts/recipe_books_context";
import { CloseRounded } from "@material-ui/icons";
import { AuthContext } from "contexts/auth_context";
import { FixedSizeList } from 'react-window';
import _ from "lodash";
import { useDebouncedCallback } from 'use-debounce';
import ExtractRecipeFromUrl from '../extract_recipe_from_url/extract_recipe_from_url';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';



const Row = ({ data, index, style }) => {
    let meal = data.filteredRecipes[index]

    return (
        <div key={`modify-recipe-row-${meal.id}`} className="modify-recipe-row" style={style}><Button className="modify-recipe-row-button" value={meal.id} onClick={data.handleRecipeClick}>
            <img className="modify-recipe-row-picture" src={meal.picture} alt={meal.name} />
            <div className="modify-recipe-row-column">

                <div className="modify-recipe-row-name">{meal.name}</div>
                {getMealNutrientAmount(meal, "Calories") > 0 && <div className="modify-recipe-row-caloric-info">
                    <span className="caloric-chip caloric-chip-calories">{getMealNutrientAmount(meal, "Calories")} Cals</span>
                    <span className="caloric-chip caloric-chip-protein">P &nbsp; {getMealNutrientAmount(meal, "Protein")}g</span>
                    <span className="caloric-chip caloric-chip-carbs">C &nbsp; {getMealNutrientAmount(meal, "Carbohydrates")}g </span>
                    <span className="caloric-chip caloric-chip-fats">F &nbsp;{getMealNutrientAmount(meal, "Fat")}g</span>
                </div>}

            </div>
        </Button></div>
    )
};

const ModifyRecipeBook = (props) => {
    // const menu = React.useContext(MenuContext).getActiveMenu();
    const recipeBookRecipes = React.useContext(RecipeBooksContext).getRecipesForRecipeBook(props.recipeBook.id);
    // const setAndSaveRecipeBook = React.useContext(RecipeBooksContext).setAndSaveRecipeBook
    const [recipeBeingModified, setRecipeBeingModified] = React.useState(null)
    const [creatingNewRecipe, setCreatingNewRecipe] = React.useState(false)
    const { currentUser } = React.useContext(AuthContext)
    const [recipeFilterText, setRecipeFilterText] = React.useState("")
    const [extractRecipeDialogOpen, setExtractRecipeDialogOpen] = React.useState(false)
    const recipeBookListRef = React.useRef(null);


    const scrollToTopOfRecipeBookList = () => {
        // Step 2: Use the scrollTo method with offset 0 to scroll to the top
        recipeBookListRef.current.scrollTo(0);
      };

    function handleExtractRecipeDialogExitClick() {
        setExtractRecipeDialogOpen(false)
    }

    const [windowSize, setWindowSize] = React.useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const handleExitClick = () => {
        setRecipeBeingModified(null)
    }

    const handleRecipeClick = (e) => {
        let foundRecipe = recipeBookRecipes.find(r => r.id === e.currentTarget.value)
        setRecipeBeingModified(foundRecipe)
    }

    const debouncedLogFilterEvent = useDebouncedCallback(
        // function
        async (filteredText) => {
            try {
                if (filteredText.length >= 3) {
                    FirebaseAnalytics.logEvent({
                        name: "filtered_recipe",
                        params: { filtered_text: filteredText, currentUser: currentUser.uid }
                    })
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        // delay in ms
        1000
    );

    const handleRecipeFilterTextChange = (event) => {
        setRecipeFilterText(event.target.value)
        debouncedLogFilterEvent(event.target.value)

    }


    let isReadOnly = true;

    if (props.recipeBook.owners.includes(currentUser.uid)) {
        isReadOnly = false
    }

    // const handleChangeImage = async (e) => {
    //     let pictureFile = e.target.files[0]
    //     let blob = pictureFile.slice(0, pictureFile.size, "image/jpeg");
    //     let newFile = new File([blob], `${props.recipeBook.id}-${Math.floor(Number.MAX_SAFE_INTEGER * Math.random())}.jpeg`, { type: "image/jpeg" });
    //     let formData = new FormData();

    //     formData.append("oldURL", props.recipeBook.picture);

    //     formData.append("folder", currentUser.uid)
    //     formData.append("imgfile", newFile);

    //     const { data: response } = await axios.post('uploadPicture/', formData)
    //     props.recipeBook['picture'] = response

    //     setAndSaveRecipeBook(props.recipeBook)
    //     // picture: URL.createObjectURL(e.target.files[0])
    // }

    // const handleChange = (e) => {
    //     let newRecipeBook = { ...props.recipeBook, [e.target.name]: e.target.value }

    //     setAndSaveRecipeBook(newRecipeBook)
    //     props.setRecipeBook(newRecipeBook)
    // }

    function extractRecipeFromURL() {
        setExtractRecipeDialogOpen(true)
    }

    function addNewRecipe() {
        let newRecipe = _.cloneDeep(config.emptyRecipe)
        newRecipe['recipeBooks'].push(props.recipeBook.id)
        newRecipe['owners'] = [currentUser.uid]
        setRecipeBeingModified(newRecipe)
        setCreatingNewRecipe(true)
    }

    let filteredRecipes = []
    if (recipeFilterText === "") {
        //if no filters or categories are applied add the meal always
        filteredRecipes = recipeBookRecipes
    }
    else {
        for (let recipe of recipeBookRecipes) {
            if (recipe.name.toLowerCase().includes(recipeFilterText.toLowerCase())) {
                filteredRecipes.push(recipe)
            }
        }
    }

    // console.log(windowSize[0],windowSize[1])

    let offsetHeight = 220

    if (isReadOnly) {
        offsetHeight = 170
    }

    return (<>

        <div className="modify-recipe-book-modal" style={{ margin: '10px', textAlign: 'center' }}>

            <Button className='modify-recipe-exit-button' onClick={props.close}><CloseRounded fontSize="small" /></Button>

            <div className="modify-recipe-book-top">
                <div className="modify-recipe-book-top-column"> <img className="modify-recipe-book-picture" style={{ margin: 'auto' }} src={props.recipeBook.id === config.baseRecipeBookId ? "/recipe_book_bitetracker.png" : "/recipe_book_user.png"} alt={props.recipeBook.name} />
                    {/* <Button className="modify-recipe-change-image-button" variant="contained" component="label" label="upload file"><ImageIcon /> Change
                        <input type="file" name="picture" accept="image/jpeg" id="imgfile" onChange={handleChangeImage} hidden></input>

                    </Button> */}
                </div>
                <div className="modify-recipe-top-column">
                    {props.recipeBook.name}


                </div>
            </div>
            {recipeBookRecipes.length > 10 && <TextField className="modify-recipe-book-filter-textfield" placeholder='Filter & search recipes...' label="" value={recipeFilterText} onChange={handleRecipeFilterTextChange} />}

            <FixedSizeList
                height={filteredRecipes.length * 132 > windowSize[1] - offsetHeight - windowSize[1] / 32 ? windowSize[1] - offsetHeight - windowSize[1] / 32 : (filteredRecipes.length * 132) + 5}
                itemCount={filteredRecipes.length}
                itemSize={windowSize[0] > 500 ? 120 : 100}
                width={windowSize[0] > 565 ? 500 : windowSize[0] - 40}
                ref={recipeBookListRef}
                itemData={{
                    filteredRecipes,
                    handleRecipeClick
                    
                }}

            >
                {Row}
            </FixedSizeList>
            {recipeBookRecipes.length === 0 && <p className="empty-recipe-book-message">Your recipe book is empty - Add a new recipe or copy recipes from the BiteTracker recipe book</p>}
            {!isReadOnly && <div style={{marginTop:'5px'}}> <button className="calendar-add-menu-meal-button"  onClick={addNewRecipe}>Create New Recipe</button><br></br>
                <button className="calendar-add-menu-meal-button" onClick={extractRecipeFromURL}>Import Recipe From URL </button>
            </div>
            }
        </div >

        <Dialog maxWidth={'xl'} open={Boolean(recipeBeingModified)} >
            {<ModifyRecipe scrollToTopOfRecipeBookList={scrollToTopOfRecipeBookList} recipeBook={props.recipeBook} recipeBeingModified={recipeBeingModified} close={handleExitClick} creatingNewRecipe={creatingNewRecipe} setCreatingNewRecipe={setCreatingNewRecipe} isReadOnly={isReadOnly} setRecipeBookBeingModified={props.setRecipeBookBeingModified} />}

        </Dialog>

        <Dialog open={extractRecipeDialogOpen} onClose={handleExtractRecipeDialogExitClick} >
            <ExtractRecipeFromUrl handleExitClick={handleExtractRecipeDialogExitClick} />
        </Dialog>

    </>)

}

export default ModifyRecipeBook;