// components/shared/graphs/exercise_points_over_time_graph.js

import React from 'react';
import { UserContext } from 'contexts/user_context';
import { parseDateMonthYear } from 'helpers/date_helpers';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Brush } from 'recharts';
import { groupDataByWeek } from 'helpers/data_helpers';

const ExercisePointsOverTimeGraph = () => {
    const userObject = React.useContext(UserContext).userObject;
    let data = [];

    if (userObject.exerciseData && Object.keys(userObject.exerciseData).length > 0) {
        for (let dateStr of Object.keys(userObject.exerciseData)) {
            const date = parseDateMonthYear(dateStr);
            const exercises = userObject.exerciseData[dateStr];

            let totalFlexibility = 0;
            let totalSustainedCardioEffort = 0;
            let totalMuscleStrain = 0;
            let totalExplosiveEffort = 0;

            exercises.forEach(exercise => {
                const points = exercise.points || {};
                totalFlexibility += points.flexibility || 0;
                totalSustainedCardioEffort += points.sustainedCardioEffort || 0;
                totalMuscleStrain += points.muscleStrain || 0;
                totalExplosiveEffort += points.explosiveEffort || 0;
            });

            data.push({
                date: date,
                flexibility: totalFlexibility,
                sustainedCardioEffort: totalSustainedCardioEffort,
                muscleStrain: totalMuscleStrain,
                explosiveEffort: totalExplosiveEffort
            });
        }

        // Group data by week
        const groupedData = groupDataByWeek(data);

        // Aggregate data per week
        let aggregatedData = [];

        for (let week in groupedData) {
            const weekData = groupedData[week];

            const totalFlexibility = weekData.reduce((sum, item) => sum + item.flexibility, 0);
            const totalSustainedCardioEffort = weekData.reduce((sum, item) => sum + item.sustainedCardioEffort, 0);
            const totalMuscleStrain = weekData.reduce((sum, item) => sum + item.muscleStrain, 0);
            const totalExplosiveEffort = weekData.reduce((sum, item) => sum + item.explosiveEffort, 0);

            aggregatedData.push({
                week: week,
                flexibility: totalFlexibility,
                sustainedCardioEffort: totalSustainedCardioEffort,
                muscleStrain: totalMuscleStrain,
                explosiveEffort: totalExplosiveEffort
            });
        }

        // Modify week labels
        aggregatedData = aggregatedData.map((week) => {
            const weekStartDate = getDateOfISOWeek(week.week);
            const options = { month: 'numeric', day: 'numeric' };
            const formattedDate = weekStartDate.toLocaleDateString(undefined, options);
            return {
                ...week,
                weekLabel: `Week of ${formattedDate}`,
            };
        });

        // Sort the data by week
        aggregatedData.sort((a, b) => (a.week > b.week ? 1 : -1));

        return (
            <ResponsiveContainer  style={{marginLeft:'-15px'}} width="100%" height={300}>
                <AreaChart data={aggregatedData}>
                    <XAxis dataKey="weekLabel" />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="flexibility" stackId="1" name="Flexibility" stroke="#8884d8" fill="#8884d8" />
                    <Area type="monotone" dataKey="sustainedCardioEffort" stackId="1" name="Sustained Cardio" stroke="#82ca9d" fill="#82ca9d" />
                    <Area type="monotone" dataKey="muscleStrain" stackId="1" name="Muscle Strain" stroke="#ffc658" fill="#ffc658" />
                    <Area type="monotone" dataKey="explosiveEffort" stackId="1" name="Explosive Effort" stroke="#d0ed57" fill="#d0ed57" />
                    <Brush dataKey="weekLabel" height={30} stroke="#8884d8" />
                </AreaChart>
            </ResponsiveContainer>
        );
    } else {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <h2>No exercise data found.</h2>
                <p>Please log your exercise to see data.</p>
            </div>
        );
    }
};

// Helper function to get the start date of the ISO week
const getDateOfISOWeek = (isoWeekString) => {
    const [yearStr, weekStr] = isoWeekString.split('-W');
    const year = parseInt(yearStr, 10);
    const week = parseInt(weekStr, 10);

    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dayOfWeek = simple.getDay();
    const ISOweekStart = new Date(simple);

    if (dayOfWeek <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart;
};

export default ExercisePointsOverTimeGraph;
