import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth_context';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { getMealNutrientAmount } from 'helpers/recipe_helpers';
import { groupDataByWeek, aggregateWeeklyData } from 'helpers/data_helpers';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Legend,
    Brush,
} from 'recharts';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

const CaloriesOverTimeGraph = () => {
    const { currentUser } = useContext(AuthContext);
    const [weeklyData, setWeeklyData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchWeeklyData = async () => {
            try {
                const db = getFirestore();
                const calendarMealCollectionRef = collection(
                    db,
                    'users',
                    currentUser.uid,
                    'calendarMeals'
                );

                const timeFrame = new Date();
                timeFrame.setDate(timeFrame.getDate() - 180);

                const mealsQuery = query(
                    calendarMealCollectionRef,
                    where('calendarDate', '>', timeFrame)
                );

                const querySnapshot = await getDocs(mealsQuery);

                let data = [];

                querySnapshot.forEach((doc) => {
                    const tempDoc = doc.data();
                    if (tempDoc.calendarDate) {
                        tempDoc.calendarDate = new Date(
                            tempDoc.calendarDate.seconds * 1000
                        );

                        // Get nutrient amounts
                        const proteinAmount = getMealNutrientAmount(tempDoc, 'Protein');
                        const carbsAmount = getMealNutrientAmount(tempDoc, 'Carbohydrates');
                        const fatAmount = getMealNutrientAmount(tempDoc, 'Fat');

                        // Parse amounts to numbers and default to 0 if invalid
                        const proteinGrams = parseFloat(proteinAmount) || 0;
                        const carbsGrams = parseFloat(carbsAmount) || 0;
                        const fatGrams = parseFloat(fatAmount) || 0;

                        // Calculate calories from each macronutrient and round to nearest integer
                        const caloriesFromProtein = Math.round(proteinGrams * 4);
                        const caloriesFromCarbs = Math.round(carbsGrams * 4);
                        const caloriesFromFat = Math.round(fatGrams * 9);

                        const totalCalories =
                            caloriesFromProtein + caloriesFromCarbs + caloriesFromFat;

                        data.push({
                            date: tempDoc.calendarDate,
                            caloriesFromProtein,
                            caloriesFromCarbs,
                            caloriesFromFat,
                            totalCalories,
                        });
                    }
                });

                const groupedData = groupDataByWeek(data);
                let aggregatedData = aggregateWeeklyData(groupedData);

                // Log to Firebase Analytics
                FirebaseAnalytics.logEvent({
                    name: 'saw_macros_graph',
                });

                // Modify week labels
                aggregatedData = aggregatedData.map((week) => {
                    const weekStartDate = getDateOfISOWeek(week.week);
                    const options = { month: 'numeric', day: 'numeric' };
                    const formattedDate = weekStartDate.toLocaleDateString(
                        undefined,
                        options
                    );
                    return {
                        ...week,
                        weekLabel: `Week of ${formattedDate}`,
                    };
                });

                setWeeklyData(aggregatedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching weekly data:', error);
                setLoading(false);
            }
        };

        fetchWeeklyData();
    }, [currentUser.uid]);

    // Helper function to get the start date of the ISO week
    const getDateOfISOWeek = (isoWeekString) => {
        const [yearStr, weekStr] = isoWeekString.split('-W');
        const year = parseInt(yearStr, 10);
        const week = parseInt(weekStr, 10);

        const simple = new Date(year, 0, 1 + (week - 1) * 7);
        const dayOfWeek = simple.getDay();
        const ISOweekStart = new Date(simple);

        if (dayOfWeek <= 4) {
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        return ISOweekStart;
    };

    // Custom Tooltip Component
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const totalCalories = payload[0].payload.totalCalories;

            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: '#fff',
                        padding: '10px',
                        border: '1px solid #ccc',
                    }}
                >
                    <p className="label">
                        <strong>{label}</strong>
                    </p>
                    <p>Avg Total Calories: {totalCalories.toLocaleString()}</p>
                    {payload.map((entry, index) => (
                        <p key={`item-${index}`} style={{ color: entry.color }}>
                            {entry.name}: {entry.value.toLocaleString()}
                        </p>
                    ))}
                </div>
            );
        }

        return null;
    };

    const renderChart = () => {
        if (loading) {
            return (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <h2>Loading data...</h2>
                </div>
            );
        }

        if (weeklyData.length === 0) {
            return (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <h2>No data available for the selected period.</h2>
                    <p>Please track your meals to see weekly reports.</p>
                </div>
            );
        }

        return (
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={weeklyData}>
                    <XAxis dataKey="weekLabel" />
                    <YAxis tickFormatter={(value) => value.toLocaleString()} />
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                        type="monotone"
                        dataKey="caloriesFromProtein"
                        stackId="1"
                        name="Avg Protein Cals"
                        stroke="#24C581"
                        fill="#24C581"
                    />
                    <Area
                        type="monotone"
                        dataKey="caloriesFromCarbs"
                        stackId="1"
                        name="Avg Carbs Cals"
                        stroke="#25ABFF"
                        fill="#25ABFF"
                    />
                    <Area
                        type="monotone"
                        dataKey="caloriesFromFat"
                        stackId="1"
                        name="Avg Fat Cals"
                        stroke="#FF802C"
                        fill="#FF802C"
                    />
                    <Brush
                        dataKey="weekLabel"
                        height={30}
                        stroke="#2D7933"
                        startIndex={Math.max(weeklyData.length - 4, 0)}
                        endIndex={weeklyData.length - 1}
                    />
                </AreaChart>
            </ResponsiveContainer>
        );
    };

    return (
        <div
            className="weekly-report-page"
            style={{
                maxWidth: '800px',
                margin: '0 auto',
                marginLeft: '-10px',
                width: '100%',
            }}
        >
            {renderChart()}
            <div style={{ marginTop: '10px', fontSize: '12px', color: 'grey', marginLeft: '15px', textAlign: 'center' }}> Note it's better to skip whole days than to log partial days</div>

        </div>
    );
};

export default CaloriesOverTimeGraph;
