// import React from "react";
// import { CartContext } from 'contexts/cart_context';
// import { CheckoutContext } from 'contexts/checkout_context';
// import CheckoutCartRow from "../checkout_cart/checkout_cart_row";


const NavigationCartHover = (props) => {

    // const mealsForCheckout = React.useContext(CartContext).getMealsForCheckout();
    // const totalPrice = React.useContext(CheckoutContext).totalPrice;
    // const setTotalPrice = React.useContext(CheckoutContext).setTotalPrice;

    // const formatter = new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    //     minimumFractionDigits: 2
    // })

    // let cartRows = [
    // ]
    // for (let mealId in mealsForCheckout) {
    //     const mealObject = mealsForCheckout[mealId];
    //     cartRows.push(
    //         <div className="navigation-cart-hover-meal-row">
    //             <img className="navigation-cart-hover-meal-picture" src={mealObject.picture} alt={mealObject.name} />
    //             <div className="navigation-cart-hover-meal-name">{mealObject.name}</div>
    //             <div className="navigation-cart-hover-meal-quantity"> {mealObject.quantity} x {formatter.format(mealObject.price)}</div>
    //         </div>

    //     )
    // }
    // React.useEffect(() => {
    //     let localPrice = 0
    //     for (let mealId in mealsForCheckout) {
    //         localPrice += mealsForCheckout[mealId].price * mealsForCheckout[mealId].quantity;
    //         const mealObject = mealsForCheckout[mealId];
    //         cartRows.push(<CheckoutCartRow cartObject={props.cartObject} name={mealObject.name} quantity={mealObject.quantity} picture={mealObject.picture} price={mealObject.price} category={mealObject.category} id={mealId} key={mealId} />)
    //     }
    //     setTotalPrice(localPrice)

    // }, [JSON.stringify(mealsForCheckout)]);

    // return (<div>{cartRows}
    //     <div className="navigation-cart-hover-meal-cart-total"><b>Subtotal: ${totalPrice.toFixed(2)}</b></div></div>)
}

export default NavigationCartHover;