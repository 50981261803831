import './onboarding_wizard.css';
import React from 'react';
import freeIcon from 'images/free-icon.png';
import saveTimeIcon from 'images/save-time-icon.png';
import ideasIcon from 'images/ideas-icon.png';
import takeAwayOnboarding from 'images/take-away-rafiki-2.png';

import CalorieCalculator from '../calorie_calculator/calorie_calculator';
// import { AuthContext } from 'contexts/auth_context';
import CalorieBox from '../modify_calories/calorie_box';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { UserContext } from 'contexts/user_context';
import DietaryPreferences from '../dietary_preferences/dietary_preferences';
import { useHistory } from 'react-router-dom';

// Import the CheckCircle icon from Material UI
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const OnboardingWizard = (props) => {
  const [onboardingStage, setOnboardingStage] = React.useState(0);
  // const { currentUser } = React.useContext(AuthContext)
  const [isTracking, setIsTracking] = React.useState(true);
  const [skipped, setSkipped] = React.useState(false);
  const userObject = React.useContext(UserContext).userObject;
  const setUserObject = React.useContext(UserContext).setUserObject;
  let history = useHistory();

  // New state variables for tracking selections
  const [selectedTracking, setSelectedTracking] = React.useState(true);
  const [selectedPlanning, setSelectedPlanning] = React.useState(true);

  function handleFirstClick() {
    setOnboardingStage(1);
  }

  function handleNextClick() {
    if (!selectedTracking && !selectedPlanning) return;

    if (selectedPlanning && onboardingStage === 2) {
      setOnboardingStage(3); // Proceed to Dietary Preferences
    } else if (selectedTracking && onboardingStage === 2) {
      setOnboardingStage(4); // Proceed to Calorie Calculator
    } else if (onboardingStage === 3 && selectedTracking) {
      setOnboardingStage(4);
    } else {
      if (!selectedTracking) {
        handleEndOfOnboarding()
      }
      else {
        setOnboardingStage(5);
      }
    }
  }

  function handleSkipClick() {
    setSkipped(true);
    setOnboardingStage(5);
  }

  function handleEndOfOnboarding() {
    // Navigate accordingly based on selections
    if (selectedPlanning && selectedTracking) {
      // If both are selected, start tracking (as per your requirement)
      FirebaseAnalytics.logEvent({
        name: "chose_onboarding_option",
        params: { chosenOnboarding: 'both' }
      })

      props.setIsNewlyWrittenUser(false);
    } else if (selectedPlanning) {
      history.push('/planning');

      FirebaseAnalytics.logEvent({
        name: "chose_onboarding_option",
        params: { chosenOnboarding: 'planning' }
      })

      props.setIsNewlyWrittenUser(false);
    } else {
      props.setIsNewlyWrittenUser(false);
      FirebaseAnalytics.logEvent({
        name: "chose_onboarding_option",
        params: { chosenOnboarding: 'tracking' }
      })
    }
  }

  if (onboardingStage === 0) {
    return (
      <div style={{ padding: '20px 10px', maxWidth: '400px', width: 'calc(100vw - 20px)' }}>
        <div className="onboarding-wizard-title-first">Welcome to</div>
        <div className="onboarding-wizard-title-first-next">
          BITE<span style={{ color: '#2D7933' }}>TRACKER</span>!
        </div>

        <div className="onboarding-wizard-upsell-row">
          <div className="onboarding-wizard-upsell-row-icon">
            <img className="onboarding-wizard-upsell-row-icon-inner" alt="Group" src={freeIcon} />
          </div>
          <div className="onboarding-wizard-upsell-row-text-first">
            <div>
              We are <b>100% free</b> - with no hidden fees & no subscriptions.
            </div>
          </div>
        </div>
        <div className="onboarding-wizard-upsell-row">
          <div className="onboarding-wizard-upsell-row-icon">
            <img className="onboarding-wizard-upsell-row-icon-inner" alt="Group" src={saveTimeIcon} />
          </div>
          <div className="onboarding-wizard-upsell-row-text-first">
            We want to help you save time & achieve your goals - at <b>your pace.</b>
          </div>
        </div>

        <div className="onboarding-wizard-upsell-row">
          <div className="onboarding-wizard-upsell-row-icon">
            <img className="onboarding-wizard-upsell-row-icon-inner" alt="Group" src={ideasIcon} />
          </div>
          <div className="onboarding-wizard-upsell-row-text-first">
            It's still early days for us so keep an eye for new features. If you have feedback,{' '}
            <b>reach out to us!</b>
          </div>
        </div>
        <div style={{ margin: '30px auto 10px auto', maxWidth: '295px', textAlign: 'center' }}>
          <div style={{ textAlign: 'left' }}>Cheers,</div>
          <div style={{ paddingTop: '6px', fontFamily: 'Montserrat-SemiBold', textAlign: 'left' }}>
            The BiteTracker Team
          </div>
        </div>

        <div style={{ textAlign: 'center' }}>
          <img
            style={{ margin: '10px auto -5px auto', width: '300px' }}
            className="onboarding-wizard-upsell-row-image"
            alt="Group"
            src="3857131-1.png"
          />
        </div>

        <div style={{ textAlign: 'center' }}>
          <button onClick={handleFirstClick} className="calendar-add-menu-meal-button">
            Get Started
          </button>
        </div>
      </div>
    );
  }

  if (onboardingStage === 1) {
    return (
      <div style={{ padding: '20px 10px', maxWidth: '400px', width: 'calc(100vw - 20px)' }}>
        <div className="onboarding-wizard-title">With our app you will be able to:</div>

        <div className="onboarding-wizard-upsell-row">
          <div className="onboarding-wizard-upsell-row-inner">
            <img className="onboarding-wizard-upsell-row-image" alt="Recipe Book" key="1" src="group-18442.png" />
          </div>
          <div className="onboarding-wizard-upsell-row-text">
            <div className="onboarding-wizard-upsell-row-number">1</div>
            <div style={{ textAlign: 'center' }}>
              <b>Track your calories and macros</b>, exercise and hydration. Make it easy with our barcode scanning and
              AI Tracking.
            </div>
          </div>
        </div>

        <div className="onboarding-wizard-upsell-row">
          <div className="onboarding-wizard-upsell-row-text" style={{ marginLeft: '4px', marginRight: '10px', marginTop: '10px' }}>
            <div className="onboarding-wizard-upsell-row-number">2</div>
            <div>
              <b>Plan recipes </b> to cook & eat using our curated recipe book or by adding web/your own recipes
            </div>
          </div>
          <div className="onboarding-wizard-upsell-row-inner">
            <img
              style={{ marginTop: '10px', height: '80px' }}
              className="onboarding-wizard-upsell-row-image"
              key="2"
              alt="Calendar"
              src="group-18400.png"
            />
          </div>
        </div>

        <div className="onboarding-wizard-upsell-row" style={{ marginTop: '-3px' }}>
          <div className="onboarding-wizard-upsell-row-inner">
            <img className="onboarding-wizard-upsell-row-image" alt="takeAway" key="3" src={takeAwayOnboarding} />
          </div>
          <div className="onboarding-wizard-upsell-row-text">
            <div className="onboarding-wizard-upsell-row-number">3</div>
            <div>
              <b>Order recipe ingredients </b> of recipes planned directly via Instacart, WholeFoods or AmazonFresh.
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <button onClick={() => setOnboardingStage(2)} className="calendar-add-menu-meal-button">
            Next
          </button>
        </div>
      </div>
    );
  }

  if (onboardingStage === 2) {
    return (
      <div style={{ padding: '20px 10px', maxWidth: '400px', width: 'calc(100vw - 20px)' }}>
        <div className="onboarding-wizard-title">Where do you want to get started?</div>

        {/* Calorie Tracking Option */}
        <div
          className={`onboarding-wizard-button ${selectedTracking ? 'selected' : ''}`}
          onClick={() => setSelectedTracking(!selectedTracking)}
        >
          <div className="onboarding-wizard-button-image">
            <img
              className="onboarding-wizard-option-image"
              alt="Calorie Tracking"
              src="group-18442.png"
            />
          </div>
          <div className="onboarding-wizard-button-text">
            <div className="onboarding-wizard-button-title">Calorie Tracking</div>
            <div className="onboarding-wizard-button-content">
              Track the calories of foods via search, barcode scanning or with AI as well as your
              exercise and hydration levels.
            </div>
          </div>
          {selectedTracking && (
            <CheckCircleIcon
              className="onboarding-wizard-checkmark-icon"
              style={{ color: '#2D7933' }}
            />
          )}
        </div>

        {/* Recipe Planning Option */}
        <div
          className={`onboarding-wizard-button ${selectedPlanning ? 'selected' : ''}`}
          onClick={() => setSelectedPlanning(!selectedPlanning)}
        >
          <div className="onboarding-wizard-button-image">
            <img
              className="onboarding-wizard-option-image"
              alt="Recipe Planning"
              src="group-18400.png"
            />
          </div>
          <div className="onboarding-wizard-button-text">
            <div className="onboarding-wizard-button-title">Recipe Planning</div>
            <div className="onboarding-wizard-button-content">
              Plan the recipes you want to cook throughout the week and order ingredients via
              Instacart/Amazon.
            </div>
          </div>
          {selectedPlanning && (
            <CheckCircleIcon
              className="onboarding-wizard-checkmark-icon"
              style={{ color: '#2D7933' }}
            />
          )}
        </div>

        {/* Helper Text */}
        <div className="onboarding-wizard-helper-text">
          {selectedTracking && selectedPlanning && 'I am interested in both planning and tracking'}
          {!selectedTracking && selectedPlanning && 'I am interested only in recipe planning'}
          {selectedTracking && !selectedPlanning && 'I am interested only in calorie tracking'}
        </div>

        {/* Next Button */}
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button
            onClick={handleNextClick}
            disabled={!selectedTracking && !selectedPlanning}
            className={
              !selectedTracking && !selectedPlanning
                ? 'disabled-button'
                : 'calendar-add-menu-meal-button'
            }
          >
            Next
          </button>
        </div>
      </div>
    );
  }

  if (onboardingStage === 3) {
    return (
      <div>
        <div
          style={{ width: '270px', margin: '20px auto -10px auto', fontSize: '16px' }}
          className="onboarding-wizard-title"
        >
          Tell us your <br />
          <b style={{ color: '#2D7933' }}>dietary preferences</b>
        </div>
        <DietaryPreferences handleExitClick={handleNextClick} skip={handleNextClick} />
      </div>
    );
  }

  if (onboardingStage === 4) {
    return (
      <div>

        <CalorieCalculator handleExitClick={handleSkipClick} skip={handleSkipClick} />
      </div>
    );
  }

  if (onboardingStage === 5) {
    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <CalorieBox skipped={skipped} />
        <div style={{ height: '12px' }}></div>
        <div className="onboarding-wizard-form-submit-button">
          {skipped && (
            <>
              <div
                onClick={handleEndOfOnboarding}
                style={{ color: '#999999', fontSize: '14px', textAlign: 'center', fontFamily: 'Montserrat-SemiBold' }}
              >
                <span onClick={props.skip}>SKIP</span>
              </div>
              <div
                style={{ backgroundColor: '#259B36', opacity: '0.5', margin: '0px 0px 0px 15px' }}
                className="calorie-chart-green-dot"
              />
              <div
                style={{ backgroundColor: '#259B36', opacity: '0.65', margin: '0px 3px 0px 3px' }}
                className="calorie-chart-green-dot"
              />
              <div
                style={{ backgroundColor: '#259B36', opacity: '0.8', margin: '0px 10px 0px 0px' }}
                className="calorie-chart-green-dot"
              />
            </>
          )}
          {selectedTracking && selectedPlanning && (
            <button onClick={handleEndOfOnboarding} className="calendar-add-menu-meal-button">
              Start tracking
            </button>
          )}
          {selectedTracking && !selectedPlanning && (
            <button onClick={handleEndOfOnboarding} className="calendar-add-menu-meal-button">
              Start tracking
            </button>
          )}
          {!selectedTracking && selectedPlanning && (
            <button onClick={handleEndOfOnboarding} className="calendar-add-menu-meal-button">
              Start planning
            </button>
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default OnboardingWizard;
