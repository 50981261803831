
//React, router
import React from 'react';

//context
import { UserContext } from 'contexts/user_context';



import {  Slider, styled } from '@material-ui/core';

import _ from 'lodash';

import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';

const ProteinSlider = styled(Slider)({
    color: '#24C581',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
        height: 8
    },
    '& .MuiSlider-rail': {
        border: 'none',
        height: 8
    },
    '& .MuiSlider-thumb': {
        height: 18,
        width: 18,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 28,
        height: 28,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#24C581',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});


const CarbsSlider = styled(Slider)({
    color: '#25ABFF',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
        height: 8
    },
    '& .MuiSlider-rail': {
        border: 'none',
        height: 8
    },
    '& .MuiSlider-thumb': {
        height: 18,
        width: 18,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 28,
        height: 28,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#24C581',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const FatSlider = styled(Slider)({
    color: '#EE681D',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
        height: 8
    },
    '& .MuiSlider-rail': {
        border: 'none',
        height: 8
    },
    '& .MuiSlider-thumb': {
        height: 18,
        width: 18,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 28,
        height: 28,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#24C581',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});


const CalorieBoxBreakdown = (props) => {
    const userObject = React.useContext(UserContext).userObject;
    const setUserObject = React.useContext(UserContext).setUserObject;


    let protein0 = userObject.macroBreakdown['protein']['breakfast'];
    let protein1 = protein0 + userObject.macroBreakdown['protein']['lunch'];
    let protein2 = protein1 + userObject.macroBreakdown['protein']['dinner'];


    let carbs0 = userObject.macroBreakdown['carbs']['breakfast'];
    let carbs1 = carbs0 + userObject.macroBreakdown['carbs']['lunch'];
    let carbs2 = carbs1 + userObject.macroBreakdown['carbs']['dinner'];

    let fat0 = userObject.macroBreakdown['fat']['breakfast'];
    let fat1 = fat0 + userObject.macroBreakdown['fat']['lunch'];
    let fat2 = fat1 + userObject.macroBreakdown['fat']['dinner'];

    function handleMacroBreakdownChange(macro, value) {

        //breakfast is value[0]
        //lunch is value[1]-value[0]
        //dinner is value[2] - value[1]
        //snack is 100 - value[2]
        let obj = { 'breakfast': value[0], 'lunch': value[1] - value[0], 'dinner': value[2] - value[1], 'snack': 100 - value[2] }

        let newMacroBreakdown = _.cloneDeep(userObject.macroBreakdown)

        newMacroBreakdown[macro] = obj

        setUserObject({ ...userObject, macroBreakdown: newMacroBreakdown });


    }
    //user is inputting macros
    return (
        <div style={{ textAlign: 'center' }}>

            {!userObject.proteins && !userObject.fats && !userObject.carbs && <div style={{ margin: '30px 50px', color: 'grey' }}> Looks like you haven't added valid macros... <br></br>
                <button className='order-ingredients-button-for-browser' onClick={() => { props.setTabValue(0) }}>Add Macros</button></div>}

            {!!userObject.proteins && <>

                <div style={{ marginTop: '25px', fontFamily: 'Montserrat-SemiBold', marginBottom:'2px' }}>Protein target breakdown</div>
                <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <ProteinSlider
                        value={[protein0, protein1, protein2]}
                        aria-labelledby="discrete-slider-restrict"
                        onChange={(e, value) => { handleMacroBreakdownChange('protein', value) }}
                        step={1}
                        min={0}
                        max={100}
                        track={false}
                    />
                    <div className="macro-breakdown-label">
                        <span>Breakfast: {userObject.macroBreakdown.protein.breakfast}%
                            <div className="macro-breakdown-label-lower">{Math.round(userObject.macroBreakdown.protein.breakfast * parseInt(userObject.proteins) / 100)}g</div></span>
                        <span>Lunch: {userObject.macroBreakdown.protein.lunch}%
                            <div className="macro-breakdown-label-lower">{Math.round(userObject.macroBreakdown.protein.lunch * parseInt(userObject.proteins) / 100)}g</div></span>
                        <span>Dinner: {userObject.macroBreakdown.protein.dinner}%
                            <div className="macro-breakdown-label-lower">{Math.round(userObject.macroBreakdown.protein.dinner * parseInt(userObject.proteins) / 100)}g</div></span>
                        <span>Snack: {userObject.macroBreakdown.protein.snack}%
                            <div className="macro-breakdown-label-lower">{Math.round(userObject.macroBreakdown.protein.snack * parseInt(userObject.proteins) / 100)}g</div></span>

                    </div>
                </div>



            </>}

            {!!userObject.carbs && <>

                <div style={{ marginTop: '28px', fontFamily: 'Montserrat-SemiBold', marginBottom:'2px' }}>Carbs target breakdown</div>
                <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <CarbsSlider
                        value={[carbs0, carbs1, carbs2]}
                        aria-labelledby="discrete-slider-restrict"
                        onChange={(e, value) => { handleMacroBreakdownChange('carbs', value) }}
                        step={1}
                        min={0}
                        max={100}
                        track={false}
                    />
                    <div className="macro-breakdown-label">
                        <span>Breakfast: {userObject.macroBreakdown.carbs.breakfast}%
                            <div className="macro-breakdown-label-lower">{Math.round(userObject.macroBreakdown.carbs.breakfast * parseInt(userObject.carbs) / 100)}g</div></span>
                        <span>Lunch: {userObject.macroBreakdown.carbs.lunch}%
                            <div className="macro-breakdown-label-lower">{Math.round(userObject.macroBreakdown.carbs.lunch * parseInt(userObject.carbs) / 100)}g</div></span>
                        <span>Dinner: {userObject.macroBreakdown.carbs.dinner}%
                            <div className="macro-breakdown-label-lower">{Math.round(userObject.macroBreakdown.carbs.dinner * parseInt(userObject.carbs) / 100)}g</div></span>
                        <span>Snack: {userObject.macroBreakdown.carbs.snack}%
                            <div className="macro-breakdown-label-lower">{Math.round(userObject.macroBreakdown.carbs.snack * parseInt(userObject.carbs) / 100)}g</div></span>

                    </div>
                </div>



            </>}

            {!!userObject.fats && <>

                <div style={{ marginTop: '28px', fontFamily: 'Montserrat-SemiBold', marginBottom:'2px' }}>Fat target breakdown</div>
                <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <FatSlider
                        value={[fat0, fat1, fat2]}
                        aria-labelledby="discrete-slider-restrict"
                        onChange={(e, value) => { handleMacroBreakdownChange('fat', value) }}
                        step={1}
                        min={0}
                        max={100}
                        track={false}
                    />
                    <div className="macro-breakdown-label">
                        <span>Breakfast: {userObject.macroBreakdown.fat.breakfast}%
                            <div className="macro-breakdown-label-lower">{Math.round(userObject.macroBreakdown.fat.breakfast * parseInt(userObject.fats) / 100)}g</div></span>
                        <span>Lunch: {userObject.macroBreakdown.fat.lunch}%
                            <div className="macro-breakdown-label-lower">{Math.round(userObject.macroBreakdown.fat.lunch * parseInt(userObject.fats) / 100)}g</div></span>
                        <span>Dinner: {userObject.macroBreakdown.fat.dinner}%
                            <div className="macro-breakdown-label-lower">{Math.round(userObject.macroBreakdown.fat.dinner * parseInt(userObject.fats) / 100)}g</div></span>
                        <span>Snack: {userObject.macroBreakdown.fat.snack}%
                            <div className="macro-breakdown-label-lower">{Math.round(userObject.macroBreakdown.fat.snack * parseInt(userObject.fats) / 100)}g</div></span>

                    </div>
                </div>



            </>}

            {(!!userObject.proteins || !!userObject.fats || !!userObject.carbs) && <div className="calorie-meal-suggestion-calculator-message" style={{ marginTop: '11px', fontSize: '12px', display: 'flex', width: '307px', marginLeft: 'auto', marginRight: 'auto', alignItems: 'center' }}>
                <WbIncandescentIcon /> Pro-tip: spread your macros throughout your day to regulate blood sugar levels
            </div>
            }


        </div>
    );


}
export default CalorieBoxBreakdown