// components/shared/graphs/calories_burned_over_time_graph.js

import React from 'react';
import { UserContext } from 'contexts/user_context';
import { parseDateMonthYear } from 'helpers/date_helpers';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Brush } from 'recharts';
import { groupDataByWeek } from 'helpers/data_helpers';

const CaloriesBurnedOverTimeGraph = () => {
    const userObject = React.useContext(UserContext).userObject;
    let data = [];

    if (userObject.exerciseData && Object.keys(userObject.exerciseData).length > 0) {
        for (let dateStr of Object.keys(userObject.exerciseData)) {
            const date = parseDateMonthYear(dateStr);
            const exercises = userObject.exerciseData[dateStr];

            let totalCaloriesBurned = 0;

            exercises.forEach(exercise => {
                let caloriesBurned = parseFloat(exercise.caloriesBurned);
                if (!isNaN(caloriesBurned)) {
                    totalCaloriesBurned += caloriesBurned;
                }
            });

            data.push({
                date: date,
                totalCaloriesBurned: totalCaloriesBurned
            });
        }

        // Group data by week
        const groupedData = groupDataByWeek(data);

        // Aggregate data per week
        let aggregatedData = [];

        for (let week in groupedData) {
            const weekData = groupedData[week];
            const totalCaloriesBurned = weekData.reduce((sum, item) => sum + item.totalCaloriesBurned, 0);

            aggregatedData.push({
                week: week,
                totalCaloriesBurned: totalCaloriesBurned
            });
        }

        // Modify week labels
        aggregatedData = aggregatedData.map((week) => {
            const weekStartDate = getDateOfISOWeek(week.week);
            const options = { month: 'numeric', day: 'numeric' };
            const formattedDate = weekStartDate.toLocaleDateString(undefined, options);
            return {
                ...week,
                weekLabel: `Week of ${formattedDate}`,
            };
        });

        // Sort the data by week
        aggregatedData.sort((a, b) => (a.week > b.week ? 1 : -1));

        return (
            
            <ResponsiveContainer style={{marginLeft:'-15px'}} width="100%" height={300}>
                <AreaChart data={aggregatedData}>
                    <XAxis dataKey="weekLabel" />
                    <YAxis />
                    <Tooltip formatter={(value) => `${value.toFixed(0)} kcal`} />
                    <Area
                        type="monotone"
                        dataKey="totalCaloriesBurned"
                        name="Calories Burned"
                        stroke="#FF8042"
                        fill="#FFBB28"
                    />
                    <Brush dataKey="weekLabel" height={30} stroke="#FF8042" />
                </AreaChart>
            </ResponsiveContainer>
        );
    } else {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <h2>No exercise data found.</h2>
                <p>Please log your exercise to see data.</p>
            </div>
        );
    }
};

// Helper function to get the start date of the ISO week
const getDateOfISOWeek = (isoWeekString) => {
    const [yearStr, weekStr] = isoWeekString.split('-W');
    const year = parseInt(yearStr, 10);
    const week = parseInt(weekStr, 10);

    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dayOfWeek = simple.getDay();
    const ISOweekStart = new Date(simple);

    if (dayOfWeek <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart;
};

export default CaloriesBurnedOverTimeGraph;
