import React from "react";
// import { config } from 'Constants'
import Button from '@material-ui/core/Button';
import { Dialog } from "@material-ui/core";
// import { v4 as uuidv4 } from 'uuid';

import { RecipeBooksContext } from "contexts/recipe_books_context";
import ModifyRecipeBook from "./modify_recipe_book";
import { config } from "Constants";


const RecipeBookList = (props) => {
    // const menu = React.useContext(MenuContext).getActiveMenu();
    const recipeBooks = React.useContext(RecipeBooksContext).recipeBookList;
    const [recipeBookBeingModified, setRecipeBookBeingModified] = React.useState(null)

    const handleExitClick = () => {
        setRecipeBookBeingModified(null)
    }

    const handleRecipeClick = (e) => {
        setRecipeBookBeingModified(recipeBooks[e.currentTarget.value])
    }

    // function addNewRecipeBook() {
    //     let newRecipeBook = config.emptyRecipeBook
    //     newRecipeBook['id']= uuidv4()
    //     setRecipeBookBeingModified(newRecipeBook)
    //     setCreatingNewRecipeBook(true)F
    // }
    let rows = []
    for (let index in recipeBooks) {

        let recipeBook = recipeBooks[index]
        let element = 
            <div key={`recipe-book-row-${recipeBook.id}`} className="recipe-book-row"><Button value={index} onClick={handleRecipeClick}>
                <img className="recipe-book-picture" src={recipeBook.id===config.baseRecipeBookId?"/recipe_book_bitetracker.png":"/recipe_book_user.png"} alt={recipeBook.name} />
                <div className="recipe-book-column">

                    <div className="recipe-book-name">{recipeBook.name}</div>



                </div>
            </Button></div>
        if (recipeBook.id === config.baseRecipeBookId){
            rows.unshift(element)
        }
        else{
            rows.push(element)
        }
    }


    return (<>

        <div style={{ padding: '10px', margin: '10px 0px' }}>
            {rows}

        </div>
        {/* <Button onClick={addNewRecipeBook}>Create Recipe Book</Button> */}
        <Dialog className="recipe-book-dialog" maxWidth={'xl'} open={Boolean(recipeBookBeingModified)} onClose={handleExitClick} >
            {recipeBookBeingModified && <ModifyRecipeBook recipeBook={recipeBookBeingModified} setRecipeBookBeingModified={setRecipeBookBeingModified} setRecipeBook={setRecipeBookBeingModified} close={handleExitClick} />}

        </Dialog>
    </>)

}

export default RecipeBookList;