import React, { useState, useContext, useRef } from "react";
import { CartContext } from 'contexts/cart_context';
import Button from '@material-ui/core/Button';
import { RecipeBooksContext } from "contexts/recipe_books_context";
import { FixedSizeList } from "react-window";
import { Dialog, TextField } from "@material-ui/core";
import CreateNewRecipe from "../create_new_recipe/create_new_recipe";
import { decimalToFraction, getMealNutrientAmount, recalculateCaloriesForRecipe } from "helpers/recipe_helpers";
import _ from "lodash";
import LoopIcon from '@material-ui/icons/Loop';
import { useDebouncedCallback } from 'use-debounce';
import { AuthContext } from 'contexts/auth_context';
import ModifyRecipe from "../modify_recipe/modify_recipe";
import MealDetailsCard from "../meal_details_card/meal_details_card";
import { config } from "Constants";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import CopyRecipeDialog from "../modify_recipe/copy_recipe_dialog";
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { getWeeks } from 'helpers/date_helpers';
import MealPortioningSlider from "../meal_portioning_slider/meal_portioning_slider";
import DoneIcon from '@material-ui/icons/Done';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Row = ({ data, index, style }) => {
    let meal = data.userFilteredRecipes ? data.userFilteredRecipes[index] : data.cookingRecipes[index];
    let addMealToCart = data.addMealToCart ? data.addMealToCart : data.addCookingMealToCart;
    let handleEditRecipeClick = data.handleEditRecipeClick;
    let handleRecipeViewClick = data.handleRecipeViewClick ? data.handleRecipeViewClick : data.handleWebRecipeViewClick;
    let pictureURL = meal.picture.replace("https://storage.googleapis.com/thymeless-upload-storage/", "https://ik.imagekit.io/hk6gff5az/tr:w-150,h-150/");
    let showCheckmark = data.showCheckmark === meal.id || data.showCheckmark === meal.cooking_recipe_id;

    return (
        <div key={`calendar-add-menu-meal-row-${meal.id}`} style={style} className="calendar-add-menu-meal-row" >
            <img className="calendar-add-menu-meal-picture" value={meal.id ? meal.id : meal.spoonacularRecipeId} onClick={handleRecipeViewClick} style={{ marginRight: '10px' }} src={pictureURL} alt={meal.name} />
            <div className="calendar-add-menu-meal-column">
                <div onClick={handleRecipeViewClick} value={meal.id ? meal.id : meal.spoonacularRecipeId} className="calendar-add-menu-meal-name">{meal.name}</div>
                {!!meal.aggregateLikes && <div className="calendar-add-menu-meal-likes"> {meal.aggregateLikes} like{meal.aggregateLikes > 1 && "s"}</div>}
                {getMealNutrientAmount(meal, "Calories") > 0 && <div className="calendar-add-menu-meal-caloric-info">
                    <span className="caloric-chip caloric-chip-calories">{getMealNutrientAmount(meal, "Calories")} Cals</span>
                    <span className="caloric-chip caloric-chip-protein">{getMealNutrientAmount(meal, "Protein")}g</span>
                    <span className="caloric-chip caloric-chip-carbs">{getMealNutrientAmount(meal, "Carbohydrates")}g</span>
                    <span className="caloric-chip caloric-chip-fats">{getMealNutrientAmount(meal, "Fat")}g</span>
                </div>}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '13px' }}>
                    {data.cookingRecipes && <>
                        Servings: <span style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '-6px' }}><MealPortioningSlider portion={meal.portion} setPortion={data.setPortion} cooking_recipe_id={meal.cooking_recipe_id} shrink={true} smallPopup={false} /></span>
                    </>}
                    <button disabled={showCheckmark} className="calendar-add-menu-meal-button" variant="contained" color="primary" value={meal.cooking_recipe_id ? meal.cooking_recipe_id : meal.id} onClick={addMealToCart}>{showCheckmark ? <div className="checkmark-animation"><DoneIcon /></div> : "Add"}</button>
                    {data.userFilteredRecipes && <button className="calendar-add-menu-meal-button" style={{ background: 'rgb(129 197 36)' }} variant="contained" color="primary" value={meal.cooking_recipe_id ? meal.cooking_recipe_id : meal.id} onClick={handleEditRecipeClick}>Edit</button>}
                </div>
            </div>
        </div>
    );
};

const cookingWeeks = getWeeks(2, 2);

const CalendarAddRecipeMeal = (props) => {
    const addMealToColumn = useContext(CartContext).addOrMoveMealToColumn;
    const recipes = useContext(RecipeBooksContext).recipeList;
    const [recipeFilterText, setRecipeFilterText] = useState("");
    const { currentUser } = useContext(AuthContext);
    const getCustomRecipeBookId = useContext(RecipeBooksContext).getCustomRecipeBookId;
    const [recipeBeingModified, setRecipeBeingModified] = useState(null);
    const [recipeBeingCopied, setRecipeBeingCopied] = useState(null);
    const [recipeBeingViewed, setRecipeBeingViewed] = useState(null);
    const [showUserRecipes, setShowUserRecipes] = useState(true);
    const myRecipesRef = useRef(null);
    const cookingRecipeList = useContext(CartContext).cookingRecipeList;
    const [cookingRecipeListClone, setCookingRecipeListClone] = useState(cookingRecipeList);
    const [showCheckmark, setShowCheckmark] = useState(null); // Change this line

    const scrollToTopOfMyRecipes = () => {
        myRecipesRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest'
        });
        setShowUserRecipes(true);
    };

    const toggleShowUserRecipes = (event) => {
        setShowUserRecipes(!showUserRecipes);
    };

    const handleExitClick = () => {
        setRecipeBeingModified(null);
    };

    const handleViewingExitClick = () => {
        setRecipeBeingViewed(null);
    };

    const handleEditRecipeClick = (e) => {
        let foundRecipe = recipes.find(r => r.id === e.currentTarget.value);
        setRecipeBeingModified(foundRecipe);
    };

    const handleRecipeViewClick = (e) => {
        let foundRecipe = recipes.find(r => r.id === e.currentTarget.getAttribute('value'));
        if (!foundRecipe.portion) {
            foundRecipe['portion'] = 1
        }
        setRecipeBeingViewed(foundRecipe);
    };


    const setRecipeBeingViewedPortion = (portion) => {

        let recipeClone = _.cloneDeep(recipeBeingViewed)

        //with ingredients
        for (let ingredient of recipeClone.ingredients) {
            ingredient.amountString = decimalToFraction(ingredient.amount * portion / recipeClone.portion)
            ingredient.amount = ingredient.amount * portion / recipeClone.portion
        }


        recipeClone = recalculateCaloriesForRecipe(recipeClone)
        setRecipeBeingViewed({ ...recipeClone, portion: portion })
    }


    const debouncedLogFilterEvent = useDebouncedCallback(
        async (filteredText) => {
            try {
                if (filteredText.length >= 3) {
                    FirebaseAnalytics.logEvent({
                        name: "filtered_recipe",
                        params: { filtered_text: filteredText, currentUser: currentUser.uid }
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        1000
    );

    const addMealToCart = (e) => {
        let foundRecipe = recipes.find(r => r.id === e.currentTarget.value);
        addMealToColumn(props.calendarDate, _.cloneDeep({ ...foundRecipe, id: undefined }), props.calendarCategory ? props.calendarCategory : "breakfast");
        FirebaseAnalytics.logEvent({
            name: "added_recipe_to_calendar",
            params: { recipe_name: foundRecipe.name }
        });
        setShowCheckmark(foundRecipe.id);
        setTimeout(() => {
            setShowCheckmark(null);
        }, 1000); // Duration of the animation
    };

    const addCookingMealToCart = (e) => {
        let foundRecipe = cookingRecipeListClone.find(r => r.cooking_recipe_id === e.currentTarget.value);
        addMealToColumn(props.calendarDate, _.cloneDeep({ ...foundRecipe, id: undefined }), props.calendarCategory ? props.calendarCategory : "breakfast");
        FirebaseAnalytics.logEvent({
            name: "added_recipe_to_calendar",
            params: { recipe_name: foundRecipe.name }
        });
        setShowCheckmark(foundRecipe.cooking_recipe_id);
        setTimeout(() => {
            setShowCheckmark(null);
        }, 1000); // Duration of the animation
    };

    function setPortion(portion, cooking_recipe_id) {
        let cookingRecipeListCloneCopy = _.cloneDeep(cookingRecipeListClone);
        let foundRecipe = cookingRecipeListCloneCopy.find(r => r.cooking_recipe_id === cooking_recipe_id);

        for (let ingredient of foundRecipe.ingredients) {
            ingredient.amountString = decimalToFraction(ingredient.amount * portion / foundRecipe.portion);
            ingredient.amount = ingredient.amount * portion / foundRecipe.portion;
        }

        foundRecipe = recalculateCaloriesForRecipe(foundRecipe);
        foundRecipe['portion'] = portion;

        setCookingRecipeListClone(cookingRecipeListCloneCopy);
    }

    const addMealFromDetailsCard = async (recipe) => {
        if (!recipe.spoonacularRecipeId) {
            let recipeClone = _.cloneDeep(recipe);
            addMealToColumn(props.calendarDate, recipeClone, props.calendarCategory ? props.calendarCategory : "breakfast");
            FirebaseAnalytics.logEvent({
                name: "added_recipe_to_calendar",
                params: { recipe_name: recipeClone.name, currentUser: currentUser.uid, recipe_type: recipeClone.recipeBooks[0] === config.baseRecipeBookId ? "bitetracker" : "user" }
            });
        }
        setShowCheckmark(recipe.id);
        setTimeout(() => {
            setShowCheckmark(null);
        }, 1000); // Duration of the animation
    };

    const handleRecipeFilterTextChange = (event) => {
        setRecipeFilterText(event.target.value);
        debouncedLogFilterEvent(event.target.value);
    };

    let userFilteredRecipes = [];
    let cookingRecipes = [];
    let foundUserRecipe = false;
    let foundCookingRecipe = false;

    if (recipes === null) {
        return <></>;
    } else {
        for (let index in cookingRecipeListClone) {
            let cookingRecipe = cookingRecipeListClone[index];
            for (let cookingWeek of cookingWeeks) {
                if (cookingRecipe.week === cookingWeek.startOfWeek) {
                    cookingRecipes.push(cookingRecipe);
                    foundCookingRecipe = true;
                }
            }
        }

        for (let meal of recipes) {
            if (meal.recipeBooks[0] === config.baseRecipeBookId) {
                continue;
            } else {
                foundUserRecipe = true;
                if (recipeFilterText === "") {
                    userFilteredRecipes.push(meal);
                } else {
                    if (meal.name.toLowerCase().includes(recipeFilterText.toLowerCase())) {
                        userFilteredRecipes.push(meal);
                    }
                }
            }
        }
    }

    return (
        <><div style={{ width: 'calc(100vw - 20px)', maxWidth: '500px' }}>
            <div className="calendar-add-recipe-meal-top">
                <TextField className="calendar-add-menu-meal-filter-textfield" placeholder='Filter and search recipes...' label="" value={recipeFilterText} onChange={handleRecipeFilterTextChange} />
            </div>
            {recipes.length === 0 && <div style={{ textAlign: 'center' }}>Loading recipes <LoopIcon style={{ animation: 'spin 2s linear infinite' }} /></div>}
            <div className="calendar-add-recipe-meal-content">
                <div ref={myRecipesRef} onClick={toggleShowUserRecipes} className="calendar-add-recipe-meal-header">Planned Recipes
                    <Button className="calendar-meal-carousel-collapse-button">
                        {showUserRecipes ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </Button>
                </div>
                {!foundCookingRecipe && showUserRecipes && <div style={{ fontSize: '13px', color: 'grey', margin: '8px 10px -3px 10px' }}>
                    No planned recipes. Visit the <Link to="/planning" style={{ textDecoration: 'underline' }}> planning tab</Link> to plan & cook recipes.
                </div>}
                {showUserRecipes &&
                    <FixedSizeList
                        height={cookingRecipes.length * 127 + 10}
                        itemCount={cookingRecipes.length}
                        itemSize={127}
                        itemData={{
                            cookingRecipes,
                            addCookingMealToCart,
                            getCustomRecipeBookId,
                            handleRecipeViewClick,
                            setPortion,
                            showCheckmark
                        }}
                    >
                        {Row}
                    </FixedSizeList>
                }

                <div ref={myRecipesRef} onClick={toggleShowUserRecipes} className="calendar-add-recipe-meal-header">My Recipes
                    <Button className="calendar-meal-carousel-collapse-button">
                        {showUserRecipes ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </Button>
                </div>
                {!foundUserRecipe && showUserRecipes && <div style={{ fontSize: '13px', color: 'grey', margin: '8px 10px -3px 10px' }}>
                    Your recipe book is empty - Visit the <Link to="/planning" style={{ textDecoration: 'underline' }}> planning tab</Link> to add new recipes.
                </div>}
                {showUserRecipes &&
                    <FixedSizeList
                        height={userFilteredRecipes.length * 127 + 10}
                        itemCount={userFilteredRecipes.length}
                        itemSize={127}
                        itemData={{
                            userFilteredRecipes,
                            addMealToCart,
                            getCustomRecipeBookId,
                            handleEditRecipeClick,
                            handleRecipeViewClick,
                            showCheckmark
                        }}
                    >
                        {Row}
                    </FixedSizeList>
                }
            </div>
        </div>
            <Dialog maxWidth={'xl'} open={Boolean(recipeBeingModified)} >
                {<ModifyRecipe recipeBook={getCustomRecipeBookId} recipeBeingModified={recipeBeingModified} close={handleExitClick} creatingNewRecipe={false} setCreatingNewRecipe={null} disableCopy={false} />}
            </Dialog>

            <Dialog open={Boolean(recipeBeingViewed)} maxWidth={'xl'} >
                <MealDetailsCard isReadOnly={true} addToCalendarEnabled={true} portion={recipeBeingViewed?.portion} setPortion={setRecipeBeingViewedPortion} handleExitClick={handleViewingExitClick} meal={recipeBeingViewed} customAddMealFunction={addMealFromDetailsCard} />
            </Dialog>

            <CopyRecipeDialog setRecipeFilterText={setRecipeFilterText} attemptedCopy={Boolean(recipeBeingCopied)} setAttemptedCopy={(value) => { setRecipeBeingCopied(null) }} recipe={recipeBeingCopied} scrollToTopOfRecipeBookList={scrollToTopOfMyRecipes} />
        </>
    );
};

export default CalendarAddRecipeMeal;
