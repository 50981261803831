import './calendar_meal_carousel.css'

import React from "react";

import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Lazy } from 'swiper';
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
//mock data for the menu
import CalendarMealCarouselMeal from './calendar_meal_carousel_meal'
import Filters from '../filters/filters';
import { useResizeDetector } from 'react-resize-detector';
import { RecipeBooksContext } from 'contexts/recipe_books_context';
import { TextField } from '@material-ui/core';
import { useDebouncedCallback } from 'use-debounce';
import CreateNewRecipe from '../create_new_recipe/create_new_recipe';
import { AuthContext } from 'contexts/auth_context';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

SwiperCore.use([Navigation, Lazy]);

const CalendarMealCarousel = (props) => {
    const isCollapsed = props.isCollapsed
    const setCollapsed = props.setCollapsed
    //const menu = React.useContext(MenuContext).getActiveMenu();
    const recipes = React.useContext(RecipeBooksContext).recipeList;
    // const getCurrentDeliveryDate = React.useContext(MenuContext).getCurrentDeliveryDate
    let [meals] = React.useState(null)
    const filters = props.filters
    const mealCategoriesFiltered = props.mealCategoriesFiltered
    const recipeBooksFiltered = props.recipeBooksFiltered
    const [recipeFilterText, setRecipeFilterText] = React.useState("")
    const [swiper, setSwiper] = React.useState(null);
    const [hasSwiperReset, setHasSwiperReset] = React.useState(false)
    const { currentUser } = React.useContext(AuthContext)


    React.useEffect(() => {
        if (swiper && swiper.lazy) {
            swiper.lazy.load()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, recipeFilterText, mealCategoriesFiltered, recipeBooksFiltered, recipes]);

    const onResize = React.useCallback(() => {
        if (swiper) {
            swiper.update()
        }
    }, [swiper]);
    const { width, ref } = useResizeDetector({ onResize });

    const toggleCollapse = (event) => {
        if (isCollapsed) {
            setHasSwiperReset(true)
        }
        setCollapsed(!isCollapsed)
    }

    const debouncedLogFilterEvent = useDebouncedCallback(
        // function
        async (filteredText) => {
            try {
                if (filteredText.length >= 3) {
                    FirebaseAnalytics.logEvent({
                        name: "filtered_recipe",
                        params: { filtered_text: filteredText, currentUser: currentUser.uid }
                    })
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        // delay in ms
        1000
    );


    const handleRecipeFilterTextChange = (event) => {
        setRecipeFilterText(event.target.value)
        debouncedLogFilterEvent(event.target.value)
    }


    let slidesPerView = 1

    if (width !== undefined) {
        slidesPerView = window.innerWidth < 804 ? Math.floor(width / 140) : Math.floor(width / 155);
    }

    if (hasSwiperReset && swiper && swiper.params && swiper.activeIndex > 0) {
        setHasSwiperReset(false)
        setTimeout(() => swiper.slideTo(0, 0))
        // if (swiper.activeIndex === 0) { 


    }

    if (recipes === null || recipes.length === 0) {
        return <></>
    }

    else {
        meals = []
        let filtersApplied = []
        for (let filterValue in filters) {
            if (filters[filterValue]) {
                filtersApplied.push(filterValue)
            }
        }

        if (mealCategoriesFiltered.length !== 0) {
            filtersApplied.push(mealCategoriesFiltered)

        }

        if (recipeBooksFiltered.length !== 0) {
            filtersApplied.push(recipeBooksFiltered)


        }

        for (let meal of recipes) {
            if (filtersApplied.length === 0 && mealCategoriesFiltered.length === 0 && recipeBooksFiltered.length === 0 && recipeFilterText === "") {
                //if no filters or categories are applied add the meal always
                meals.push(<SwiperSlide key={`slide-${meal.id}`}><CalendarMealCarouselMeal meal={meal}></CalendarMealCarouselMeal></SwiperSlide>)
            }
            else {
                let tags = meal.tags.concat(meal.categories).concat(meal.recipeBooks)
                let allTagsFound = true

                //when there's some filters applied make sure we can find all of them in the tags
                for (let filterValue in filtersApplied) {
                    if (tags === null) {
                        allTagsFound = false
                        break
                    }
                    if (!tags.includes(filtersApplied[filterValue])) {
                        allTagsFound = false
                    }
                }
                if (allTagsFound) {
                    if (meal.name.toLowerCase().includes(recipeFilterText.toLowerCase())) {
                        meals.push(<SwiperSlide key={`slide-${meal.id}`}><CalendarMealCarouselMeal meal={meal}></CalendarMealCarouselMeal></SwiperSlide>)
                    }

                }
            }
        }

        return (

            <div className="calendar-meal-picker" >

                {isCollapsed && <div style={{ textAlign: 'center' }}> <Button className="calendar-meal-picker-expand-button" onClick={toggleCollapse}>
                    <KeyboardArrowUpIcon />Show recipes
                </Button></div>}

                {!isCollapsed && <>

                    <Collapse in={!isCollapsed}>

                        <div className="calendar-meal-carousel" >
                            {props.isFiltersCollapsed && !props.isFiltersHidden && <div className="calendar-meal-carousel-collapsed-filters" />}
                            <div className={props.isFiltersHidden ? "calendar-meal-carousel-top-filters-removed" : props.isFiltersCollapsed ? "calendar-meal-carousel-top-filters-collapsed" : "calendar-meal-carousel-top"}>

                                <Button className="calendar-meal-carousel-collapse-button" onClick={toggleCollapse}>
                                    <KeyboardArrowDownIcon />
                                </Button>
                                <TextField className="calendar-meal-carousel-filter-textfield" placeholder='Search recipes...' label="" value={recipeFilterText} onChange={handleRecipeFilterTextChange} />
                                <CreateNewRecipe></CreateNewRecipe>
                            </div>
                            {!props.isFiltersCollapsed && !props.isFiltersHidden && <div className="meal-picker-filters"> <Filters filters={props.filters} setFilters={props.setFilters} mealCategoriesFiltered={props.mealCategoriesFiltered}
                                setMealCategoriesFiltered={props.setMealCategoriesFiltered} recipeBooksFiltered={props.recipeBooksFiltered} setRecipeBooksFiltered={props.setRecipeBooksFiltered} /></div>}
                            {meals.length !== 0 && <div className="calendar-meal-carousel-previous"><NavigateBeforeIcon /></div>}

                            {meals.length === 0 && <div className="calendar-meal-picker-empty">No meals to show for the combination of filters selected <CreateNewRecipe></CreateNewRecipe></div>}

                            {meals.length !== 0 && <><div className="calendar-meal-carousel-swiper-container" style={{ maxWidth: (meals.length * 174) + 'px' }} ref={ref} >

                                <Swiper

                                    onSwiper={setSwiper}
                                    preloadImages={false}
                                    lazy={{
                                        loadPrevNext: true,
                                        loadPrevNextAmount: 15
                                    }}
                                    id="main"
                                    spaceBetween={0}
                                    watchSlidesVisibility={true}

                                    slidesPerView={meals.length > slidesPerView ? slidesPerView : meals.length}
                                    slidesPerGroup={3}
                                    speed={750}
                                    navigation={{ prevEl: ".calendar-meal-carousel-previous", nextEl: ".calendar-meal-carousel-next" }}
                                    allowTouchMove={false}
                                    shortSwipes={false}
                                > {meals} </Swiper></div>
                                <div className="calendar-meal-carousel-next"><NavigateNextIcon /></div>

                            </>}
                        </div>


                    </Collapse>
                </>


                }
            </div>

        );
    }
}

export default CalendarMealCarousel;