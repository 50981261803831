import React, { useState, useRef, useEffect } from 'react';
import Quagga from '@ericblade/quagga2';
import Scanner from './Scanner';


import PropTypes from 'prop-types';
import axios from 'axios';
import { getFoodNutrientAmount } from 'helpers/recipe_helpers';
import { Capacitor } from '@capacitor/core';
import { Button, Select, Tooltip, MenuItem, Dialog } from '@material-ui/core';
import _ from 'lodash';
import MealPortioningSlider from '../meal_portioning_slider/meal_portioning_slider';
import SwipeableTemporaryDrawer from '../calendar/SwipeableTemporaryDrawer';
import { config } from 'Constants';
import { CartContext } from 'contexts/cart_context';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { Alert } from '@material-ui/lab';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { AuthContext } from 'contexts/auth_context';
import {
  BarcodeScanner as BarcodeScannerNew,
} from '@capacitor-mlkit/barcode-scanning';

const Result = ({ result }) => (
  <li>
    {result.codeResult.code} [{result.codeResult.format}]
  </li>
);

Result.propTypes = {
  result: PropTypes.object
};

const BarcodeScanner = (props) => {
  const [scanning, setScanning] = useState(true); // toggleable state for "should render scanner"
  const [cameras, setCameras] = useState([]); // array of available cameras, as returned by Quagga.CameraAccess.enumerateVideoDevices()
  const [cameraId, setCameraId] = useState(null); // id of the active camera device
  const [cameraError, setCameraError] = useState(null); // error message from failing to access the camera
  const [food, setFood] = useState(null); // toggleable state for "should torch be on"
  const scannerRef = useRef(null); // reference to the scanner element in the DOM
  const [isActionSheetOpen, setIsActionSheetOpen] = React.useState(false)
  const addFavoriteFood = React.useContext(CartContext).addFavoriteFood;
  const removeFoodFromFavorites = React.useContext(CartContext).removeFoodFromFavorites;
  const addMealToColumn = React.useContext(CartContext).addOrMoveMealToColumn;
  const favoriteFoodList = React.useContext(CartContext).favoriteFoodList
  const [errorState, setErrorState] = React.useState(0)
  const [isNotWorkingScan, setIsNotWorkingScan] = React.useState(false)
  const { currentUser } = React.useContext(AuthContext)

  // at start, we need to get a list of the available cameras.  We can do that with Quagga.CameraAccess.enumerateVideoDevices.
  // HOWEVER, Android will not allow enumeration to occur unless the user has granted camera permissions to the app/page.
  // AS WELL, Android will not ask for permission until you actually try to USE the camera, just enumerating the devices is not enough to trigger the permission prompt.
  // THEREFORE, if we're going to be running in Android, we need to first call Quagga.CameraAccess.request() to trigger the permission prompt.
  // AND THEN, we need to call Quagga.CameraAccess.release() to release the camera so that it can be used by the scanner.
  // AND FINALLY, we can call Quagga.CameraAccess.enumerateVideoDevices() to get the list of cameras.

  // Normally, I would place this in an application level "initialization" event, but for this demo, I'm just going to put it in a useEffect() hook in the App component.

  useEffect(() => {
    const enableCamera = async () => {
      await Quagga.CameraAccess.request(null, {});
    };
    const disableCamera = async () => {
      await Quagga.CameraAccess.release();
    };

    const enumerateCameras = async () => {
      const cameras = await Quagga.CameraAccess.enumerateVideoDevices();
      return cameras;
    };
    enableCamera()
      .then(disableCamera)
      .then(enumerateCameras)
      .then((cameras) => setCameras(cameras))
      .then(() => Quagga.CameraAccess.disableTorch()) // disable torch at start, in case it was enabled before and we hot-reloaded
      .catch((err) => {
        setCameraError(err)
        FirebaseAnalytics.logEvent({
          name: "scanned_barcode_camera_error",
          params: { browserDetails: navigator ? navigator.userAgent : "", currentUser: currentUser.uid }
        })

      });
    return () => disableCamera();
  }, [currentUser.uid]);



  function ensureThirteenDigits(num) {
    // Convert the number to a string
    let numStr = num.toString();

    // Calculate the number of zeros needed
    const zerosNeeded = 13 - numStr.length;

    // Add the zeros to the left of the number
    numStr = '0'.repeat(zerosNeeded > 0 ? zerosNeeded : 0) + numStr;

    // Ensure the resulting string is 13 characters long
    return numStr.length > 13 ? numStr.slice(-13) : numStr;
  }

  function handleDetection(result) {
    let adjustedResult = ensureThirteenDigits(result)
    setScanning(false)
    axios.get('/food-search/foodFromBarCode/' + adjustedResult).then(async resp => {
      if (resp.data.food_id) {
        FirebaseAnalytics.logEvent({
          name: "scanned_barcode",
          params: { successfulScan: "true", foodScanned: resp.data.food_name, currentUser: currentUser.uid }
        })

        setFood({ ...resp.data, portionChosen: 1, servingChosen: 0 })
      }
      else {
      }
    }
    ).catch(err => {
      FirebaseAnalytics.logEvent({
        name: "scanned_barcode",
        params: { successfulScan: "false", barcode: adjustedResult, currentUser: currentUser.uid }
      })
      setErrorState(1)
    })

  }


  function handleUnitSelectChange(event) {
    let foodCopy = _.cloneDeep(food)
    foodCopy.servingChosen = event.target.value
    setFood(foodCopy)
  }

  function setPortion(portion) {
    let foodCopy = _.cloneDeep(food)
    foodCopy.portionChosen = portion
    setFood(foodCopy)
  }

  function handleActionSheetChange(value) {
    let foodCopy = _.cloneDeep(food)
    foodCopy.servingChosen = value
    setFood(foodCopy)

  }

  const addFoodToCalendar = () => {

    let mealObject = _.cloneDeep(config.emptyRecipe)
    mealObject = props.calendarCategory ? { ...mealObject, calendarCategory: props.calendarCategory } : { ...mealObject, calendarCategory: "breakfast" }

    let foodChosen = food
    mealObject = { ...mealObject, portion: foodChosen.portionChosen, name: foodChosen.food_name, description: foodChosen.brand_name ? foodChosen.brand_name + " - a serving is " + foodChosen.servings.serving[foodChosen.servingChosen].serving_description : "a portion is " + foodChosen.servings.serving[foodChosen.servingChosen].serving_description, areNutrientsManual: true, picture: foodChosen.picture }

    for (let mealNutrient of mealObject.nutrition.nutrients) {
      if (mealNutrient.name === "Calories") {
        mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, "Calories") * foodChosen.portionChosen)
      }
      if (mealNutrient.name === "Protein") {
        mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, "Protein") * foodChosen.portionChosen)
      }
      if (mealNutrient.name === "Carbohydrates") {
        mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, "Carbohydrates") * foodChosen.portionChosen)
      }
      if (mealNutrient.name === "Fat") {
        mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, "Fat") * foodChosen.portionChosen)
      }
      if (mealNutrient.name === "Cholesterol") {
        mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, "Cholesterol") * foodChosen.portionChosen)
      }
      if (mealNutrient.name === "Sugar") {
        mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, "Sugar") * foodChosen.portionChosen)
      }
      if (mealNutrient.name === "Sodium") {
        mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, "Sodium") * foodChosen.portionChosen)
      }
      if (mealNutrient.name === "Calcium") {
        mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, "Calcium") * foodChosen.portionChosen)
      }
      if (mealNutrient.name === "Saturated Fat") {
        mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, "Saturated Fat") * foodChosen.portionChosen)
      }
      if (mealNutrient.name === "Fiber") {
        mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, "Fiber") * foodChosen.portionChosen)
      }
    }
    addMealToColumn(props.calendarDate, mealObject, mealObject['calendarCategory'])

    props.handleExitClick()
    props.close()


  }

  const removeFromFavorites = (e) => {
    removeFoodFromFavorites(food.food_id)
  }
  const addToFavorites = (e) => {
    addFavoriteFood(food)
  }



  let servingOptions = []
  let foodInFavorites = false
  if (food) {

    for (let servingIndex in food.servings.serving) {

      servingOptions.push(
        <MenuItem index={servingIndex} key={food.servings.serving[servingIndex].serving_description} value={servingIndex}>
          {food.servings.serving[servingIndex].serving_description}
        </MenuItem>
      )
    }
    for (let favoriteFood of favoriteFoodList) {
      if (favoriteFood.food_id === food.food_id) {
        foodInFavorites = true
        break
      }
    }

  }

  async function closeScanner() {
    setScanning(false)

    setTimeout(() => { props.setBarcodeScanDialogOpen(false) }, 100)
  }
  const checkPermissions = async () => {
    try {
      const { camera } = await BarcodeScannerNew.checkPermissions();
      return camera;
    } catch (error) {
      console.error('Error checking permissions:', error);
      await requestPermissions();
    }
  };

  const requestPermissions = async () => {
    const { camera } = await BarcodeScannerNew.requestPermissions();
    return camera;
  };

  useEffect(() => {
    async function handleAndroid() {
      if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'web') return
      const data = await BarcodeScannerNew.isGoogleBarcodeScannerModuleAvailable().catch(async err => {
        setIsNotWorkingScan(true)
        FirebaseAnalytics.logEvent({
          name: "scanned_barcode_camera_android_defaulting",
          params: { currentUser: currentUser.uid }
        })
        return
      });
      if (!data.available) {
        await BarcodeScannerNew.installGoogleBarcodeScannerModule();
      }
    }
    handleAndroid();
  }
    , [currentUser.uid])

  const startScanning = async () => {
    try {
      const hasCameraPermission = await checkPermissions(); // Check or request permissions
      if (!hasCameraPermission) {
        requestPermissions();
        return;
      }

      document.querySelector('body')?.classList.add('barcode-scanner-active');

      const { barcodes } = await BarcodeScannerNew.scan();
      document
        .querySelector('body')
        ?.classList.remove('barcode-scanner-active');
      if (barcodes.length) {
        handleDetection(barcodes[0].displayValue)
      }
    } catch (error) {
      document
        .querySelector('body')
        ?.classList.remove('barcode-scanner-active');
    } finally {
      setScanning(false);
    }
  };

  return (
    <Dialog className="calendar-add-meal-popover" open={props.barcodeScanDialogOpen} onClose={closeScanner}>

      <div className="barcode-scanner" style={{ minWidth: '300px', padding: '10px' }}>

        {errorState === 1 && <Alert severity="warning" onClose={() => { setErrorState(0) }}>Item not found!</Alert>}

        {cameraError ? <Alert severity="warning" onClose={() => { setCameraError(null) }}>Error initializing camera... Check app/browser permissions </Alert> : null}

        {(Capacitor.getPlatform() === "web" || isNotWorkingScan) && (cameras.length === 0 && !scanning ? <div style={{ marginTop: '10px' }}>Loading Camera, may be prompting for permissions beforehand</div> :
          <>
            <select style={{ marginRight: '15px' }} onChange={(event) => setCameraId(event.target.value)}>
              {cameras.map((camera) => (
                <option key={camera.deviceId} value={camera.deviceId}>
                  {camera.label || camera.deviceId}
                </option>
              ))}
            </select>
            <button className="calendar-add-menu-meal-button" onClick={() => setScanning(!scanning)}>{scanning ? 'Stop' : 'Start'}</button>
          </>
        )}
        {
          (scanning && (Capacitor.getPlatform() === "web" || isNotWorkingScan)) &&
          <div ref={scannerRef} style={{ position: 'relative' }}>
            <canvas className="drawingBuffer" style={{
              position: 'absolute',
              top: '0px',
              // left: '0px',
              // height: '100%',
              // width: '100%',
              maxWidth: '100%',
            }} width="280px" height="280px" />
            <Scanner scannerRef={scannerRef} cameraId={cameraId} onDetected={(result) => handleDetection(result)} />
          </div>
        }
        {(Capacitor.getPlatform() !== "web" && !isNotWorkingScan) && <button className="calendar-add-menu-meal-button" onClick={() => startScanning()}>{'Start Scan'}</button>}
        {food &&
          <div style={{ borderBottom: '1px solid #e1e1e1' }}>
            <div key={`calendar-add-menu-meal-row-${food.food_id}`} className="calendar-add-menu-meal-row" style={{ marginBottom: '10px', maxWidth: '100%' }}>
              <img className="food-search-meal-picture" src={food.picture} alt={food.food_name} />

              <div className="calendar-add-menu-meal-column" style={{ overflow: 'hidden' }} >
                <div style={{ display: 'flex', maxWidth: '100%' }}>
                  <div className="calendar-add-menu-meal-name" style={{ margin: '7px 10px 7px 0px' }}>{food.food_name}
                    {food.brand_name && <span className="branded-product-search-brand">{food.brand_name}</span>}
                  </div>
                </div>
                <div className="food-search-box-inner-row">

                  <div style={{ maxWidth: '68%' }}>

                    {getFoodNutrientAmount(food, "Calories") > 0 && <div className="calendar-add-custom-meal-caloric-info">
                      <span className="caloric-chip caloric-chip-calories">{Math.round(getFoodNutrientAmount(food, "Calories") * food.portionChosen)} Cals</span>
                      <span className="caloric-chip caloric-chip-protein">{Math.round(getFoodNutrientAmount(food, "Protein") * food.portionChosen)}g</span>
                      <span className="caloric-chip caloric-chip-carbs">{Math.round(getFoodNutrientAmount(food, "Carbohydrates") * food.portionChosen)}g</span>
                      <span className="caloric-chip caloric-chip-fats">{Math.round(getFoodNutrientAmount(food, "Fat") * food.portionChosen)}g</span>

                    </div>}

                    <div>
                      {servingOptions && <div className="" style={{ marginBottom: '-2px', marginTop: '5px' }} >
                        {Capacitor.getPlatform() === 'web' ?
                          <Select
                            label="unit"
                            value={food.servingChosen}
                            name="unit"
                            onChange={handleUnitSelectChange}
                            className="modify-recipe-unit-select"
                            style={{ maxWidth: '95%' }}
                            disableUnderline
                          >
                            {servingOptions}
                          </Select>
                          :
                          <div
                            onClick={() => {
                              setIsActionSheetOpen(true)
                            }}
                            className='modify-recipe-unit-text'
                          >
                            {servingOptions.find((obj) => obj.props.value === food.servingChosen)}
                          </div>
                        }

                      </div>
                      }
                      <div style={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
                        Servings: <span style={{ marginLeft: '10px', marginBottom: '-6px', width: '400px' }}><MealPortioningSlider portion={food.portionChosen} setPortion={setPortion} shrink={true} smallPopup={true} /></span>
                      </div>
                    </div>


                  </div>
                  {<div className="calendar-add-menu-meal-column" style={{ maxWidth: '100px' }}>
                    <button className="calendar-add-menu-meal-button" variant="contained" color="primary" onClick={addFoodToCalendar}>Add</button>
                    {foodInFavorites ? <Tooltip title="Remove from favorites">
                      <Button style={{ color: '#ff7c93' }} onClick={removeFromFavorites}><FavoriteIcon /></Button>
                    </Tooltip> :
                      <Tooltip title="Add to favorites">
                        <Button style={{ color: 'grey' }} onClick={addToFavorites}><FavoriteBorderIcon /></Button>
                      </Tooltip>
                    }
                  </div>}
                </div>

              </div></div>
            {servingOptions && <SwipeableTemporaryDrawer
              actionSheetOpen={isActionSheetOpen}
              setActionSheetOpen={setIsActionSheetOpen}
              list={servingOptions}
              onChange={(ind) => {
                console.log('index: ', ind);
                handleActionSheetChange(servingOptions[ind].props.value)
              }}
            />}

          </div>}


      </div>
    </Dialog>
  );
};

export default BarcodeScanner