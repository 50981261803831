import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signInWithCustomToken, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, signInWithCredential, signOut, sendEmailVerification, updateProfile, initializeAuth, indexedDBLocalPersistence } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { config } from 'Constants';
import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Capacitor } from "@capacitor/core";
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import firebase from "firebase/compat/app"
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

export const AuthContext = React.createContext();

const firebaseConfig = {
  apiKey: config.firebaseApiKey,
  authDomain: config.firebaseAuthDomain,
  databaseURL: config.firebaseDatabaseURL,
  projectId: config.firebaseProjectId,
  storageBucket: config.firebaseStorageBucket,
  messagingSenderId: config.firebaseMessagingSenderId,
  appId: config.firebaseAppId
};



const app = initializeApp(firebaseConfig);

let auth;
if (Capacitor.isNativePlatform()) {
  auth = initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
  });
} else {
  auth = getAuth();
}

// const auth = getAuth(app);
const db = getFirestore(app);

export function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [isNewlyWrittenUser, setIsNewlyWrittenUser] = useState(false);

  async function login(email, password) {
    let result = await signInWithEmailAndPassword(auth, email, password);
    if (!result.user.emailVerified) {
      await sendEmailVerification(result.user);
      await logout();
      return 'need to verify email to log in';
    }
    return '';
  }

  function loginWithToken() {
    return signInWithCustomToken(auth, "");
  }

  async function writeUserToDB(user) {
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      console.log("Document data:", userDoc.data());
    } else {
      
      console.log("Did not find the user in firestore, creating a new one");
      const newUserObject = _.cloneDeep({ ...config.emptyUserObject, signUpDate: new Date().toString() });
      const newRecipeBookId = uuidv4();
      newUserObject.recipeBooks.push(newRecipeBookId);
      await setDoc(userDocRef, newUserObject);
      let displayName = user?.displayName ? user.displayName.split(" ")[0]+"'s" : 'Your';
      const newRecipeBookObject = { ...config.emptyRecipeBook, name: `${displayName} Recipe Book`, id: newRecipeBookId, owners: [user.uid] };
      console.log("creating recipe book");
      await setDoc(doc(db, 'recipe_books', newRecipeBookId), newRecipeBookObject);
      FirebaseAnalytics.logEvent({
        name: "signed_up",
        params: { value: '0.5', currency: 'USD', currentUser: user.uid, windowWidth: window.innerWidth }
      });
      if (window.fbq) {
        window.fbq('track', 'CompleteRegistration', {
          email: user.email
        });
      }

      setTimeout(() => {
        setIsNewlyWrittenUser(true);
      }, 500);
    }
  }

  async function signUp(email, password, name) {
    try {
      let result = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(result.user);
      await updateProfile(result.user, { displayName: name });
      await writeUserToDB(result.user);
     
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function loginSocial() {
    try {
        if (Capacitor.getPlatform() === 'web') {
            const provider = new GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            const res = await signInWithPopup(auth, provider);
            await writeUserToDB(res.user);
            return res.user;
        } else {
            const googleUser = await GoogleAuth.signIn();
            console.log('Signed in with Google:', googleUser);
            const credential = GoogleAuthProvider.credential(googleUser.authentication.idToken);
            console.log('credential: ', credential);
            const data = await signInWithCredential(auth, credential);
            console.log('user: ', data.user);
            await writeUserToDB(data.user);
            return data.user;
        }
    } catch (error) {
        console.error('Error signing in with Google:', error);
        throw error;  // Propagate error for further handling
    }
}


  async function appleLogin() {
    let options = {
      scopes: 'email name',
    };
    try {
      return SignInWithApple.authorize(options)
        .then(async (res) => {
          const provider = new firebase.auth.OAuthProvider('apple.com');
          const authCredential = provider.credential({
            idToken: res.response.identityToken,
          });
          return firebase
            .auth()
            .signInWithCredential(authCredential)
            .then((result) => {
              writeUserToDB(result.user)
              return result.user
            })
            .catch((error) => {
              console.log('sign-in with apple error: ', error);
            });
        })
        .catch(error => {
          console.log('authorize error: ', error);
        });
    } catch (error) {
      console.error('Error signing in with Apple:', error);
    }
  }

  async function logout(resetUserObject) {
    if (resetUserObject) resetUserObject()
    await signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    loginWithToken,
    loginSocial,
    appleLogin,
    logout,
    signUp,
    isNewlyWrittenUser,
    setIsNewlyWrittenUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
