import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts/auth_context'; // Adjust the import path as needed
import { Route, Redirect } from 'react-router-dom';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

export default function PrivateAdminRoute({ component: Component, ...rest }) {
  const { currentUser } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state to handle async fetch

  useEffect(() => {
    const checkAdmin = async () => {
      if (currentUser) {
        try {
          const db = getFirestore(); // Initialize Firestore
          const adminDocRef = doc(db, 'admins', currentUser.uid); // Reference to the admin document
          const adminDoc = await getDoc(adminDocRef); // Fetch the document
          


          setIsAdmin(adminDoc.exists()); // Check if the document exists
        } catch (error) {
        }
      } else {
        console.log('No current user found'); // Log if no user is found
      }
      setLoading(false); // Set loading to false after fetch is complete
    };

    checkAdmin();
  }, [currentUser]);

  if (loading) {
    // Show loading or spinner while checking admin status
    return <div>Loading...</div>;
  }

  // Additional debugging log for currentUser and isAdmin

  return (
    <Route
      {...rest}
      render={props =>
        currentUser && isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}
