import './extract_recipe_from_url.css'
import React from "react";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { config } from 'Constants'
import { RecipeBooksContext } from 'contexts/recipe_books_context';
import { cleanUpNutrients, recalculateCaloriesForRecipe } from 'helpers/recipe_helpers';
import { Alert } from '@material-ui/lab';
import LoopIcon from '@material-ui/icons/Loop';
import _ from "lodash";
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';


const ExtractRecipeFromUrl = (props) => {
    const copyRecipeToOwnRecipeBook = React.useContext(RecipeBooksContext).copyRecipeToOwnRecipeBook
    const [loading, setLoading] = React.useState(false)
    const [errorState, setErrorState] = React.useState(0)
    let [url, setUrl] = React.useState('');



    const handleChange = (e) => {
        setUrl(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorState(0)
        setLoading(true)
    

        axios.get('ingredient/extractRecipeFromURL', { params: { url: url } }).then(response => {
            
            let description = 'Imported from ' + response.data.sourceName
            let newRecipe = _.cloneDeep(config.emptyRecipe)

            if (response.data.servings > 0) {
                description = description + " - Serves " + response.data.servings
            }
            let extractedRecipe = { ...newRecipe, portion: response.data.servings > 8 ? 8 : response.data.servings, description: description, name: response.data.title, readyInMinutes:response.data.readyInMinutes>0?response.data.readyInMinutes:0, analyzedInstructions: response.data.analyzedInstructions[0] ? response.data.analyzedInstructions[0] : {steps:[]} }

            if (response.data.image) {
                extractedRecipe = { ...extractedRecipe, picture: response.data.image }
            }
            let ingredients = []

            let foundAllIngredients= true
            for (let ingredientIndex in response.data.extendedIngredients) {
                let extendedIngredient = response.data.extendedIngredients[ingredientIndex]
                if(extendedIngredient.id===-1){
                    foundAllIngredients=false
                    continue
                }
                let ingredientNutrition = response.data.nutrition.ingredients[ingredientIndex]

                ingredientNutrition.weightPerServing = { amount: extendedIngredient.weightPerServing, unit: 'g' }

                ingredientNutrition.nutrients = cleanUpNutrients(ingredientNutrition.nutrients)
                
                for (let ingredientNutrient of ingredientNutrition.nutrients){
                    ingredientNutrient.amount = ingredientNutrient.amount * response.data.servings
                }

                let possibleUnits = ["g"]

                if (extendedIngredient.unit !== "g") {
                    possibleUnits.push(extendedIngredient.unit)
                }

                ingredients.push({ ...extendedIngredient, amountString: extendedIngredient.amount.toString(), selectedUnit: extendedIngredient.unit, possibleUnits: possibleUnits, nutrition: ingredientNutrition })
            }
            FirebaseAnalytics.logEvent({
                name: "extracted_recipe",
                params: { extractUrl: url }
            })
            extractedRecipe = recalculateCaloriesForRecipe({ ...extractedRecipe, ingredients: ingredients })
            copyRecipeToOwnRecipeBook(extractedRecipe)
            setLoading(false)
            if(foundAllIngredients){
                props.handleExitClick()
            }
            else{
                setErrorState(2)
                setUrl('')
            }
        }).catch(error => {
            setErrorState(1)
            setLoading(false)

        })




    }





    return (

        <>

            <div className="extract-recipe-dialog-form">
                <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>



                    <div className="extract-recipe-dialog-form-line-item" >
                        <TextField className="extract-recipe-dialog-form-input" variant="outlined" disabled={loading} multiline required={true} label="website link" name='URL' InputLabelProps={{ shrink: true }} value={url} onChange={handleChange} placeholder="e.g. www.food.com/ easy-enchiladas"></TextField>

                    </div>


                    <div className="extract-recipe-dialog-form-submit-button"><button type="submit" disabled={loading} className="order-ingredients-button-for-browser" style={{ padding: '10px 20px', fontSize: '15px', minWidth:'150px', fontFamily: 'Montserrat-SemiBold' }} value="Submit" >Import Recipe<>{loading && <LoopIcon style={{ fontSize: '22px', margin: '-5px 0px -5px 5px', animation: 'spin 2s linear infinite' }} />}</></button>
                    </div>
       

                </form>
                {errorState === 1 && <div style={{ marginTop: "10px" }}><Alert severity="error"><>Error importing from URL provided</></Alert></div>}
                {errorState === 2 && <div style={{ marginTop: "10px" }}><Alert severity="warning"><>successfully imported recipe but some ingredients were not found. Verify and add them manually</></Alert></div>}

            </div>
         
        </>
    )

}

export default ExtractRecipeFromUrl;