import './meal_portioning_slider.css'
import React from 'react';
import { styled } from '@material-ui/styles';

import { Slider } from '@material-ui/core';


export const PrettoSmallSlider = styled(Slider)({
    color: '#2D7933',
    height: 8,
    pointerEvents: 'none !important',
    '& .MuiSlider-track': {
        border: 'none',
        height: 8,
        borderRadius: '5px'
    },
    '& .MuiSlider-rail': {
        border: 'none',
        height: 8,
        borderRadius: '5px',
        opacity: 0.3
    },
    '& .MuiSlider-thumb': {
        height: 14,
        width: 14,
        marginTop: -3.5,
        backgroundColor: '#fff',
        pointerEvents: 'all !important',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.5,
        fontSize: 12,
        background: 'unset',
        padding: '5px 0px 0px 12px',
        width: 45,
        height: 40,
        borderRadius: '30%',
        backgroundColor: '#2D7933',
        transform: 'translatey(0px) scale(0)',
        left: '-22px !important',
    },
    '& .MuiSlider-valueLabel span': {
        borderRadius: '50% 50% 50% 50%',
    },
    '& .MuiSlider-valueLabel span span': {
        color: 'white'
    },
    '& .PrivateValueLabel-thumb-2.PrivateValueLabel-open-3 .PrivateValueLabel-offset-4': {
        transform: 'translatey(-20px) scale(1)'
    },
});


const PrettoSmallSliderSmallPopup = styled(Slider)({
    color: '#2D7933',
    height: 8,
    pointerEvents: 'none !important',
    '& .MuiSlider-track': {
        border: 'none',
        height: 8,
        borderRadius: '5px'
    },
    '& .MuiSlider-rail': {
        border: 'none',
        height: 8,
        borderRadius: '5px'
    },
    '& .MuiSlider-thumb': {
        height: 16,
        width: 16,
        backgroundColor: '#fff',
        pointerEvents: 'all !important',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.1,
        fontSize: 12,
        background: 'unset',
        padding: '0px 0px 0px 0px',
        width: 25,
        height: 25,
        borderRadius: '50%',
        backgroundColor: '#2D7933',
        transform: 'translatey(0px) scale(0)',
        left: '-10px !important',


        // lineHeight: 1.2,
        // fontSize: 12,
        // background: 'unset',
        // padding: 0,
        // width: 32,
        // height: 32,
        // borderRadius: '30%',
        // transform: 'translatey(60px) scale(1) !important',
        // left: '-5px !important',


    },
    '& .MuiSlider-valueLabel span': {
        borderRadius: '50% 50% 50% 50%',
    },
    '& .MuiSlider-valueLabel span span': {
        color: 'white'
    },
    '& .PrivateValueLabel-thumb-2.PrivateValueLabel-open-3 .PrivateValueLabel-offset-4': {
        transform: 'translatey(-7px) scale(1)'
    },
});



const PrettoSlider = styled(Slider)({
    color: '#2D7933',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
        height: 8,
        borderRadius: '5px'
    },
    '& .MuiSlider-rail': {
        border: 'none',
        height: 8,
        borderRadius: '5px'
    },
    '& .MuiSlider-thumb': {
        height: 18,
        width: 18,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '30%',
        backgroundColor: '#2D7933',
        transform: 'translatey(52px) scale(1) !important',
        left: '-8px !important',
    },
    '& .MuiSlider-valueLabel span': {
        borderRadius: '50% 50% 50% 50%',
    },
    '& .MuiSlider-valueLabel span span': {
        color: 'white'
    },
    '& .PrivateValueLabel-thumb-2.PrivateValueLabel-open-3 .PrivateValueLabel-offset-4': {
        transform: 'translatey(60px) scale(1)'
    },
});


const PrettoSliderWithSmallDisplay = styled(Slider)({
    color: '#2D7933',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
        height: 8,
        borderRadius: '5px'
    },
    '& .MuiSlider-rail': {
        border: 'none',
        height: 8,
        borderRadius: '5px'
    },
    '& .MuiSlider-thumb': {
        height: 16,
        width: 16,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 10,
        background: 'unset',
        padding: 0,
        width: 24,
        height: 22,
        
        borderRadius: '50%',
        backgroundColor: 'none',
        transform: 'translatey(49px) scale(1) !important',
        left: '-5px !important',
    },
    '& .MuiSlider-valueLabel span': {
        borderRadius: '80% 80% 80% 80%',
        width:'23px',
        height:'23px',
        marginTop:'0px',
        marginBottom:'0px',
        display:'flex', 
        alignItems:'center',
        justifyContent:'center'
    },
    '& .MuiSlider-valueLabel span span': {
        color: 'white'
    },
    '& .PrivateValueLabel-thumb-2.PrivateValueLabel-open-3 .PrivateValueLabel-offset-4': {
        transform: 'translatey(60px) scale(1)'
    },

});

const marks = [
    {
        value: 0,
        label: '1/16',
        portion: 0.0625
    },
    {
        value: 1,
        label: '1/8',
        portion: 0.125
    },
    {
        value: 2,
        label: '1/6',
        portion: 0.1667,

    },
    {
        value: 3,
        label: '1/4',
        portion: 0.25,

    },
    {
        value: 4,
        label: '1/3',
        portion: 0.33,
    },
    {
        value: 5,
        label: '1/2',
        portion: 0.5

    },
    {
        value: 6,
        label: '2/3',
        portion: 0.66

    },
    {
        value: 7,
        label: '3/4',
        portion: 0.75

    },
    {
        value: 8,
        label: '7/8',
        portion: 0.875

    },
    {
        value: 9,
        label: '1',
        portion: 1

    },
    {
        value: 10,
        label: '1 1/4',
        portion: 1.25

    },
    {
        value: 11,
        label: '1 1/2',
        portion: 1.5

    },
    {
        value: 12,
        label: '1 3/4',
        portion: 1.75

    },
    {
        value: 13,
        label: '2',
        portion: 2

    },
    {
        value: 14,
        label: '2 1/2',
        portion: 2.5

    },
    {
        value: 15,
        label: '3',
        portion: 3

    },
    {
        value: 16,
        label: '4',
        portion: 4

    },
    {
        value: 17,
        label: '5',
        portion: 5

    },
    {
        value: 18,
        label: '6',
        portion: 6

    },
    {
        value: 19,
        label: '7',
        portion: 7

    },
    {
        value: 20,
        label: '8',
        portion: 8

    },
];

const MealPortioningSlider = (props) => {
    // const [cartObject, setCartObject] = React.useContext(CartContext);
    const [tempPortion, setTempPortion] = React.useState(props.portion)
    let portion = props.portion
    let setPortion = props.setPortion

    React.useEffect(() => {
        const updatePortion = setTimeout(() => {
            
            if (props.cooking_recipe_id) {
                setPortion(tempPortion, props.cooking_recipe_id)
                return
            }

            if (props.index) {
                setPortion(tempPortion, props.index)
            }
            else {
                setPortion(tempPortion)
            }
        }, 500);
        return () => clearTimeout(updatePortion);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempPortion])


    React.useEffect(() => {
        const updateTempPortion = setTimeout(() => {
            if (tempPortion !== portion) {
                setTempPortion(portion)
            }
        }, 0);
        return () => clearTimeout(updateTempPortion);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portion])


    function calculateValue(value) {
        if (marks[value]) {
            return marks[value].label;
        }
    }

    function calculateValueSmall(value) {
        if (marks[value]) {
            return "Servings " + marks[value].label;
        }
    }

    function handleSliderChange(event, value) {
        if (typeof value === 'number') {
            setTempPortion(marks[value].portion)
        }
        if (props.freezeParent) {
            props.freezeParent()
        }

    }

    function findPortionSliderValue(portionParam) {
        for (let index in marks) {
            if (marks[index].portion === portionParam) {
                return index
            }
        }
    }

    return (
        <>
            {props.shrink ?

                props.smallPopup ?
                
                props.showLabel?
                <div className="generate-settings-content-medium">

                        <PrettoSliderWithSmallDisplay
                            value={findPortionSliderValue(tempPortion)}
                            aria-label="Small"
                            onChange={handleSliderChange}
                            scale={calculateValue}
                            step={1}
                            min={0}
                            max={marks.length - 1}
                            color='secondary'
                            valueLabelDisplay="auto"
                        />
                    </div> :
                
                    <div className="generate-settings-content-medium">

                        <PrettoSmallSliderSmallPopup
                            value={findPortionSliderValue(tempPortion)}
                            aria-label="Small"
                            onChange={handleSliderChange}
                            scale={calculateValue}
                            step={1}
                            min={0}
                            max={marks.length - 1}
                            color='secondary'
                            valueLabelDisplay="auto"
                        />
                    </div> :

                    <div className="generate-settings-content-small">

                        <PrettoSmallSlider
                            value={findPortionSliderValue(tempPortion)}
                            aria-label="Small"
                            onChange={handleSliderChange}
                            scale={calculateValueSmall}
                            step={1}
                            min={0}
                            max={marks.length - 1}
                            color='secondary'
                            valueLabelDisplay="auto"
                        />
                    </div> :
                <div style={{ marginTop: '5px', width:'300px' }}>
                    <PrettoSlider
                        value={findPortionSliderValue(tempPortion)}
                        aria-label="Small"
                        onChange={handleSliderChange}
                        scale={calculateValue}
                        step={1}
                        min={0}
                        max={marks.length - 1}
                        color='secondary'
                        valueLabelDisplay="auto"


                    />

                </div>

            }
        </>
    )
}
export default MealPortioningSlider;
