import React from 'react';
import { Dialog } from '@material-ui/core'
//  import FoodSearchRow from './branded_product_search_row';
import _ from 'lodash';
import { CartContext } from 'contexts/cart_context';

import MealPortioningSlider from '../meal_portioning_slider/meal_portioning_slider';
import { decimalToFraction, getMealNutrientAmount, recalculateCaloriesForRecipe } from 'helpers/recipe_helpers';
import DeleteIcon from '@material-ui/icons/Delete';
import MealDetailsCard from '../meal_details_card/meal_details_card';




const RecipeCookingPlannerRow = (props) => {
    let removeRecipeFromCookingList = React.useContext(CartContext).removeRecipeFromCookingList
    let updateCookingRecipe = React.useContext(CartContext).updateCookingRecipe
    const [recipeBeingViewed, setRecipeBeingViewed] = React.useState(false)

    const [portion, setPortion] = React.useState(props.recipe.portion ? props.recipe.portion : 1)

    let recipe = props.recipe


    const removeFromRecipeList = (e) => {
        removeRecipeFromCookingList(recipe.cooking_recipe_id)
    }

    const handleRecipeViewClick = () => {
        setRecipeBeingViewed(true)

    }

    const handleRecipeViewExitClick = () => {
        setRecipeBeingViewed(false)

    }

    React.useEffect(() => {
        const updatePortion = setTimeout(() => {
            //first portion ingredients, then set the new cart meal
            let recipeClone = _.cloneDeep(recipe)

            //with ingredients
            if (recipe.portion) {
                for (let ingredient of recipeClone.ingredients) {
                    ingredient.amountString = decimalToFraction(ingredient.amount * portion / recipe.portion)
                    ingredient.amount = ingredient.amount * portion / recipe.portion
                }
            }
            else {
                for (let ingredient of recipeClone.ingredients) {
                    ingredient.amountString = decimalToFraction(ingredient.amount * portion)
                    ingredient.amount = ingredient.amount * portion
                }
            }
            recipeClone = recalculateCaloriesForRecipe(recipeClone)
            updateCookingRecipe({ ...recipeClone, portion: portion })

        }, 100);
        return () => clearTimeout(updatePortion);
    }, [portion])



    React.useEffect(() => {
        if (portion !== recipe.portion) {
            setPortion(recipe.portion)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipe])

    return (<>
        <div style={{ borderBottom: '1px solid #e1e1e1' }}>
            <div key={`calendar-add-menu-meal-row-${recipe.cooking_recipe_id}`} className="calendar-add-menu-meal-row" style={{ marginBottom: '10px' }}>

                <img className="calendar-add-menu-meal-picture" value={recipe.cooking_recipe_id} onClick={handleRecipeViewClick} style={{ marginRight: '15px', marginLeft: '8px' }} src={recipe.picture} alt={recipe.name} />

                <div className="calendar-add-menu-meal-column"  >
                    <div style={{ display: 'flex' }}>
                        <div className="calendar-add-menu-meal-name" style={{ margin: '7px 10px 7px 0px' }}>{recipe.name}
                        </div>
                    </div>
                    <div className="food-search-box-inner-row">

                        <div>

                            {getMealNutrientAmount(recipe, "Calories") > 0 && <div className="calendar-add-menu-meal-caloric-info">
                                <span className="caloric-chip caloric-chip-calories">{getMealNutrientAmount(recipe, "Calories")} Cals</span>
                                <span className="caloric-chip caloric-chip-protein">{getMealNutrientAmount(recipe, "Protein")}g</span>
                                <span className="caloric-chip caloric-chip-carbs">{getMealNutrientAmount(recipe, "Carbohydrates")}g</span>
                                <span className="caloric-chip caloric-chip-fats">{getMealNutrientAmount(recipe, "Fat")}g</span>
                            </div>}


                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '13px', marginBottom:'8px' }}>
                                Servings: <span style={{ marginLeft: '10px', marginBottom: '-4px' }}>
                                    <MealPortioningSlider portion={portion} setPortion={setPortion} shrink={true} smallPopup={true} showLabel={true} /></span>
                            </div>
                        </div>


                    </div>


                </div>
                <div style={{ marginLeft: 'auto' }}>
                    <div style={{ margin: '15px' }} onClick={removeFromRecipeList}>
                        <DeleteIcon style={{ color: 'grey' }} />
                    </div>
                </div>

            </div>

        </div>
        <Dialog open={recipeBeingViewed} maxWidth={'xl'} >
            <MealDetailsCard addToCalendarEnabled={false} disableCopy={true} portion={portion} setPortion={setPortion} handleExitClick={handleRecipeViewExitClick} meal={recipe} />
        </Dialog>
    </>


    )
}
export default RecipeCookingPlannerRow