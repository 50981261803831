import React from 'react'
import IngredientsSearchBox from 'components/shared/ingredients_search_box/ingredients_search_box';
import './modify_recipe.css'
import {  recalculateCaloriesForRecipe } from 'helpers/recipe_helpers';
import ModifyRecipeIngredientRow from './modify_recipe_ingredient_row';
import _ from 'lodash';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';


export default function ModifyRecipeIngredients(props) {
    const recipe = props.recipe
    const setRecipe = props.setRecipe
    //these are used for search. Ingredients list are t



  
    const setRecipeAndRecalculateCalories = (newRecipe) => {
        recalculateCaloriesForRecipe(newRecipe)

        setRecipe(newRecipe)
        if (props.setStagedChanges) {
            props.setStagedChanges(true)

        }

    }

    const handleOnDragEnd = (result) => {
        let recipeClone = _.cloneDeep(props.recipe)
        if (result.destination && result.source) {
            const toIndex = result.destination.index
            const fromIndex = result.source.index

            const element = recipeClone.ingredients[fromIndex];
            recipeClone.ingredients.splice(fromIndex, 1);
            recipeClone.ingredients.splice(toIndex, 0, element);
            setRecipe(recipeClone)
        }
    }



    let innerContent;

    if (recipe.areNutrientsManual) {
        innerContent = <></>
    }
    else {

        let ingredientRows = []
        for (let index in recipe.ingredients) {
            ingredientRows.push(<ModifyRecipeIngredientRow setStagedChanges={props.setStagedChanges} key={recipe.ingredients[index].id} ingredient={recipe.ingredients[index]} recipe={recipe} setRecipeAndRecalculateCalories={setRecipeAndRecalculateCalories} index={index} isReadOnly={props.isReadOnly}></ModifyRecipeIngredientRow>)
        }


        innerContent = <>
            {!props.disableSearch && <IngredientsSearchBox  setRecipe={setRecipeAndRecalculateCalories} recipe={recipe}></IngredientsSearchBox>}

            <div className="modify-recipe-ingredients-row">
                <DragDropContext onDragEnd={handleOnDragEnd}>

                    <Droppable key={1} droppableId="ingredients">
                        {(provided) => (

                            <div key={1} className="modify-recipe-ingredients-column" {...provided.droppableProps} ref={provided.innerRef}>
                                {ingredientRows}
                                {provided.placeholder}

                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className="modify-recipe-ingredients-column">

                    
                </div>
            </div>

        </>
    }


    return (
        <div style={{ textAlign: 'center' }}>
            <div className="modify-recipe-ingredients"> <div className="modify-recipe-ingredients-header">Ingredients
            </div>
            {innerContent}

            </div>

        </div>
    )

}

