import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import { config as mainConfig } from './Constants'
import {getAnalytics} from "firebase/analytics"


export const firebaseConfig = {
  apiKey: mainConfig.firebaseApiKey,
  authDomain: mainConfig.firebaseAuthDomain,
  databaseURL: mainConfig.firebaseDatabaseURL,
  projectId: mainConfig.firebaseProjectId,
  storageBucket: mainConfig.firebaseStorageBucket,
  messagingSenderId: mainConfig.firebaseMessagingSenderId,
  appId: mainConfig.firebaseAppId
}

export const app = firebase.initializeApp(firebaseConfig)

export const analytics = getAnalytics(app)

export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const googleProvider = new firebase.auth.GoogleAuthProvider();

export const auth = app.auth()

