// ExerciseTrackerCard.js

// Import statements
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { UserContext } from 'contexts/user_context';
import './exercise_tracker_card.css';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  Typography,
  MenuItem,
  Select,
  InputLabel,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';

import StrengthIcon from 'images/Rojos/Strength.png';
import SportsIcon from 'images/Rojos/Sports.png';
import YogaIcon from 'images/Rojos/Yoga.png';
import RecreationalIcon from 'images/Rojos/Recreational.png';
import HouseholdIcon from 'images/Rojos/Household.png';
import CardioActivityIcon from 'images/Rojos/Cardio Activity.png';


import CardioIcon from 'images/Rojos/Sustained Cardio.png';
import ExplosiveIcon from 'images/Rojos/Explosive Effort.png';
import FlexibilityIcon from 'images/Rojos/Flexibility.png';
import MuscleStrainIcon from 'images/Rojos/Muscle Strain.png';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { PieChart, Pie, Cell, Label } from 'recharts';

import activities from './activities_with_calories.json';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

const GaugeChart = ({ value, max = 10, label, icon }) => {
  const data = [
    { value },
    { value: max - value },
  ];

  const colors = ['#f1a092', '#e0e0e0'];

  return (
    <div className="exercise-gauge-chart">
      <PieChart width={100} height={80}>
        <Pie
          data={data}
          startAngle={180}
          endAngle={0}
          innerRadius={25}
          outerRadius={35}
          dataKey="value"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
          {/* Add Label to display the value inside the chart */}
          <Label
            value={value.toFixed(1)}
            position="center"
            className="exercise-gauge-value"
          />
        </Pie>
      </PieChart>
      <div className="exercise-gauge-label">
        {icon}
        <span>{label}</span>
      </div>
    </div>
  );
};

const ExerciseTrackerCard = ({ calendarDate }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { getExerciseData, setExerciseData, userObject } = useContext(UserContext);

  const [exercises, setExercises] = useState(getExerciseData(calendarDate) || []);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [duration, setDuration] = useState('');
  const [intensity, setIntensity] = useState('moderate');
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const [factorInfoDialogOpen, setFactorInfoDialogOpen] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const [activityTouched, setActivityTouched] = useState(false);
  const [durationTouched, setDurationTouched] = useState(false);

  const activityOptions = activities;

  const pointFactors = [
    {
      key: 'sustainedCardioEffort',
      label: 'Sustained Cardio',
      icon: (
        <img
          alt="Cardio"
          src={CardioIcon}
          style={{ marginRight: '4px', width: '25px', height: '25px' }}
        />
      ),
      description: 'Measures aerobic endurance.',
      fullDescription:
        'Sustained Cardio Effort refers to activities that keep your heart rate elevated over a prolonged period. It improves cardiovascular health, increases stamina, and aids in burning calories for weight management.',
    },
    {
      key: 'muscleStrain',
      label: 'Muscle Strain',
      icon: (
        <img
          alt="Muscle Strain"
          src={MuscleStrainIcon}
          style={{ marginRight: '4px', width: '25px', height: '25px' }}
        />
      ),
      description: 'Measures strength and resistance effort.',
      fullDescription:
        'Muscle Strain represents the effort/load carried by your muscles during physical activity. Building muscle and strength enhances metabolism, supports joint health, and improves overall physical performance.',
    },
    {
      key: 'explosiveEffort',
      label: 'Explosive Effort',
      icon: (
        <img
          alt="Explosiveness"
          src={ExplosiveIcon}
          style={{ marginRight: '4px', width: '25px', height: '25px' }}
        />
      ),
      description: 'Measures power and speed in short bursts.',
      fullDescription:
        'Explosive Effort involves quick, intense movements that improve power, speed, and agility. It is beneficial for enhancing athletic performance, building fast-twitch muscle fibers, and enhancing bone density.',
    },
    {
      key: 'flexibility',
      label: 'Flexibility',
      icon: (
        <img
          alt="Flexibility"
          src={FlexibilityIcon}
          style={{ marginRight: '4px', width: '25px', height: '25px' }}
        />
      ),
      description: 'Measures range of motion and muscle elasticity.',
      fullDescription:
        'Flexibility measures the ability of your joints and muscles to move through their full range of motion. Improving flexibility reduces the risk of injuries, alleviates muscle tension, and enhances posture.',
    },
  ];

  useEffect(() => {
    const newExercises = getExerciseData(calendarDate) || [];
    setExercises(newExercises);
  }, [userObject.exerciseData, calendarDate]);

  // EPOC adjustment function
  const getEpocAdjustment = (category, intensity) => {
    if (category === 'Cardio' || category === 'Sports') {
      if (intensity === 'high') return 5;
      if (intensity === 'veryHigh') return 10;
    } else if (category === 'Complex' || category === 'Strength') {
      if (intensity === 'moderate') return 5;
      if (intensity === 'high') return 10;
      if (intensity === 'veryHigh') return 15;
    }
    return 0;
  };

  // Function to get the current weight
  const getCurrentWeight = () => {
    const weightData = userObject.weight;
    if (!weightData) return [null, null];

    const dates = Object.keys(weightData);
    if (dates.length === 0) return [null, null];

    const latestDate = dates.sort().pop();
    const { value, unit } = weightData[latestDate];

    return [parseFloat(value), unit];
  };

  // Function to get the current height in meters
  const getCurrentHeight = () => {
    if (userObject.heightUnit === 'cm' && userObject.height) {
      return parseFloat(userObject.height) / 100;
    } else if (userObject.heightFeet && userObject.heightInches) {
      const heightInches =
        parseInt(userObject.heightFeet) * 12 + parseInt(userObject.heightInches);
      return heightInches * 0.0254;
    }
    return null;
  };

  // Function to get the intensity multiplier
  const getIntensityMultiplier = (intensity) => {
    switch (intensity) {
      case 'low':
        return 0.75;
      case 'moderate':
        return 1.0;
      case 'high':
        return 1.25;
      case 'veryHigh':
        return 1.5;
      default:
        return 1.0;
    }
  };

  // Function to calculate calories burned
  const calculateCaloriesBurned = (selectedActivity, intensity, duration) => {
    if (!selectedActivity || !duration || parseFloat(duration) <= 0) return null;

    const intensityMultiplier = getIntensityMultiplier(intensity);

    const baseCaloriesPerMinute =
      selectedActivity.baselineCaloriesBurnedPerMinute * intensityMultiplier;

    const baseCalories = baseCaloriesPerMinute * parseFloat(duration);

    const epocPercentage = getEpocAdjustment(selectedActivity.category, intensity);
    const epocMultiplier = 1 + epocPercentage / 100;

    const adjustedCalories = baseCalories * epocMultiplier;

    // *** Begin User-Specific Multipliers ***

    let [weightValue, weightUnit] = getCurrentWeight();
    let weightKg = null;
    if (weightValue && weightUnit) {
      weightKg = weightUnit === 'kg' ? weightValue : weightValue / 2.205;
    }

    const heightMeters = getCurrentHeight();

    let bmiMultiplier = 1.0;
    if (weightKg && heightMeters) {
      const bmi = weightKg / (heightMeters * heightMeters);
      if (bmi < 18.5) {
        bmiMultiplier = 0.9;
      } else if (bmi >= 18.5 && bmi <= 24.9) {
        bmiMultiplier = 1.0;
      } else if (bmi >= 25 && bmi <= 29.9) {
        bmiMultiplier = 1.1;
      } else if (bmi >= 30) {
        bmiMultiplier = 1.2;
      }
    } else {
      bmiMultiplier = 1.0;
    }

    let ageMultiplier = 1.0;
    if (userObject.age) {
      const age = parseInt(userObject.age, 10);
      if (age >= 18 && age <= 34) {
        ageMultiplier = 1.0;
      } else if (age >= 35 && age <= 45) {
        ageMultiplier = 0.95;
      } else if (age >= 46 && age <= 60) {
        ageMultiplier = 0.9;
      } else if (age >= 61) {
        ageMultiplier = 0.85;
      }
    } else {
      ageMultiplier = 1.0;
    }

    let genderMultiplier = 1.0;
    if (userObject.sex) {
      if (userObject.sex === 'female') {
        genderMultiplier = 0.9;
      } else if (userObject.sex === 'male') {
        genderMultiplier = 1.0;
      }
    } else {
      genderMultiplier = 1.0;
    }

    const finalCalories =
      adjustedCalories * bmiMultiplier * ageMultiplier * genderMultiplier;

    // *** End User-Specific Multipliers ***

    return finalCalories.toFixed(0); // Return as a string with no decimals
  };

  const handleAddExercise = () => {
    setOpenDialog(true);
    setIsEditing(false);
    setEditIndex(null);
    setSelectedActivity(null);
    setDuration('');
    setIntensity('moderate');
    setActivityTouched(false);
    setDurationTouched(false);
  };

  const handleEditExercise = (index) => {
    const exerciseToEdit = exercises[index];
    setSelectedActivity(activities.find((a) => a.name === exerciseToEdit.activity));
    setDuration(exerciseToEdit.duration.toString());
    setIntensity(exerciseToEdit.intensity);
    setIsEditing(true);
    setEditIndex(index);
    setOpenDialog(true);
    setActivityTouched(false);
    setDurationTouched(false);
  };

  const handleSaveExercise = () => {
    if (selectedActivity && duration) {
      const finalCalories = calculateCaloriesBurned(selectedActivity, intensity, duration);

      const intensityMultiplier = getIntensityMultiplier(intensity);

      const baseScores = selectedActivity.scores;

      const adjustedScores = {
        sustainedCardioEffort: Math.round(
          baseScores.sustainedCardioEffort * intensityMultiplier
        ),
        muscleStrain: Math.round(baseScores.muscleStrain * intensityMultiplier),
        explosiveEffort: Math.round(baseScores.explosiveEffort * intensityMultiplier),
        flexibility: Math.round(baseScores.flexibility * intensityMultiplier),
      };

      const points = {
        sustainedCardioEffort:
          adjustedScores.sustainedCardioEffort * parseFloat(duration),
        muscleStrain: adjustedScores.muscleStrain * parseFloat(duration),
        explosiveEffort: adjustedScores.explosiveEffort * parseFloat(duration),
        flexibility: adjustedScores.flexibility * parseFloat(duration),
      };

      const newExercise = {
        activity: selectedActivity.name,
        duration: parseFloat(duration),
        category: selectedActivity.category,
        intensity,
        caloriesBurned: finalCalories,
        points,
      };

      let updatedExercises;
      if (isEditing) {
        updatedExercises = [...exercises];
        updatedExercises[editIndex] = newExercise;
      } else {
        updatedExercises = [...exercises, newExercise];
      }

      // Log to Firebase Analytics
      FirebaseAnalytics.logEvent({
        name: 'tracked_exercise',
        params: { exerciseName: selectedActivity.name },
      });

      setExercises(updatedExercises);
      setExerciseData(calendarDate, updatedExercises);

      handleCloseDialog();
    }
  };

  const handleDeleteExercise = (index) => {
    const updatedExercises = exercises.filter((_, i) => i !== index);
    setExercises(updatedExercises);
    setExerciseData(calendarDate, updatedExercises);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedActivity(null);
    setDuration('');
    setIntensity('moderate');
    setIsEditing(false);
    setEditIndex(null);
    setActivityTouched(false);
    setDurationTouched(false);
  };

  // Determine if the Save button should be disabled
  const isSaveDisabled = !selectedActivity || !duration || parseFloat(duration) <= 0;

  // Compute intensity multiplier and adjusted scores
  const intensityMultiplier = getIntensityMultiplier(intensity);

  let adjustedScores = {};

  if (selectedActivity && selectedActivity.scores) {
    const baseScores = selectedActivity.scores;
    adjustedScores = {
      sustainedCardioEffort: baseScores.sustainedCardioEffort * intensityMultiplier,
      muscleStrain: baseScores.muscleStrain * intensityMultiplier,
      explosiveEffort: baseScores.explosiveEffort * intensityMultiplier,
      flexibility: baseScores.flexibility * intensityMultiplier,
    };
  }

  // Calculate estimated calories burned
  const calculatedCaloriesBurned = useMemo(() => {
    return calculateCaloriesBurned(selectedActivity, intensity, duration);
  }, [selectedActivity, intensity, duration, userObject]);

  return (
    <div className="exercise-tracker-card">
      <div className="exercise-tracker-header">
        <div className="exercise-tracker-header-left">
          Exercise
          <button onClick={handleAddExercise} className="add-exercise-button">
            <AddCircleIcon
              style={{
                fontSize: '18px',
                color: '#f1a092',
              }}
            />
          </button>
        </div>

        <div className="exercise-tracker-header-right">
          <IconButton
            style={{ marginLeft: '4px' }}
            onClick={() => setInfoDialogOpen(true)}
            size="small"
          >
            <InfoIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
      <div className="exercise-list">
        {exercises.length === 0 ? (
          <div className="no-exercise">No exercises logged for this day</div>
        ) : (
          exercises.map((exercise, index) => (
            <div key={index} className="exercise-item">
              <div className="exercise-details">
                {exercise.category === 'Strength' ? (
                  <img
                    alt="Strength"
                    src={StrengthIcon}
                    style={{
                      marginRight: '11px',
                      marginLeft: '5px',
                      width: '40px',
                      height: '40px',
                    }}
                  />
                ) : exercise.category === 'Sports' ? (
                  <img
                    alt="Sports"
                    src={SportsIcon}
                    style={{
                      marginRight: '11px',
                      marginLeft: '5px',
                      width: '40px',
                      height: '40px',
                    }}
                  />
                ) : exercise.category === 'Recreational' ? (
                  <img
                    alt="Recreational"
                    src={RecreationalIcon}
                    style={{
                      marginRight: '11px',
                      marginLeft: '5px',
                      width: '40px',
                      height: '40px',
                    }}
                  />
                ) : exercise.category === 'Household' ? (
                  <img
                    alt="Household"
                    src={HouseholdIcon}
                    style={{
                      marginRight: '11px',
                      marginLeft: '5px',
                      width: '40px',
                      height: '40px',
                    }}
                  />
                ) : exercise.category === 'Flexibility' ? (
                  <img
                    alt="Yoga"
                    src={YogaIcon}
                    style={{
                      marginRight: '11px',
                      marginLeft: '5px',
                      width: '40px',
                      height: '40px',
                    }}
                  />
                ) : (
                  <img
                    alt="Cardio"
                    src={CardioActivityIcon}
                    style={{
                      marginRight: '11px',
                      marginLeft: '5px',
                      width: '40px',
                      height: '40px',
                    }}
                  />
                )}

                <div>
                  <div className="exercise-activity">{exercise.activity}</div>
                  <div className="exercise-info">
                    {exercise.caloriesBurned} Cals (Estimated Burn)
                  </div>
                  {/* Points Summary */}
                  <div className="exercise-points">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '12px',
                        color: 'gray',
                        flexWrap: 'wrap',
                        cursor: 'pointer',
                      }}
                      onClick={() => setFactorInfoDialogOpen(true)}
                    >
                      <img
                        alt="Cardio"
                        src={CardioIcon}
                        style={{
                          marginRight: '4px',
                          width: '25px',
                          height: '25px',
                          maxWidth: '6vw',
                          maxHeight: '6vw',
                        }}
                      />
                      {exercise.points?.sustainedCardioEffort.toFixed(0)}&nbsp;&nbsp;
                      <img
                        alt="Muscle Strain"
                        src={MuscleStrainIcon}
                        style={{
                          marginRight: '4px',
                          width: '25px',
                          height: '25px',
                          maxWidth: '6vw',
                          maxHeight: '6vw',
                        }}
                      />
                      {exercise.points?.muscleStrain.toFixed(0)}&nbsp;&nbsp;
                      <img
                        alt="Explosiveness"
                        src={ExplosiveIcon}
                        style={{
                          marginRight: '4px',
                          width: '25px',
                          height: '25px',
                          maxWidth: '6vw',
                          maxHeight: '6vw',
                        }}
                      />
                      {exercise.points?.explosiveEffort.toFixed(0)}&nbsp;&nbsp;
                      <img
                        alt="Flexibility"
                        src={FlexibilityIcon}
                        style={{
                          marginRight: '4px',
                          width: '25px',
                          height: '25px',
                          maxWidth: '6vw',
                          maxHeight: '6vw',
                        }}
                      />
                      {exercise.points?.flexibility.toFixed(0)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="exercise-actions">
                <Tooltip title="Edit Exercise">
                  <IconButton onClick={() => handleEditExercise(index)} size="small">
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Exercise">
                  <IconButton onClick={() => handleDeleteExercise(index)} size="small">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Add/Edit Exercise Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullScreen={fullScreen}
        scroll="paper"
      >
        <DialogTitle>{isEditing ? 'Edit Exercise' : 'Add Exercise'}</DialogTitle>
        <DialogContent dividers>
          <div style={{ width: '100%', maxWidth: '500px' }}>
            <div className="exercise-not-found-message">
              Can't find an activity? <NavLink to="contact_us">Contact us</NavLink> to get it added
            </div>
            <Autocomplete
              options={activityOptions}
              getOptionLabel={(option) => option.name}
              value={selectedActivity}
              onChange={(event, newValue) => {
                setSelectedActivity(newValue);
                setActivityTouched(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Activity"
                  variant="outlined"
                  fullWidth
                  required
                  error={!selectedActivity && activityTouched}
                  helperText={
                    !selectedActivity && activityTouched ? 'Please select an activity' : ''
                  }
                />
              )}
              onBlur={() => setActivityTouched(true)}
              style={{ marginTop: '10px' }}
            />

            {/* Intensity Selection */}
            <InputLabel
              style={{ marginTop: '20px' }}
              id="intensity-select-label"
              shrink
            >
              Intensity
            </InputLabel>
            <Select
              value={intensity}
              onChange={(e) => setIntensity(e.target.value)}
              variant="outlined"
              fullWidth
              label="Intensity"
            >
              <MenuItem value="low">Low</MenuItem>
              <MenuItem value="moderate">Moderate</MenuItem>
              <MenuItem value="high">High</MenuItem>
              <MenuItem value="veryHigh">Very High</MenuItem>
            </Select>

            {/* Display Baseline Scores After Activity and Intensity Selection */}
            {selectedActivity && (
              <div style={{ marginTop: '10px' }}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{ display: 'flex', alignItems: 'center', marginBottom: '0px' }}
                >
                  Baseline Points per Minute
                  <IconButton
                    size="small"
                    onClick={() => setFactorInfoDialogOpen(true)}
                    style={{ marginLeft: '5px' }}
                  >
                    <HelpOutlineIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <div className="exercise-gauge-container">
                  {pointFactors.map((factor) => (
                    <div key={factor.key} className="exercise-gauge-wrapper">
                      <GaugeChart
                        value={Math.min(parseFloat(adjustedScores[factor.key]), 10)}
                        label={factor.label}
                        icon={factor.icon}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            <TextField
              label="Duration (minutes)"
              type="number"
              value={duration}
              onChange={(e) => {
                setDuration(e.target.value);
              }}
              onBlur={() => setDurationTouched(true)}
              fullWidth
              variant="outlined"
              style={{ marginTop: '25px' }}
              inputProps={{ min: 1 }}
              required
              error={(!duration || parseFloat(duration) <= 0) && durationTouched}
              helperText={
                (!duration || parseFloat(duration) <= 0) && durationTouched
                  ? 'Please enter a valid duration'
                  : ''
              }
            />

            {/* Display Estimated Calories Burned */}
            {calculatedCaloriesBurned && (
              <Typography variant="body2" style={{ marginTop: '15px' }}>
                Estimated Calories Burned: {calculatedCaloriesBurned} Cals
              </Typography>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveExercise} color="primary" disabled={isSaveDisabled}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Factor Info Dialog */}
      <Dialog
        open={factorInfoDialogOpen}
        onClose={() => setFactorInfoDialogOpen(false)}
        scroll="paper"
        fullScreen={fullScreen}
      >
        <DialogTitle>Exercise Points Factors</DialogTitle>
        <DialogContent dividers>
          <div style={{ padding: '0px 20px 0px 20px', marginBottom: '-10px' }}>
            {pointFactors.map((factor) => (
              <div key={factor.key} style={{ marginBottom: '15px' }}>
                <Typography
                  variant="subtitle1"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {factor.icon}
                  {factor.label}
                </Typography>
                <Typography variant="body2">{factor.fullDescription}</Typography>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFactorInfoDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Info Dialog */}
      <Dialog
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
        scroll="paper"
        fullScreen={fullScreen}
      >
        <DialogTitle>About Exercise Tracking</DialogTitle>
        <DialogContent dividers>
          <div className="dialog-content" style={{ padding: '0px 20px 0px 20px' }}>
            <Typography variant="body1" style={{ fontSize: '14px' }}>
              We display estimated calories burned based on your selected activity, intensity,
              duration, and biometric data as well as expected post-exercise oxygen
              consumption (EPOC).
            </Typography>
            <br />
            <Typography variant="body1" style={{ fontSize: '14px' }}>
              Please note that these are estimates and actual calories burned may vary.
            </Typography>
            <br />
            <Typography variant="body1" style={{ fontSize: '14px' }}>
              We do not modify your daily calorie/macro targets based on daily exercise. We
              recommend using our AI consultant every 2 weeks to find new daily targets. The AI
              consultant will account for changes in weight, body fat %, exercise patterns,
              hydration and food intake to make recommendations.
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInfoDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExerciseTrackerCard;
