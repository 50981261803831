import React from 'react';
import './week_selector.css';

const WeekSelector = ({ weeks, selectedWeek, setSelectedWeek }) => {
  return (
    <div className="week-selector">
      <select value={selectedWeek} onChange={(e) => setSelectedWeek(e.target.value)}>
        {weeks.map((week, index) => (
          <option key={index} value={week.startOfWeek}>
            {week.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default WeekSelector;
