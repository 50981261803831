import React, { createContext } from 'react'
import axios from 'axios';
import { config } from '../Constants'
import { UserContext } from './user_context';


axios.defaults.baseURL = config.API_URL;

export const MenuContext = createContext();

export const MenuContextProvider = (props) => {
    const [menuObject, setMenuObject] = React.useState({ deliveryDates: null, minimumMeals: 0,loaded:false });
    const foodVendorID = React.useContext(UserContext).userObject.foodVendorID
    const userObjectRef = React.useContext(UserContext).userObjectRef
    const setChosenDeliveryDate = React.useContext(UserContext).setChosenDeliveryDate
    const menuObjectRef = React.useRef({});
    menuObjectRef.current = menuObject;

    function getCurrentDeliveryDate() {

        if (menuObjectRef.current.deliveryDates && menuObjectRef.current.deliveryDates.length!==0) {
            let chosenDeliveryDate = userObjectRef.current.chosenDeliveryDate

            for (let dateIndex in menuObjectRef.current.deliveryDates) {
                if (menuObjectRef.current.deliveryDates[dateIndex].DeliveryDate === chosenDeliveryDate) {
                    return menuObjectRef.current.deliveryDates[dateIndex]
                }
            }
            setChosenDeliveryDate(menuObjectRef.current.deliveryDates[0].DeliveryDate)
            return menuObjectRef.current.deliveryDates[0]
        }
    }

    function getActiveMenuId() {
        let chosenDeliveryDate = userObjectRef.current.chosenDeliveryDate
        let deliveryDate;

        for (let dateIndex in menuObject.deliveryDates) {
            if (menuObject.deliveryDates[dateIndex].DeliveryDate === chosenDeliveryDate) {
                deliveryDate = menuObject.deliveryDates[dateIndex]
            }
        }
        return deliveryDate.MenuID
    }


    function getActiveMenu(chosenDeliveryDateParam = userObjectRef.current.chosenDeliveryDate) {
        if (!menuObject.deliveryDates) {
            return
        }

        let deliveryDate;

        for (let dateIndex in menuObject.deliveryDates) {
            if (menuObject.deliveryDates[dateIndex].DeliveryDate === chosenDeliveryDateParam) {
                deliveryDate = menuObject.deliveryDates[dateIndex]
            }
        }


        let activeMenu = []

        if (deliveryDate) {
            let activeMenuId = deliveryDate.MenuID

            for (let menuIndex in menuObject.menus) {
                if (menuObject.menus[menuIndex].ID === activeMenuId) {
                    let menuRecipeIDs = menuObject.menus[menuIndex].RecipeIDs
                    for (let recipeIdIndex in menuObject.recipes) {
                        for (let menuIdIndex in menuRecipeIDs) {
                            let menuId = menuRecipeIDs[menuIdIndex]
                            if (menuObject.recipes[recipeIdIndex].id === menuId) {
                                activeMenu.push(menuObject.recipes[recipeIdIndex])
                                break
                            }


                        }
                    }
                }
            }
        }

        return activeMenu
    }



    const getActiveMenuWithRefresh = async (foodVendorIDParam = foodVendorID) => {
        let menuObjectLocal = menuObject

        if (!menuObject.deliveryDates) {
            menuObjectLocal = await refreshMenuData(foodVendorIDParam)
        }

        if (menuObjectLocal) {
            let deliveryDate = getCurrentDeliveryDate()
            let activeMenu = []
            if (deliveryDate) {
                let activeMenuId = deliveryDate.MenuID
                for (let menuIndex in menuObjectLocal.menus) {
                    if (menuObjectLocal.menus[menuIndex].ID === activeMenuId) {
                        let menuRecipeIDs = menuObjectLocal.menus[menuIndex].RecipeIDs
                        for (let menuIdIndex in menuRecipeIDs) {
                            let menuId = menuRecipeIDs[menuIdIndex]
                            for (let recipeIdIndex in menuObjectLocal.recipes) {
                                if (menuObjectLocal.recipes[recipeIdIndex].id === menuId) {
                                    activeMenu.push(menuObjectLocal.recipes[recipeIdIndex])
                                    break
                                }


                            }

                        }
                    }
                }
            }
            return activeMenu

        }
    }

    const refreshMenuData = async (foodVendorIDParam, minimumMeals = menuObject.minimumMeals) => {
        try {
            if (foodVendorIDParam === null) { 
                setMenuObject({...menuObject, loaded:true})
                return null 
            }
            const resp = await axios.get('vendorOffer/' + foodVendorIDParam);
            let retObject = { ...menuObject, recipes: resp.data.Offer.Recipes, menus: resp.data.Offer.Menus, deliveryDates: resp.data.Offer.DeliveryDates, minimumMeals: resp.data.MinimumOrderMeals, loaded:true }
            setMenuObject(retObject)
            return (retObject)
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }

    function getMenuCategories() {
        let menu = getActiveMenu()
        if (menu) {
            let categories = []
            for (let menuIndex in menu) {
                if (!categories.includes(menu[menuIndex].category)) {
                    categories.push(menu[menuIndex].category)
                }
            }
            return categories
        }

    }

    function getMenuFilters() {
        let menu = getActiveMenu()
        if (menu) {

            let filters = []
            for (let menuIndex in menu) {
                for (let tagIndex in menu[menuIndex].tags) {
                    if (!filters.includes(menu[menuIndex].tags[tagIndex])) {
                        filters.push(menu[menuIndex].tags[tagIndex])
                    }
                }
            }
            return filters
        }

    }


    return (
        <MenuContext.Provider value={{ menuObject: menuObject, setMenuObject:setMenuObject, getActiveMenu: getActiveMenu, getActiveMenuWithRefresh: getActiveMenuWithRefresh, refreshMenuData: refreshMenuData, getMenuCategories: getMenuCategories, getMenuFilters: getMenuFilters, getCurrentDeliveryDate: getCurrentDeliveryDate, getActiveMenuId: getActiveMenuId }}>
            {props.children}
        </MenuContext.Provider>

    );
};