// components/shared/graphs/hydration_over_time_graph.js

import React from 'react';
import { UserContext } from 'contexts/user_context';
import { parseDateMonthYear } from 'helpers/date_helpers';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Brush } from 'recharts';
import { groupDataByWeek } from 'helpers/data_helpers';

const HydrationOverTimeGraph = () => {
    const userObject = React.useContext(UserContext).userObject;
    let data = [];

    if (userObject.waterIntake && Object.keys(userObject.waterIntake).length > 0) {
        for (let dateStr of Object.keys(userObject.waterIntake)) {
            const date = parseDateMonthYear(dateStr);
            const intake = userObject.waterIntake[dateStr];

            if (intake > 0) {
                data.push({
                    date: date,
                    intake: intake
                });
            }
        }

        // Group data by week
        const groupedData = groupDataByWeek(data);

        // Aggregate data per week
        let aggregatedData = [];

        for (let week in groupedData) {
            const weekData = groupedData[week];

            const totalIntake = weekData.reduce((sum, item) => sum + item.intake, 0);
            const daysWithData = weekData.length;
            const avgIntake = totalIntake / daysWithData;

            aggregatedData.push({
                week: week,
                avgIntake: avgIntake
            });
        }

        // Modify week labels
        aggregatedData = aggregatedData.map((week) => {
            const weekStartDate = getDateOfISOWeek(week.week);
            const options = { month: 'numeric', day: 'numeric' };
            const formattedDate = weekStartDate.toLocaleDateString(undefined, options);
            return {
                ...week,
                weekLabel: `Week of ${formattedDate}`,
            };
        });

        // Sort the data by week
        aggregatedData.sort((a, b) => (a.week > b.week ? 1 : -1));

        return (
            <ResponsiveContainer  style={{marginLeft:'-20px'}} width="100%" height={300}>
                <AreaChart data={aggregatedData}>
                    <XAxis dataKey="weekLabel" />
                    <YAxis />
                    <Tooltip formatter={(value) => `${value.toFixed(1)} cups`} />
                    <Area
                        type="monotone"
                        dataKey="avgIntake"
                        name="Avg Hydration"
                        stroke="#24C581"
                        fill="#82ca9d"
                    />
                    <Brush dataKey="weekLabel" height={30} stroke="#24C581" />
                </AreaChart>
            </ResponsiveContainer>
        );
    } else {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <h2>No hydration data found.</h2>
                <p>Please log your hydration to see data.</p>
            </div>
        );
    }
};

// Helper function to get the start date of the ISO week
const getDateOfISOWeek = (isoWeekString) => {
    const [yearStr, weekStr] = isoWeekString.split('-W');
    const year = parseInt(yearStr, 10);
    const week = parseInt(weekStr, 10);

    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dayOfWeek = simple.getDay();
    const ISOweekStart = new Date(simple);

    if (dayOfWeek <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart;
};

export default HydrationOverTimeGraph;
