import './sign_up.css'
import { Button, TextField } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { AuthContext } from "contexts/auth_context"
import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import { googleProvider } from "../../../firebase"
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import LoopIcon from '@material-ui/icons/Loop';
import { Capacitor } from '@capacitor/core'
import AppleIcon from '@material-ui/icons/Apple';


export default function SignUp() {
    let [signUpObject, setSignUpObject] = React.useState({ password: '', email: '', password2: '', name: '' });
    let [validationObject, setValidationObject] = React.useState({ formErrors: { email: [], password: [], password2: [] }, emailValid: true, passwordValid: true, password2Valid: true })
    const { signUp, loginSocial, appleLogin } = useContext(AuthContext)
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const { currentUser } = React.useContext(AuthContext)

    const handleSocialClick = async (provider) => {
        await loginSocial(provider)
        setLoading(true)
    }

    const loginWithApple = async () => {
        setLoading(true)
        await appleLogin()
        setLoading(false)
    }


    function validateField(fieldName, value) {
        let fieldValidationErrors = validationObject.formErrors;
        let emailValid = validationObject.emailValid;
        let passwordValid = validationObject.passwordValid;
        let password2Valid = validationObject.password2Valid;

        switch (fieldName) {
            case 'email':
                fieldValidationErrors['email'] = []
                emailValid = true
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    emailValid = false
                    fieldValidationErrors.email.push(' is invalid')
                }
                break;
            case 'password':
                fieldValidationErrors['password'] = []
                fieldValidationErrors['password2'] = []
                passwordValid = true
                password2Valid = true
                if (value.length < 8) {
                    fieldValidationErrors.password.push(' at least 8 characters')
                    passwordValid = false
                }
                if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{1,}$/)) {
                    fieldValidationErrors.password.push(' a lowercase letter, uppercase letter, number and special character')
                    passwordValid = false
                }
                if (value !== signUpObject.password2 && signUpObject.password2 !== '') {
                    fieldValidationErrors.password2.push(' need to match')
                    password2Valid = false
                }
                break;
            case 'password2':
                fieldValidationErrors['password2'] = []
                password2Valid = true
                if (value !== signUpObject.password) {
                    fieldValidationErrors.password2.push(' need to match')
                    password2Valid = false
                }
                break;
            default:
                break;
        }
        setValidationObject({
            ...validationObject, formErrors: fieldValidationErrors,
            emailValid: emailValid,
            passwordValid: passwordValid,
            password2Valid: password2Valid,
            formValid: emailValid && passwordValid && password2Valid
        });
    }



    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError("")
            setLoading(true)
            let error = await signUp(signUpObject.email, signUpObject.password, signUpObject.name)
            setLoading(false)
            if (error) {
                setError("Failed to log in - " + error)
            }

        } catch (e) {
            console.log(e)
            setError("Failed to log in")
            setLoading(false)
        }

    }

    if (currentUser !== null) {
        history.push("/calendar")
    }

    const handleChange = (e) => {
        setSignUpObject({ ...signUpObject, [e.target.name]: e.target.value })
        validateField(e.target.name, e.target.value)
    }

    return (
        <>
            <div className="contact-us-page-top">
                <div className="signup-frame">
                    <div className="signup-header">Sign Up</div>
                    {error && <Alert severity={"error"}>{error}</Alert>}
                    <form onSubmit={handleSubmit}>
                        <div className="signup-form-line-item">
                            <TextField className="signup-form-input-wide" type="text" variant="outlined" required={true} label="name" name='name' value={signUpObject.name} onChange={handleChange}></TextField>
                        </div>
                        <div className="signup-form-line-item">
                            <TextField className="signup-form-input-wide" error={!validationObject.emailValid} type="text" variant="outlined" required={true} label="email" name='email' value={signUpObject.email} onChange={handleChange}></TextField>
                        </div>
                        <div className="signup-form-line-item">
                            <TextField className="signup-form-input-wide" error={!validationObject.passwordValid} type="password" variant="outlined" required={true} label="password" name='password' value={signUpObject.password} onChange={handleChange}></TextField>
                        </div>
                        <div className="signup-form-line-item">
                            <TextField className="signup-form-input-wide" error={!validationObject.password2Valid} type="password" variant="outlined" required={true} label="repeat password" name='password2' value={signUpObject.password2} onChange={handleChange}></TextField>
                        </div>
                        <div className='formErrors'>
                            {Object.keys(validationObject.formErrors).map((fieldName, i) => {
                                if (validationObject.formErrors[fieldName].length > 0 && signUpObject[fieldName]) {
                                    if (fieldName === "password2") {
                                        return (
                                            <Alert severity={"error"} key={i} style={{ marginBottom: '10px' }}> passwords need to match</Alert>
                                        )
                                    }
                                    return (
                                        <Alert severity={"error"} key={i} style={{ marginBottom: '10px' }}> {fieldName} needs to have  {validationObject.formErrors[fieldName].join()}</Alert>
                                    )
                                } else {
                                    return '';
                                }
                            })}
                        </div>
                        <Button disabled={(!validationObject.formValid) || loading} variant="contained" className="login-button" type="submit">
                            Sign Up {loading && <LoopIcon style={{ animation: 'spin 2s linear infinite' }}></LoopIcon>}
                        </Button>
                    </form>
                    <div style={{ marginTop: '10px' }}>OR</div>
                    {/* <Button className="login-facebook-button"onClick={()=>handleSocialClick(facebookProvider)}>Sign-in with Facebook</Button> */}
                    <Button className="login-google-button" onClick={() => handleSocialClick(googleProvider)}>
                        <img className="google-logo" alt="google logo" src="/googleLogo.jpeg" />
                        Sign in with Google
                    </Button>
                    {Capacitor.getPlatform() === 'ios' &&
                        <Button
                            className="login-apple-button"
                            onClick={() => loginWithApple()}
                            startIcon={<AppleIcon />}
                        >
                            Sign in with Apple
                        </Button>
                    } 
                    <div className="signup-form-switch">Already have a username and password? <NavLink to="login">Log in</NavLink></div>


                </div>
            </div>
        </>
    )
}