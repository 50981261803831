import { Button, Chip, Dialog, Input, MenuItem, Select, TextField } from '@material-ui/core'
import React from 'react'
import './modify_recipe.css'

import ModifyRecipeInstructions from './modify_recipe_instructions';
import ImageIcon from '@material-ui/icons/Image';
import { CloseRounded } from '@material-ui/icons';
import { RecipeBooksContext } from 'contexts/recipe_books_context';
import ModifyRecipeIngredients from './modify_recipe_ingredients';
import { Alert } from '@material-ui/lab';
import CalorieChart from '../calorie_chart/calorie_chart';
// import { ActionSheet } from '@capacitor/action-sheet';
import { Capacitor } from '@capacitor/core';
import SwipeableTemporaryDrawer from '../calendar/SwipeableTemporaryDrawer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CopyRecipeDialog from './copy_recipe_dialog';
import DeleteIcon from '@material-ui/icons/Delete';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ContentCopy from 'images/content_copy.svg'
import { AuthContext } from 'contexts/auth_context';
import CopyRecipeDialogBiteTracker from './copy_recipe_dialog_BT';
import MealPortioningSlider from '../meal_portioning_slider/meal_portioning_slider';


export default function ModifyRecipe(props) {
    const [recipe, setRecipe] = React.useState(props.recipeBeingModified)
    const [pictureFile, setPictureFile] = React.useState(null)
    const addOrModifyRecipe = React.useContext(RecipeBooksContext).addOrModifyRecipe
    const deleteRecipe = React.useContext(RecipeBooksContext).deleteRecipe
    const getCustomRecipeBookId = React.useContext(RecipeBooksContext).getCustomRecipeBookId
    const currentUser = React.useContext(AuthContext).currentUser
    const [attemptedDeletion, setAttemptedDeletion] = React.useState(false)
    const [imageErrorState, setImageErrorState] = React.useState(0)
    const [stagedChanges, setStagedChanges] = React.useState(false)
    const [hasExitedStagedChanges, setHasExitedStagedChanges] = React.useState(false)
    const [attemptedCopy, setAttemptedCopy] = React.useState(false)
    const [attemptedCopyBT, setAttemptedCopyBT] = React.useState(false)
    const [isTagsActionSheetOpen, setIsTagsActionSheetOpen] = React.useState(false)
    const [isCategoriesActionSheetOpen, setIsCategoriesActionSheetOpen] = React.useState(false)
    const [isComplexityActionSheetOpen, setIsComplexityActionSheetOpen] = React.useState(false)


    const submitRecipeChange = () => {
        addOrModifyRecipe(recipe, pictureFile)
        props.setCreatingNewRecipe && props.setCreatingNewRecipe(false)
        //handle success/fail
        setStagedChanges(false)

        props.close()
    }

    const handleExitClick = () => {
        props.close()
    }

    const handleAttemptedDeletion = () => {
        setAttemptedDeletion(true)
    }

    const handleAttemptedCopy = () => {
        setAttemptedCopy(true)
    }

    const handleAttemptedCopyBT = () => {
        setAttemptedCopyBT(true)
    }




    async function handleDelete() {
        setAttemptedDeletion(false)
        deleteRecipe(recipe)
        props.setCreatingNewRecipe && props.setCreatingNewRecipe(false)
        props.close()
    }

    const handleIntChange = (e) => {
        setRecipe({ ...recipe, [e.target.name]: parseInt(e.target.value) })
        setStagedChanges(true)

    }

    const handleChange = (e) => {
        let cleanedValues = e.target.value
        if (e.target.value.constructor === Array) {
            cleanedValues = cleanedValues.filter(item => item !== 'Select Category');
            cleanedValues = cleanedValues.filter(item => item !== 'Select Tags');
        }
        setRecipe({ ...recipe, [e.target.name]: cleanedValues })
        setStagedChanges(true)

    }

    const handleChangeTagAction = (newVal) => {
        let lstTmp = [...recipe.tags]
        let ind = lstTmp.indexOf(newVal)
        let lstFinal = []
        if (ind >= 0) {
            lstFinal = lstTmp.filter((val) => val !== newVal);
        } else {
            lstFinal = [...lstTmp, newVal]
        }
        setRecipe({ ...recipe, 'tags': lstFinal })
        setStagedChanges(true)
    }

    const handleChangeCategoriesAction = (newVal) => {
        let lstTmp = [...recipe.categories]
        let ind = lstTmp.indexOf(newVal)
        let lstFinal = []
        if (ind >= 0) {
            lstFinal = lstTmp.filter((val) => val !== newVal);
        } else {
            lstFinal = [...lstTmp, newVal]
        }
        setRecipe({ ...recipe, 'categories': lstFinal })
        setStagedChanges(true)
    }

    const handleChangeComplexityAction = (newVal) => {
        setRecipe({ ...recipe, 'complexity': newVal })
        setStagedChanges(true)
    }
    // const handleIntChange = (e) => {
    //     setRecipe({ ...recipe, [e.target.name]: parseInt(e.target.value) })
    // }

    // const handleBoolChange = (e) => {
    //     setRecipe({ ...recipe, [e.target.name]: Boolean(e.target.checked) })
    // }

    const handleChangeImage = e => {
        if (e.target.files[0].size > 5 * 1000 * 1024) {
            setImageErrorState(1)
            return
        }
        setRecipe({ ...recipe, picture: URL.createObjectURL(e.target.files[0]) })
        setPictureFile(e.target.files[0])
        setStagedChanges(true)
    }


    const tags = [
        'side dish',
        'main dish',
        'low-cal',
        'low-fat',
        'low-carb',
        'high-protein',
        'vegetarian',
        'vegan',
        'gluten free',
        'dairy free'

    ];

    const categories = [
        "breakfast",
        "lunch",
        "dinner",
        "snack"
    ];

    const complexity = [
        "easy",
        "medium",
        "advanced"
    ];

    // const showActionsTags = async (options) => {
    //     const result = await ActionSheet.showActions({
    //         title: 'Options',
    //         message: 'Select an option to perform',
    //         options: options.map((key) => {
    //             return {
    //                 title: key
    //             }
    //         }),
    //     });
    //     handleChangeTagAction(options[result.index])
    // };

    // const showActionsCategories = async (options) => {
    //     const result = await ActionSheet.showActions({
    //         title: 'Options',
    //         message: 'Select an option to perform',
    //         options: options.map((key) => {
    //             return {
    //                 title: key
    //             }
    //         }),
    //     });
    //     handleChangeCategoriesAction(options[result.index])
    // };

    function setPortion(portion) {

        setRecipe({ ...recipe, portion: portion })

    }

    if (recipe) {
        return (
            <div style={{ textAlign: 'center', margin: '15px' }}> <Button className='modify-recipe-exit-button' onClick={handleExitClick}><CloseRounded fontSize="small" /></Button>
                {stagedChanges && !hasExitedStagedChanges && <Alert className="modify-recipe-staged-changes-warning" icon={false} severity="info">
                    Your changes are not yet saved. <button className="mini-submit-button" style={{ marginLeft: '15px' }} onClick={submitRecipeChange}> Save Changes</button></Alert>}
                <div className="modify-recipe-top">
                    <div className="modify-recipe-top-column"> <img className="modify-recipe-picture" style={{ margin: 'auto' }} src={recipe.picture} alt={recipe.name} />
                        {!props.isReadOnly && <Button className="modify-recipe-change-image-button" variant="contained" component="label" label="upload file"><ImageIcon /> Change
                            <input type="file" name="picture" accept="image/jpeg, image/png, image/jpg" id="imgfile" onChange={handleChangeImage} hidden></input>

                        </Button>}
                        {imageErrorState === 1 && <Alert severity="error">Image with maximum size of 5MB are allowed</Alert>}

                    </div>

                    <div className="modify-recipe-top-column">
                        <TextField className="modify-recipe-name" label="recipe name" name="name" InputLabelProps={{ shrink: true }} value={recipe.name} onChange={handleChange} InputProps={{ disableUnderline: props.isReadOnly, readOnly: props.isReadOnly }} ></TextField>
                       

                    </div>

                </div>

                {!props.creatingNewRecipe && <div className="modify-recipe-action-buttons">
                    {!props.disableCopy && <Button style={{ marginBottom: '10px' }} className="modify-recipe-top-button" variant="outlined" onClick={handleAttemptedCopy}>{recipe.recipeBooks[0] === getCustomRecipeBookId() ?
                        <><img src={ContentCopy} alt='copy button' style={{ marginRight: '15px' }} /> Copy this recipe</> :
                        <><BookmarkBorderIcon style={{ marginRight: '8px', color: "rgb(45, 121, 51)" }} /> Save this recipe</>}
                    </Button>}

                    {!props.isReadOnly && <Button className="modify-recipe-top-button" variant="outlined" onClick={handleAttemptedDeletion}><DeleteIcon style={{ marginRight: '5px', color: "rgb(0,0,0, 0.9)" }} />Delete this recipe</Button>}
                    {currentUser.uid === "yAejyvwWGfZ83kWcn0BUCT8PVhp1" && <Button style={{ marginBottom: '10px', marginTop: '10px' }} className="modify-recipe-top-button" variant="outlined" onClick={handleAttemptedCopyBT}>
                        <img src={ContentCopy} alt='copy button' style={{ marginRight: '15px' }} /> Copy to BT recipe book

                    </Button>}

                </div>}
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
                        <div style={{marginTop:'20px', fontFamily:'Montserrat-SemiBold', fontSize:'16px'}}>Recipe Serving Size: </div><MealPortioningSlider portion={recipe.portion} setPortion={setPortion} meal={recipe} setCartMeal={setRecipe} />
                        </div>
                <div style={{ width: "calc(100% - 30px)", maxWidth: '420px', margin: '30px auto 10px auto' }}>
                    <CalorieChart meal={recipe} setRecipe={setRecipe}></CalorieChart>
                </div>

                <ModifyRecipeIngredients setStagedChanges={setStagedChanges} disableSearch={props.isReadOnly} isReadOnly={props.isReadOnly} recipe={recipe} setRecipe={setRecipe} />


                <div>

                    <ModifyRecipeInstructions setStagedChanges={setStagedChanges} isReadOnly={props.isReadOnly} setRecipe={setRecipe} recipe={recipe}></ModifyRecipeInstructions>

                </div>

                <div className="modify-recipe-ingredients-header" style={{ marginTop: '22px' }}>Additional Info
                </div>

                {(!props.isReadOnly || recipe.description) && <TextField className="modify-recipe-description" InputLabelProps={{ shrink: true }} multiline label="recipe description" name="description" value={recipe.description} onChange={handleChange} InputProps={{ disableUnderline: props.isReadOnly, readOnly: props.isReadOnly }} ></TextField>}

                {(!props.isReadOnly || recipe.readyinMinutes) && <div style={{ marginTop: '10px' }}><TextField className="modify-recipe-minutes" InputLabelProps={{ shrink: true }} label="Time to ready (minutes)" name="readyInMinutes" type="number" value={recipe.readyInMinutes} onChange={handleIntChange} InputProps={{ disableUnderline: props.isReadOnly, readOnly: props.isReadOnly }} ></TextField></div>}


                {Capacitor.getPlatform() === 'web' ?
                    (!props.isReadOnly || recipe.tags.length > 0) && <div className="modify-recipe-tags">
                        <Select
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            label="tags"
                            value={recipe.tags.length > 0 ? recipe.tags : ["Select Tags"]}
                            name="tags"
                            onChange={handleChange}
                            style={{ marginLeft: '5px' }}
                            inputProps={props.isReadOnly ? { readOnly: props.isReadOnly, IconComponent: () => null, } : null}
                            disableUnderline
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                                <div className="chip-container">
                                    {selected.map((value) => (
                                        <Chip className="meal-hover-card-info-tag" key={value} label={value} />
                                    ))}
                                </div>
                            )}
                        >
                            <MenuItem disabled value="Select Tag">
                                <em>Select Tags</em>
                            </MenuItem>
                            {tags.map((tag) => (
                                <MenuItem key={tag} value={tag}>
                                    {tag}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    :
                    <div
                        onClick={() => {
                            setIsTagsActionSheetOpen(true)
                        }}
                        style={{ marginTop: '15px', fontSize: '15px' }}
                    >

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.0)', maxWidth: '330px', marginLeft: 'auto', marginRight: 'auto', marginTop: '7px' }}>
                            <div style={{ maxWidth: '280px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: '12px' }}>
                                {recipe.tags.map((value) => {
                                    return (<div className="meal-hover-card-info-tag" style={{ fontSize: '14px', padding: '5px 10px', background: 'rgb(129, 197, 36)', color: '#fff', borderRadius: "50px", margin: "2px" }}>
                                        {value}
                                    </div>

                                    )
                                })}
                                {recipe.tags.length === 0 && <div className="meal-hover-card-info-tag" style={{ fontSize: '14px', padding: '5px 10px', background: 'rgb(129, 197, 36)', color: '#fff', borderRadius: "50px", margin: "2px" }}>
                                    Select Tags
                                </div>}
                            </div>
                            <div style={{ height: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                <ArrowDropDownIcon style={{ width: '20px', height: '100%', color: '#c5c5c5' }}></ArrowDropDownIcon></div>
                        </div>
                    </div>
                }
                {Capacitor.getPlatform() === 'web' ?
                    (!props.isReadOnly || recipe.categories.length > 0) && <div className="modify-recipe-categories">

                        <Select
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            label="categories"
                            value={recipe.categories.length > 0 ? recipe.categories : ["Select Category"]}
                            name="categories"
                            onChange={handleChange}
                            style={{ marginLeft: '5px' }}
                            inputProps={props.isReadOnly ? { readOnly: props.isReadOnly, IconComponent: () => null, } : null}
                            disableUnderline
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                                <div className="chip-container">
                                    {selected.map((value) => (
                                        <Chip className="meal-hover-card-info-tag" key={value} label={value} />
                                    ))}
                                </div>
                            )}
                        >
                            <MenuItem disabled value="Select Category">
                                <em>Select Category</em>
                            </MenuItem>
                            {categories.map((category) => (
                                <MenuItem key={category} value={category}>
                                    {category}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    :
                    <div
                        onClick={() => {
                            setIsCategoriesActionSheetOpen(true)
                        }}
                        style={{ marginTop: '15px', fontSize: '15px' }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '330px', marginLeft: 'auto', marginRight: 'auto', marginTop: '7px' }}>
                            <div style={{ maxWidth: '280px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: '12px' }}>
                                {recipe.categories.map((value) => {
                                    return (<div className="meal-hover-card-info-tag" style={{ fontSize: '14px', padding: '5px 10px', background: 'rgb(129, 197, 36)', color: '#fff', borderRadius: "50px", margin: "2px" }}>
                                        {value}
                                    </div>

                                    )
                                })}
                                {recipe.categories.length === 0 && <div className="meal-hover-card-info-tag" style={{ fontSize: '14px', padding: '5px 10px', background: 'rgb(129, 197, 36)', color: '#fff', borderRadius: "50px", margin: "2px" }}>
                                    Select Categories
                                </div>}
                            </div>
                            <div style={{ height: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                <ArrowDropDownIcon style={{ width: '20px', height: '100%', color: '#c5c5c5' }}></ArrowDropDownIcon></div>
                        </div>
                    </div>
                }

                {Capacitor.getPlatform() === 'web' ?
                    (!props.isReadOnly || recipe.complexity) && <div className="modify-recipe-tags">Complexity:
                        <Select
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            label="complexity"
                            value={recipe.complexity ? recipe.complexity : ''}
                            name="complexity"
                            onChange={handleChange}
                            style={{ marginLeft: '5px' }}
                            inputProps={props.isReadOnly ? { readOnly: props.isReadOnly, IconComponent: () => null, } : null}
                            disableUnderline
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                                <div >

                                    <Chip className="meal-hover-card-info-tag" key={selected} label={selected} />

                                </div>
                            )}
                        // MenuProps={MenuProps}
                        >
                            {complexity.map((val) => (
                                <MenuItem key={val} value={val}>
                                    {val}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    :
                    <div
                        onClick={() => {
                            setIsComplexityActionSheetOpen(true)
                        }}
                        style={{ marginTop: '15px', fontSize: '15px' }}
                    >
                        Complexity:
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.015)', maxWidth: '330px', marginLeft: 'auto', marginRight: 'auto', marginTop: '7px' }}>
                            <div style={{ maxWidth: '280px', minWidth: '280px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: '12px' }}>
                                {recipe.complexity &&
                                    <div className="meal-hover-card-info-tag" style={{ fontSize: '14px', padding: '5px 10px', background: 'rgb(129, 197, 36)', color: '#fff', borderRadius: "50px", margin: "2px" }}>
                                        {recipe.complexity}
                                    </div>


                                }
                            </div>
                            <div style={{ height: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                <ArrowDropDownIcon style={{ width: '20px', height: '100%', color: '#c5c5c5' }}></ArrowDropDownIcon></div>
                        </div>
                    </div>
                }

                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    {props.creatingNewRecipe ? <button className="calendar-add-menu-meal-button" onClick={submitRecipeChange}>Create</button> : <>
                        {!props.isReadOnly && <button className="calendar-add-menu-meal-button" onClick={submitRecipeChange}>Submit Changes</button>}
                    </>}
                </div>

                <Dialog maxWidth={'xl'} open={Boolean(attemptedDeletion)} >
                    <div style={{ textAlign: 'center', margin: '30px' }}> Are you sure you want to delete this recipe?
                        <div style={{ color: 'grey', fontSize: '14px', margin: '10px 0px 0px 0px' }}>This action will delete the recipe from this recipe book. You cannot undo this.</div>
                        <div><Button className="modify-recipe-delete-button" onClick={handleDelete}>Remove</Button> <Button className="modify-recipe-cancel-button" onClick={() => { setAttemptedDeletion(false) }}>Cancel</Button> </div>
                    </div>

                </Dialog>

                {/* <Dialog maxWidth={'xl'} open={Boolean(attemptedCopy)} >
                    <div style={{ textAlign: 'center', margin: '30px' }}> Confirm copy
                        <div style={{ color: 'grey', fontSize: '14px', margin: '10px 0px 0px 0px' }}>This action will duplicate this recipe into your personal recipe book for you to customize</div>
                        <div><Button className="modify-recipe-save-button" onClick={handleCopy}>Copy and go</Button> <Button className="modify-recipe-cancel-button" onClick={() => { setAttemptedCopy(false) }}>Cancel</Button> </div>
                    </div>

                </Dialog> */}

                <CopyRecipeDialog scrollToTopOfRecipeBookList={props.scrollToTopOfRecipeBookList} attemptedCopy={attemptedCopy} setAttemptedCopy={setAttemptedCopy} close={props.close} recipe={recipe} setCreatingNewRecipe={props.setCreatingNewRecipe} setRecipeBookBeingModified={props.setRecipeBookBeingModified} />
                <CopyRecipeDialogBiteTracker scrollToTopOfRecipeBookList={props.scrollToTopOfRecipeBookList} attemptedCopy={attemptedCopyBT} setAttemptedCopy={setAttemptedCopyBT} close={props.close} recipe={recipe} setCreatingNewRecipe={props.setCreatingNewRecipe} setRecipeBookBeingModified={props.setRecipeBookBeingModified} />

                <SwipeableTemporaryDrawer
                    actionSheetOpen={isTagsActionSheetOpen}
                    setActionSheetOpen={setIsTagsActionSheetOpen}
                    list={tags}
                    onChange={(ind) => {
                        console.log('index: ', ind);
                        handleChangeTagAction(tags[ind])
                    }}
                    selected={recipe.tags}

                />

                <SwipeableTemporaryDrawer
                    actionSheetOpen={isCategoriesActionSheetOpen}
                    setActionSheetOpen={setIsCategoriesActionSheetOpen}
                    list={categories}
                    onChange={(ind) => {
                        console.log('index: ', ind);
                        handleChangeCategoriesAction(categories[ind])
                    }}
                    selected={recipe.categories}
                />

                <SwipeableTemporaryDrawer
                    actionSheetOpen={isComplexityActionSheetOpen}
                    setActionSheetOpen={setIsComplexityActionSheetOpen}
                    list={complexity}
                    onChange={(ind) => {
                        console.log('index: ', ind);
                        handleChangeComplexityAction(complexity[ind])
                    }}
                />
            </div>
        )

    }
}
