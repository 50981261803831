import './calendar_side_bar.css'
import React from 'react';
import CalorieBox from 'components/shared/modify_calories/calorie_box';

// import { UserContext } from 'contexts/user_context';
// import SelectDeliveryDay from '../select_delivery_day/select_delivery_day';
import { Button } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import CheckoutReadinessInfo from '../checkout_readiness_info/checkout_readiness_info';

const CalendarSideBar = (props) => {
    // const foodVendor = React.useContext(UserContext).userObject.foodVendor
    // function handleFoodVendorButtonClick() {
    //     props.setVendorDialogOpen(true)
    // }


    return (
        <div className="calendar-page-side-bar">
            <Button className="side-bar-collapse-button" onClick={props.toggleIsSideBarCollapsed}>
                <KeyboardArrowLeftIcon />
            </Button>
            <CalorieBox disableRecommendation={true} />
            {/* <div className="calendar-side-bar-delivery-service"><div className="food-menu-side-bar-delivery-service-header">Food provider</div>
             <Button onClick={handleFoodVendorButtonClick} className="side-bar-drop-down-button">{foodVendor} <ArrowDropDownIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} /></Button></div>
            <SelectDeliveryDay /> */}
            {/* <CheckoutReadinessInfo /> */}
       

        </div>
    )
}
export default CalendarSideBar;
