import React from 'react';
import { Button, Dialog, TextField, Tabs, Tab, IconButton } from '@material-ui/core';
import { useDebouncedCallback } from 'use-debounce';
import axios from 'axios';
import './ingredients_search_box.css';
import { Alert } from '@material-ui/lab';
import IngredientSearchRow from './ingredient_search_row';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import CloseIcon from '@material-ui/icons/Close';
import { CloseRounded } from '@material-ui/icons';
import IngredientBulkImportRow from './ingredient_bulk_import_row';


const IngredientsSearchBox = (props) => {
  const [ingredientsText, setIngredientsText] = React.useState("");
  const [bulkIngredientsText, setBulkIngredientsText] = React.useState("");
  const [ingredientsSearchList, setIngredientsSearchList] = React.useState([]);
  const [bulkIngredientsList, setBulkIngredientsList] = React.useState([]);
  const [hasFoundIngredients, setHasFoundIngredients] = React.useState(true);
  const [errorState, setErrorState] = React.useState(0);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [bulkButtonDisabled, setBulkButtonDisabled] = React.useState(false)

  const debounceQueryIngredients = useDebouncedCallback(
    async (ingredientText) => {
      setHasFoundIngredients(true);
      setErrorState(0);
      if (!ingredientText) {
        setIngredientsSearchList([]);
        return;
      }
      try {
        FirebaseAnalytics.logEvent({
          name: "searched_ingredient_for_recipe",
          params: { ingredientText: ingredientText }
        });
        const resp = await axios.get('/ingredient/search/' + ingredientText);
        if (resp.data.results.length === 0) {
          setHasFoundIngredients(false);
        }
        setIngredientsSearchList(resp.data.results);
      }
      catch {
        setErrorState(1);
      }
    },
    1000
  );

  const handleAddButtonClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogExitClick = () => {
    setIsDialogOpen(false);
  };

  const handleIngredientsTextChange = (e) => {
    setIngredientsText(e.target.value);
    debounceQueryIngredients(e.target.value);
  };

  const handleBulkIngredientsTextChange = (e) => {
    setBulkButtonDisabled(false)
    setBulkIngredientsText(e.target.value);
  };

  const handleBulkImport = async () => {
    try {
      const resp = await axios.post('/ingredient/bulkImport', {
        ingredientList: bulkIngredientsText,
        servings: 1,
        includeNutrition: true
      });
      setErrorState(0)
      for (let ing of resp.data) {
        ing['amountString'] = ing.amount.toString()
        ing['selectedUnit'] = ing.unit !== '' ? ing.unit : ing.possibleUnits[0]
      }
      setBulkIngredientsList(resp.data);
      setBulkButtonDisabled(true)
    } catch {
      setErrorState(1);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAddToRecipe = () => {
    // Filter out ingredients that are already in the recipe based on the unique id
    const newIngredients = bulkIngredientsList.filter(
      ingredient => !props.recipe.ingredients.some(
        existingIngredient => existingIngredient.id === ingredient.id
      )
    );

    // Add only the new ingredients to the recipe
    props.setRecipe({
      ...props.recipe,
      ingredients: props.recipe.ingredients.concat(newIngredients)
    });

    setIsDialogOpen(false);

    FirebaseAnalytics.logEvent({
      name: "bulk_added_ingredients_to_recipe"
    });
  }


  let rows = [];
  for (let index in ingredientsSearchList) {
    let ingredient = ingredientsSearchList[index];
    rows.push(
      <IngredientSearchRow ingredient={ingredient} recipe={props.recipe} setRecipe={props.setRecipe} setIngredientsList={setIngredientsSearchList} setIngredientsText={setIngredientsText} closeDialog={handleDialogExitClick} />
    );
  }

  return (
    <>
      <div className="modify-recipe-ingredients-search-box">
        <Button className="modify-recipe-instructions-add-button" variant="outlined" onClick={handleAddButtonClick}> Add Ingredient</Button>
      </div>
      <Dialog open={isDialogOpen} onClose={handleDialogExitClick} >
        <div className='add-ingredient-dialog'>
          <Button className='modify-recipe-exit-button' onClick={handleDialogExitClick}><CloseRounded fontSize="small" /></Button>
          <Tabs TabIndicatorProps={{ style: { backgroundColor: '#2D7933' } }} value={tabValue} onChange={handleTabChange}>

            <Tab label="Bulk Import Ingredients" />
            <Tab label="Search Ingredients" />
          </Tabs>

          {tabValue === 0 && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ margin: '20px 0px', fontSize: '14px', fontFamily: 'Montserrat-SemiBold' }}>Input your ingredients (one per line)</div>
              <TextField placeholder={`e.g. 1 cup of diced carrot
2 tbsp of honey
a dash of parsley
`} InputLabelProps={{ shrink: true }} label="Ingredients Text" className="ingredient-bulk-textfield" variant="outlined" multiline rows={4} value={bulkIngredientsText} onChange={handleBulkIngredientsTextChange}></TextField>
              {!bulkButtonDisabled && < button className="submit-button" style={{ marginTop: '10px', marginBottom: '20px' }} onClick={handleBulkImport}>Import Ingredients</button>}
              {/* Render bulk imported ingredients */}
              {bulkIngredientsList.length > 0 && (
                <div className="bulk-ingredients-list">
                  {bulkIngredientsList.map((ingredient, index) => (
                    <IngredientBulkImportRow key={ingredient.id} ingredient={ingredient} bulkIngredientsList={bulkIngredientsList}
                      setBulkIngredientsList={setBulkIngredientsList} index={index} isReadOnly={false} />
                  ))}
                  <div className="extract-recipe-dialog-form-submit-button"><button className="submit-button" style={{ marginTop: '5px' }} onClick={handleAddToRecipe} >Add to Recipe</button></div>

                </div>
              )}

              {!!errorState && <Alert severity="error">Error importing ingredients, try again</Alert>}
            </div>
          )}
          {tabValue === 1 && (
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <TextField placeholder="e.g. broccoli" InputLabelProps={{ shrink: true }} label="Search ingredients" className="ingredient-search-textfield" variant="outlined" value={ingredientsText} onChange={handleIngredientsTextChange}></TextField>
              {errorState ? <Alert severity="error">Error retrieving ingredients, try a different search term</Alert> : rows}
              {!hasFoundIngredients && <Alert severity="warning">No ingredients found for that search term!</Alert>}
            </div>
          )}
        </div>
      </Dialog >
    </>
  );
};
export default IngredientsSearchBox;
