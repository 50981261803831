//React, router
import React, { useContext } from 'react';

//context
import { UserContext } from 'contexts/user_context';
import {  Dialog } from '@material-ui/core';
import CalorieCalculator from '../calorie_calculator/calorie_calculator';

const CalorieMealSuggestion = (props) => {
    const getCalorieCategory = useContext(UserContext).getCalorieCategory;
    const isUsingMacros = useContext(UserContext).userObject.isUsingMacros
    const [calculatorDialogOpen, setCalculatorDialogOpen] = React.useState(false)

    function handleOpenCalculatorClick() {
        setCalculatorDialogOpen(true)
    }

    function handleCalculatorDialogExitClick() {
        setCalculatorDialogOpen(false)
    }

    let recommendation = null;

    switch (getCalorieCategory()) {
        case 1000:
            recommendation = <b>{"2 regular meals"}</b>
            break;
        case 1100:
            recommendation = <b>{"2 regular meals"}</b>
            break;
        case 1200:
            recommendation = <b>{"2 regular meals"}</b>
            break;
        case 1300:
            recommendation = <b>{"3 regular meals"}</b>
            break;
        case 1400:
            recommendation = <b>{"3 regular meals"}</b>
            break;
        case 1500:
            recommendation = <><b>{"3 regular meals"}</b> {"or"} <b>{"2 large meals"}</b></>
            break;
        case 1600:
            recommendation = <><b>{"3 regular meals and a snack"}</b> {"or"} <b>{"2 large meals and a snack"}</b></>
            break;
        case 1700:
            recommendation = <><b>{"2 regular meals and a large meal"}</b> {"or"} <b>{"2 large meals and a regular meal"}</b></>
            break;
        case 1800:
            recommendation = <><b>{"2 regular meals a large meal and a snack"}</b> {"or"} <b>{"2 large meals and a regular meal"}</b></>
            break;
        case 1900:
            recommendation = <><b>{"2 large meals and a regular meal"}</b> {"or"} <b>{"4 regular meals"}</b></>
            break;
        case 2000:
            recommendation = <><b>{"2 large meals and a regular meal"}</b> {"or"} <b>{"4 regular meals"}</b></>
            break;
        case 2100:
            recommendation = <><b>{"3 large meals"}</b> {"or"} <b>{"2 large meals, a regular meal and a snack"}</b></>
            break;
        case 2200:
            recommendation = <><b>{"3 large meals"}</b> {"or"} <b>{"2 large meals, a regular meal and a big snack"}</b></>
            break;
        case 2300:
            recommendation = <><b>{"3 large meals and a snack"}</b> {"or"} <b>{"3 regular meals and a large meal"}</b></>
            break;
        case 2400:
            recommendation = <><b>{"3 large meals and a big snack"}</b> {"or"} <b>{"2 regular meals and 2 large meals"}</b></>
            break;
        case 2500:
            recommendation = <><b>{"3 large meals and a big snack"}</b> {"or"} <b>{"2 regular meals and 2 large meals"}</b></>
            break;
        case 2600:
            recommendation = <><b>{"3 large meals a snack and a big snack"}</b> {"or"} <b>{"3 large meals and a regular meal"}</b></>
            break;
        case 2700:
            recommendation = <><b>{"3 large meals a snack and a big snack"}</b> {"or"} <b>{"3 large meals and a regular meal"}</b></>
            break;
        case 2800:
            recommendation = <><b>{"3 large meals and 2 big snacks"}</b> {"or"} <b>{"3 large meals and a regular meal"}</b></>
            break;
        case 2900:
            recommendation = <><b>{"3 large meals and 2 big snacks"}</b> {"or"} <b>{"4 large meals"}</b></>
            break;
        case 3000:
            recommendation = <b>{"4 large meals and a snack"}</b>
            break;
        case 3100:
            recommendation = <b>{"4 large meals and a snack"}</b>
            break;
        case 3200:
            recommendation = <b>{"4 large meals and a big snack"}</b>
            break;
        case 3300:
            recommendation = <b>{"4 large meals and a big snack"}</b>
            break;
        case 3400:
            recommendation = <b>{"4 large meals a snack and a big snack"}</b>
            break;
        case 3500:
            recommendation = <b>{"4 large meals a snack and a big snack"}</b>
            break;
        case 3600:
            recommendation = <b>{"4 large meals and 2 big snacks"}</b>
            break;
        case 3700:
            recommendation = <b>{"4 large meals and 2 big snacks"}</b>
            break;
        default:
            break;
    }
    if (props.disableRecommendation) {

        return (
            <>
                <div className="calorie-meal-suggestion-calculator-message" style={{justifySelf:'auto'}}>Want to recalc your macros? <button className="calorie-meal-suggestion-message-button" variant="outlined" onClick={handleOpenCalculatorClick}>Calculate</button></div>
                <Dialog open={calculatorDialogOpen} onClose={handleCalculatorDialogExitClick} >
                    <CalorieCalculator handleExitClick={handleCalculatorDialogExitClick} />
                </Dialog>
            </>
        )
    }
    else {
        return <>{recommendation ? <div className="calorie-meal-suggestion">We recommend getting {recommendation} for a complete day of meals</div> : <div className="calorie-meal-suggestion-calculator-message">{isUsingMacros ? "Don't know your macros?" : "Don't know your calories?"} Use this <a href="https://www.calculator.net/macro-calculator.html" target="_blank" rel="noopener noreferrer">online calculator</a></div>}</>
    }
}
export default CalorieMealSuggestion