import React from "react";
import "./landing_mobile.css";
import landingMobile from "images/landingMobile.mp4";
import appStoreBadge from "images/app_store_badge.png";
import playStoreBadge from "images/play_store_badge.png";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Navigation from 'components/shared/navigation/navigation';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

export const LandingMobile = () => {

    function logAppstoreClick(){
        FirebaseAnalytics.logEvent({
            name: "clicked_app_store_link",
            params: { windowWidth: window.innerWidth }
        })
    }

    function logPlaystoreClick(){
        FirebaseAnalytics.logEvent({
            name: "clicked_play_store_link",
            params: { windowWidth: window.innerWidth }
        })
    }

    function logTryOnWebClick(){
        FirebaseAnalytics.logEvent({
            name: "clicked_try_on_web",
            params: { windowWidth: window.innerWidth }
        })
    }

    return (
        <>                <Navigation />
            <div className="home-mobile">
                <div className="div">
                    <img className="ellipse" alt="Ellipse" src="ellipse-180.png" />
                    <div className="overlap-group">
                        <img className="rectangle" alt="Rectangle" src="rectangle-6348.png" />
                        <p className="plan-your-meals-for">
                            Plan your meals <br />
                            for the week
                        </p>
                        <p className="text-wrapper">
                            Meet your calorie/macro goals with a few clicks using our built-in food calendar, macro-friendly recipe book
                            and integration with Instacart, AmazonFresh and WholeFoods.
                        </p>
                        <img className="group" alt="Group" src="group-18441.png" />
                        <a onClick={logAppstoreClick} href="https://apps.apple.com/us/app/thebitetracker/id6473445195"><img className="landing-mobile-appstore-badge" alt="Group" src={appStoreBadge} /></a>
                        <a onClick={logPlaystoreClick} href="https://play.google.com/store/apps/details?id=com.thebitetracker.com"><img className="landing-mobile-playstore-badge" alt="Group" src={playStoreBadge} /></a>

                        <NavLink onClick={logTryOnWebClick} to="/calendar">
                            <img className="img" alt="Rectangle" src="rectangle-73.png" />
                            <div className="text-wrapper-2">Try it on web</div>
                            <img className="arrow" alt="Arrow" src="arrow-2.png" />

                        </NavLink>
                        <img className="image" alt="" src="image-4.png" />
                        <img className="group-2" alt="Group" src="group-48095625.png" />
                        <img className="group-3" alt="Group" src="group-48095631.png" />
                        <div className="text-wrapper-3">Choose or add recipes</div>
                        <div className="text-wrapper-4">How it works</div>
                        <p className="p">Get control of your nutrition</p>
                        <div className="master-your-meal">
                            Master your planning
                        </div>
                        <video className="landing-mobile-video" width="100%" height="100%" loop autoPlay={"autoplay"} playsInline muted defaultMuted src={landingMobile}> </video>

                        <p className="select-and-edit">
                            Select and edit recipes from our book of macro-conscious meals or add your own recipes into your profile. As
                            you add ingredients the macros and calories will
                            <br />
                            be automatically calculated.
                        </p>
                        <p className="text-wrapper-6">
                            Start crafting your perfect meal plan today and take charge of your nutrition.
                        </p>
                        <p className="text-wrapper-7">Plan and track your week</p>
                        <p className="use-our-built-in">
                            Use our built-in calendar to add meals from
                            <br />
                            the BiteTracker recipe book and from your personal recipe book. You can also keep track of branded and
                            generic food items by adding them to your calendar. Your calories/macros will be accounted for.
                        </p>
                        <img className="group-4" alt="Group" src="group-18442.png" />
                        <div className="prepare-and-enjoy">
                            Prepare & enjoy
                            your meals
                        </div>
                        <p className="text-wrapper-8">Follow our simple recipes with the freshest ingredients of your choice.
                            <br />
                            <br />

                            You can also use the BiteTracker Calendar to account for any additional foods you are consuming in a single place.</p>
                        <img className="group-5" alt="Group" src="choose-or-add.png" />
                        <div className="text-wrapper-9">Order ingredients</div>
                        <p className="text-wrapper-10">
                            Order ingredients for your personal and BiteTracker recipes via Instacart, AmazonFresh or WholeFoods. The ingredients of the recipes are automatically linked to your shopping cart.                    </p>
                        <img className="group-6" alt="Group" src="group-6356147.png" />
                        <img className="element" alt="Element" src="3857131-2.png" />
                        <img
                            className="eat-according-your"
                            alt="Eat according your"
                            src="eat-according-your-goals-with-easy-planning.png"
                        />
                        <img className="save-time-grocery" alt="Save time grocery" src="save-time-grocery-shopping.png" />
                        <img className="feel-organized-and" alt="Feel organized and" src="feel-organized-and-in-charge.png" />
                        <img
                            className="reduce-food-waste"
                            alt="Reduce food waste"
                            src="reduce-food-waste-buying-exactly-what-you-need.png"
                        />
                        <img className="group-7" alt="Group" src="group-48095632.png" />
                        <div className="craft-your-perfect">
                            Craft your
                            Perfect Plate
                        </div>
                        <p className="text-wrapper-11">
                            Manage your meals by tracking calories, proteins, fats, and carbs, allowing you to craft a personalized,
                            well-balanced weekly meal plan that aligns perfectly with your nutritional goals
                        </p>
                        <img className="group-8" alt="Group" src="group-48095628.png" />
                        <div className="create-and-modify">
                            Create and
                            modify recipes
                        </div>
                        <p className="create-dishes-that">
                            Create dishes that resonate with your unique palate. You can add a recipe from scratch or copy/edit
                            BiteTracker recipes by modifying ingredients and quantities. You will be able to track calories and macros
                            dynamically and use our platform to store your recipes.
                            <br />
                            <br />
                            Ready to put your personal touch on your meals?
                        </p>
                        <img className="group-9" alt="Group" src="group-48095626.png" />
                        <div className="text-wrapper-12">Support by</div>
                        <img className="group-10" alt="Group" src="amazonAndWholeFoods.png" />
                        <img className="rectangle-2" alt="Rectangle" src="rectangle-39442.png" />
                        <div className="text-wrapper-13">Get ingredients same-day</div>
                        <p className="as-soon-as-you-craft">
                            As soon as you craft your weekly plan, simply order your ingredients via Instacart, AmazonFresh or
                            WholeFoods. <br />
                            <br />
                            This includes ingredients from recipes you created personally as well as any BiteTracker recipes that you
                            added into your calendar.
                        </p>
                        <img className="capa" alt="Capa" src="capa-1.png" />
                        <img className="text" alt="Text" src="text.png" />
                        <img className="group-11" alt="Group" src="group-48095627.png" />
                        <img className="rectangle-3" alt="Rectangle" src="rectangle-39443.png" />

                    </div>

                    <a href="https://apps.apple.com/us/app/thebitetracker/id6473445195"><img className="landing-mobile-appstore-badge-bottom" alt="Group" src={appStoreBadge} /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.thebitetracker.com"><img className="landing-mobile-playstore-badge-bottom" alt="Group" src={playStoreBadge} /></a>

{/* 
                    <NavLink to="/calendar">
                        <img className="img-bottom" alt="Rectangle" src="rectangle-73.png" />
                        <div className="text-wrapper-2-bottom">Try it now</div>
                        <img className="arrow-bottom" alt="Arrow" src="arrow-2.png" />

                    </NavLink> */}
                    <img className="frame" alt="Frame" src="frame-3451.png" />

                    <img className="a" alt="A" src="a.png" />
                    <div className="text-wrapper-16">SNEAK PEAK</div>
                    <p className="text-wrapper-17">200+ macro-friendly recipes</p>
                    <img className="grilled-salmon" alt="Grilled salmon" src="grilled-salmon.png" />
                    <img className="cauliflower-puree" alt="Cauliflower puree" src="cauliflower-puree.png" />
                    <img className="BT-shiitake" alt="Bt shiitake" src="bt-shiitake-mushrooms.png" />
                    <img className="sweet-potato-fries" alt="Sweet potato fries" src="sweet-potato-fries.png" />
                    <img className="grilled-chicken" alt="Grilled chicken" src="grilled-chicken-breast.png" />
                    <img className="oatmeal-pancakes" alt="Oatmeal pancakes" src="oatmeal-pancakes.png" />
                </div>
                <div className="overlap">
                    <p className="landing-mobile-contact-us">
                        <NavLink to="contact_us"><span style={{ color: 'white' }}> Contact Us</span></NavLink> <NavLink to="blog"><span style={{ color: 'white' }}> Blog</span></NavLink>

                    </p>
                    <p className="text-wrapper-15">
                        © 2023 BiteTracker | All Rights Reserved <br></br>
                        <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=823334ce-e563-427c-a0bf-93463b10dea3"><span style={{ color: 'white' }}> Privacy Policy</span></a> ||
                        <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=713d822d-3691-46a8-a4f7-5a538d76124b"><span style={{ color: 'white' }}> Terms of Use</span></a>
                        <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=f52f48b4-b14d-4771-8156-f35d484da8dc"><span style={{ color: 'white' }}> Cookie Policy</span></a>

                    </p>

                    {/* <img className="vector" alt="Vector" src="vector.png" />
                    <img className="vector-2" alt="Vector" src="image.png" />
                    <img className="vector-3" alt="Vector" src="vector-2.png" /> */}
                </div>
            </div>        </>

    );
};
