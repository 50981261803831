// projection_calculations.js

export const calculateProjections = ({
    currentWeight,
    goalWeight,
    currentBodyFat,
    goalBodyFat,
    dailyCalories,
    macroDistribution,
    activityLevel,
    cardioActivity,
    muscleBuildingActivity,
    userObject,
}) => {
    let warning = '';
    let dataPoints = [];
    let goalDate = '';
    let bodyFatGoalDate = '';
    let weightGoalMetWeek = null;
    let bodyFatGoalMetWeek = null;
    let includeBodyFat =
        currentBodyFat !== undefined &&
        currentBodyFat !== null &&
        currentBodyFat !== '';

    // Check for necessary inputs
    if (!currentWeight || !goalWeight || !dailyCalories) {
        warning =
            'Please enter your current weight, goal weight, and daily calories.';
        return { dataPoints, goalDate, warning };
    }

    // Convert units if necessary
    const weightUnit = userObject.weightUnit || 'lbs';
    const heightUnit = userObject.heightUnit || 'cm';

    const currentWeightKg =
        weightUnit === 'kg' ? currentWeight : currentWeight * 0.453592;
    const goalWeightKg =
        weightUnit === 'kg' ? goalWeight : goalWeight * 0.453592;

    let heightCm;
    if (heightUnit === 'cm') {
        heightCm = userObject.height;
    } else {
        // Convert feet and inches to cm
        heightCm =
            ((parseFloat(userObject.heightFeet || 0) * 12) +
                parseFloat(userObject.heightInches || 0)) *
            2.54;
    }

    // Basal Metabolic Rate (BMR) using Mifflin-St Jeor Equation
    const age = userObject.age || 30;
    const sex = userObject.sex || 'male';

    let BMR;
    if (sex === 'male') {
        BMR = 10 * currentWeightKg + 6.25 * heightCm - 5 * age + 5;
    } else {
        BMR = 10 * currentWeightKg + 6.25 * heightCm - 5 * age - 161;
    }

    // Total Daily Energy Expenditure (TDEE)
    let TDEE = BMR * activityLevel * cardioActivity * muscleBuildingActivity;

    // Calculate macronutrient grams
    const proteinPercentage = macroDistribution.protein / 100;
    const carbsPercentage = macroDistribution.carbs / 100;
    const fatPercentage = macroDistribution.fat / 100;

    let dailyProteinGrams = (dailyCalories * proteinPercentage) / 4;
    let dailyCarbsGrams = (dailyCalories * carbsPercentage) / 4;
    let dailyFatGrams = (dailyCalories * fatPercentage) / 9;
    let proteinIntakePerKg = dailyProteinGrams / currentWeightKg;

    // Determine if protein intake is sufficient
    const sufficientProtein = proteinIntakePerKg >= 1.6;

    // Determine if resistance training is included
    const resistanceTraining = muscleBuildingActivity > 1.0;

    // Thermic Effect of Food (TEF)
    let TEF =
        dailyProteinGrams * 4 * 0.25 +
        dailyCarbsGrams * 4 * 0.08 +
        dailyFatGrams * 9 * 0.02;

    // Adjust TDEE for TEF
    TDEE += TEF;

    // Initialize variables
    let week = 0;
    let date = new Date();
    const maxWeeks = 52; // Reverted back to 1 year

    // For weight change calculation
    let totalWeightKg = currentWeightKg || 0; // Ensure it's a number

    // For body composition, if available
    let fatMassKg;
    let leanMassKg;

    if (includeBodyFat) {
        fatMassKg = (currentWeightKg * currentBodyFat) / 100;
        leanMassKg = currentWeightKg - fatMassKg;
    }

    // Validate inputs
    if (
        isNaN(currentWeightKg) ||
        isNaN(goalWeightKg) ||
        isNaN(dailyCalories) ||
        currentWeightKg <= 0 ||
        goalWeightKg <= 0 ||
        dailyCalories <= 0
    ) {
        warning = 'Invalid input values provided.';
        return { dataPoints, goalDate, warning };
    }

    // Metabolic adaptation parameters
    const metabolicAdaptationInitialRate = 0.01; // Starting adaptation rate
    const metabolicAdaptationDecay = 0.0001; // Rate at which adaptation slows

    // Helper function for non-linear fat loss efficiency using a logistic function
    const calculateFatLossEfficiency = (bodyFatPercentage) => {
        // Logistic function parameters
        const L = 1.0; // Maximum efficiency
        const k = 0.1; // Growth rate
        const x0 = 25; // Midpoint (body fat percentage)

        // Logistic function: L / (1 + e^(-k(x - x0)))
        return L / (1 + Math.exp(-k * (bodyFatPercentage - x0))) * 0.5 + 0.5; // Scale between 0.5 and 1.0
    };

    // Helper function for gradated muscle gain efficiency
    const calculateMuscleGainEfficiency = (proteinIntake, resistanceTraining) => {
        let efficiency = 0.5; // Base efficiency

        // Increment based on protein intake
        if (proteinIntake >= 1.6) {
            efficiency += ((proteinIntake - 1.6) / 0.4) * 0.3; // Max add 0.3
        }

        // Increment if resistance training is present
        if (resistanceTraining) {
            efficiency += 0.2;
        }

        // Clamp between 0.5 and 1.0
        return Math.min(Math.max(efficiency, 0.5), 1.0);
    };

    // Helper function for metabolic adaptation
    const calculateMetabolicAdaptation = (initialRate, totalWeightLost, week) => {
        // Exponential decay to simulate slowing adaptation
        return initialRate / (1 + metabolicAdaptationDecay * week);
    };

    // Initialize metabolic adaptation factor
    let cumulativeWeightLostKg = 0;

    while (week < maxWeeks) {
        // Recalculate BMR and TDEE each week
        if (week > 0) {
            if (includeBodyFat) {
                if (sex === 'male') {
                    BMR =
                        10 * (fatMassKg + leanMassKg) +
                        6.25 * heightCm -
                        5 * age +
                        5;
                } else {
                    BMR =
                        10 * (fatMassKg + leanMassKg) +
                        6.25 * heightCm -
                        5 * age -
                        161;
                }
            } else {
                if (sex === 'male') {
                    BMR = 10 * totalWeightKg + 6.25 * heightCm - 5 * age + 5;
                } else {
                    BMR = 10 * totalWeightKg + 6.25 * heightCm - 5 * age - 161;
                }
            }

            // Apply metabolic adaptation using exponential decay
            const metabolicAdaptation = 1 - calculateMetabolicAdaptation(
                metabolicAdaptationInitialRate,
                cumulativeWeightLostKg,
                week
            );

            // Ensure metabolicAdaptation stays within a reasonable range
            const metabolicAdaptationClamped = Math.min(
                Math.max(metabolicAdaptation, 0.5),
                1.2
            );

            TDEE =
                BMR *
                activityLevel *
                cardioActivity *
                muscleBuildingActivity *
                metabolicAdaptationClamped;

            // Recalculate macronutrients if weight has changed significantly
            dailyProteinGrams = (dailyCalories * proteinPercentage) / 4;
            dailyCarbsGrams = (dailyCalories * carbsPercentage) / 4;
            dailyFatGrams = (dailyCalories * fatPercentage) / 9;
            proteinIntakePerKg = dailyProteinGrams / totalWeightKg;

            // Update TEF with current macronutrients
            TEF =
                dailyProteinGrams * 4 * 0.25 +
                dailyCarbsGrams * 4 * 0.08 +
                dailyFatGrams * 9 * 0.02;

            TDEE += TEF;
        }

        // Daily Calorie Deficit or Surplus
        const calorieDeficit = dailyCalories - TDEE;

        // Estimated Weight Change per Week
        const weeklyWeightChangeKg = (calorieDeficit * 7) / 7700;

        // Update cumulative weight lost
        if (weeklyWeightChangeKg < 0) {
            cumulativeWeightLostKg += Math.abs(weeklyWeightChangeKg);
        }

        // Update total weight
        totalWeightKg += weeklyWeightChangeKg;

        // Prevent totalWeightKg from being negative or NaN
        if (isNaN(totalWeightKg) || totalWeightKg <= 0) {
            totalWeightKg = 0.1; // Set a minimum weight to prevent division by zero
        }

        // Prevent totalWeightKg from being unrealistically low
        const minWeightKg = 30; // Minimum reasonable weight
        if (totalWeightKg < minWeightKg) totalWeightKg = minWeightKg;

        if (includeBodyFat) {
            // Adjust fat loss efficiency based on current body fat percentage using logistic function
            const currentBodyFatPercentage =
                (fatMassKg / (fatMassKg + leanMassKg)) * 100;

            const fatLossEfficiency = calculateFatLossEfficiency(
                currentBodyFatPercentage
            );

            // Adjust muscle gain efficiency based on protein intake and resistance training
            const muscleGainEfficiency = calculateMuscleGainEfficiency(
                proteinIntakePerKg,
                resistanceTraining
            );

            // Adjust fat and muscle mass changes based on efficiency
            let fatMassChangeKg;
            let muscleMassChangeKg;

            if (weeklyWeightChangeKg < 0) {
                // Weight loss
                fatMassChangeKg = weeklyWeightChangeKg * fatLossEfficiency;
                muscleMassChangeKg = weeklyWeightChangeKg * (1 - fatLossEfficiency);
            } else {
                // Weight gain
                fatMassChangeKg = weeklyWeightChangeKg * (1 - muscleGainEfficiency);
                muscleMassChangeKg = weeklyWeightChangeKg * muscleGainEfficiency;
            }

            // Update fat mass and lean mass
            fatMassKg += fatMassChangeKg;
            leanMassKg += muscleMassChangeKg;

            // Prevent negative fat or lean mass
            const essentialBodyFat = sex === 'male' ? 5 : 12; // Essential fat percentages
            const minFatMassKg =
                (essentialBodyFat / 100) * (fatMassKg + leanMassKg);
            if (fatMassKg < minFatMassKg) {
                fatMassKg = minFatMassKg;
            }
            if (leanMassKg < 0) leanMassKg = 0;

            // Update total weight
            totalWeightKg = fatMassKg + leanMassKg;

            // Prevent totalWeightKg from being negative or unrealistically low
            if (totalWeightKg < minWeightKg) totalWeightKg = minWeightKg;

            // Update body fat percentage
            const updatedBodyFatPercentage =
                (fatMassKg / totalWeightKg) * 100;

            // Record data point with bodyFat
            dataPoints.push({
                date: date.getTime(),
                weight:
                    weightUnit === 'kg'
                        ? parseFloat(totalWeightKg.toFixed(2))
                        : parseFloat((totalWeightKg / 0.453592).toFixed(2)),
                bodyFat: parseFloat(updatedBodyFatPercentage.toFixed(2)),
            });

            // Check if body fat goal is met
            if (goalBodyFat && bodyFatGoalMetWeek === null) {
                if (
                    (goalBodyFat < currentBodyFat &&
                        updatedBodyFatPercentage <= goalBodyFat) ||
                    (goalBodyFat > currentBodyFat &&
                        updatedBodyFatPercentage >= goalBodyFat)
                ) {
                    bodyFatGoalMetWeek = week;
                }
            }
        } else {
            // Record data point without bodyFat
            dataPoints.push({
                date: date.getTime(),
                weight:
                    weightUnit === 'kg'
                        ? parseFloat(totalWeightKg.toFixed(2))
                        : parseFloat((totalWeightKg / 0.453592).toFixed(2)),
            });
        }

        // Check if weight goal is met
        if (weightGoalMetWeek === null) {
            if (
                (goalWeightKg < currentWeightKg &&
                    totalWeightKg <= goalWeightKg) ||
                (goalWeightKg > currentWeightKg &&
                    totalWeightKg >= goalWeightKg)
            ) {
                weightGoalMetWeek = week;
            }
        }

        // Check if both goals are met (or only weight goal if body fat is not considered)
        const goalsMet = includeBodyFat && goalBodyFat
            ? weightGoalMetWeek !== null && bodyFatGoalMetWeek !== null
            : weightGoalMetWeek !== null;

        if (goalsMet) {
            // Goals are met, break out of the loop
            break;
        }

        // Increment week and date
        week++;
        date.setDate(date.getDate() + 7);
    }

    // Final projected weight
    const finalDataPoint = dataPoints[dataPoints.length - 1];
    const finalWeight = finalDataPoint.weight;
    const finalBodyFat = includeBodyFat ? finalDataPoint.bodyFat : null;

    // Projected Goal Dates
    goalDate = null;
    bodyFatGoalDate = null;

    if (weightGoalMetWeek !== null) {
        const projectedDate = new Date();
        projectedDate.setDate(projectedDate.getDate() + weightGoalMetWeek * 7);
        goalDate = projectedDate.toLocaleDateString();
    }

    if (includeBodyFat && goalBodyFat && bodyFatGoalMetWeek !== null) {
        const projectedBFDate = new Date();
        projectedBFDate.setDate(projectedBFDate.getDate() + bodyFatGoalMetWeek * 7);
        bodyFatGoalDate = projectedBFDate.toLocaleDateString();
    }

    // Health Warnings
    const minCalories = sex === 'male' ? 1500 : 1100;
    if (dailyCalories < minCalories) {
        warning += ` <p class="calculator-warning-message"> Your daily calorie intake is too low and may be unsafe. Minimum recommended is ${minCalories} calories.</p>`;
    }


    if (!resistanceTraining) {
        warning +=
            ' <p class="calculator-warning-message"> Doing more resistance training may help maintain a higher metabolism due to muscle mass growth/retention.</p>';
    }

    if (proteinIntakePerKg < 1.6) {
        warning +=
            '<p class="calculator-warning-message">  Your protein intake is lower than recommended, this may lead to muscle loss and slower metabolism.</p>';
    }
    warning = warning.trim().replace(/<br \/>$/, '');

    return {
        dataPoints,
        goalDate,
        bodyFatGoalDate,
        warning: warning.trim(),
        weightGoalMetWeek,
        bodyFatGoalMetWeek,
        finalWeight,
        finalBodyFat,
    };
};
