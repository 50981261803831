import './order_ingredients.css'
import React from 'react';
import { CartContext } from 'contexts/cart_context';
import LoopIcon from '@material-ui/icons/Loop';

import { Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AuthContext } from 'contexts/auth_context';

import AmazonLogo from 'images/amazon-button-logo.png'
import WholeFoodsLogo from 'images/whole-foods-button-logo.png'
import InstacartLogo from 'images/instacart-button-logo.png'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { getWeeks } from 'helpers/date_helpers';
import WeekSelector from '../week_selector/week_selector';
import ShoppingListDialog from './shopping_list_dialog';


const weeks = getWeeks(5);


const OrderIngredients = () => {
	// const [cartObject, setCartObject] = React.useContext(CartContext);
	const generateAmazonLink = React.useContext(CartContext).generateAmazonLink
	const generateInstacartPage = React.useContext(CartContext).generateInstacartPage
	const [isAmazonLoading, setIsAmazonLoading] = React.useState(false)
	const [isInstacartLoading, setIsInstacartLoading] = React.useState(false)
	const [isWholeFoodsLoading, setIsWholeFoodsLoading] = React.useState(false)
	const { currentUser } = React.useContext(AuthContext)
	const [selectedWeek, setSelectedWeek] = React.useState(weeks[0].startOfWeek);
	const [errorState, setErrorState] = React.useState(0)
	const cookingRecipeList = React.useContext(CartContext).cookingRecipeList
	const [isShoppingListOpen, setIsShoppingListOpen] = React.useState(false)

	async function handleOrderWithAmazonButtonClick() {
		setIsAmazonLoading(true)
		try {
			await generateAmazonLink(selectedWeek)
			setIsAmazonLoading(false)
			FirebaseAnalytics.logEvent({
				name: "initiated_order",
				params: { source: 'amazon', value: '2', currency: 'USD', currentUser: currentUser.uid }
			})
		}
		catch (e) {
			console.log(e)
			setErrorState(1)
			setIsAmazonLoading(false)
		}


	}

	async function handleOrderWithWholeFoodsButtonClick() {
		setIsWholeFoodsLoading(true)
		try {
			await generateAmazonLink(selectedWeek, true)
			setIsWholeFoodsLoading(false)
			FirebaseAnalytics.logEvent({
				name: "initiated_order",
				params: { source: 'whole foods', value: '2', currency: 'USD', currentUser: currentUser.uid }
			})

		}
		catch (e) {
			console.log(e)
			setErrorState(1)
			setIsWholeFoodsLoading(false)
		}


	}
	async function handleOrderWithInstacartButtonClick() {
		setIsInstacartLoading(true)
		try {
			await generateInstacartPage(selectedWeek)
			setIsInstacartLoading(false)
			FirebaseAnalytics.logEvent({
				name: "initiated_order",
				params: { source: 'instacart', value: '2', currency: 'USD', currentUser: currentUser.uid }
			})

		}
		catch (e) {
			console.log(e)
			setErrorState(1)
			setIsInstacartLoading(false)
		}
	}

	let recipeList = []

	for (let index in cookingRecipeList) {
		let recipe = cookingRecipeList[index]
		if (recipe.week === selectedWeek) {
			recipeList.push(
				<div key={index} style={{ textAlign: 'center', color: 'grey', margin: '10px' }}>
					{recipe.name}

				</div>)
		}
	}

	function handleViewShoppingListClick(){
		setIsShoppingListOpen(true)
		FirebaseAnalytics.logEvent({
			name: "opened_shopping_list",
			params: { currentUser: currentUser.uid }
		})
	}

	return (
		<>
			<div style={{ textAlign: 'center', display:'flex', flexDirection:'column', alignItems:'center' }}>
				<WeekSelector weeks={weeks} selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} />

				{recipeList.length > 0 ? <div ><div style={{ textAlign: 'center', fontFamily: 'Montserrat-SemiBold' }}>Recipes</div>{recipeList} </div> :
                    <div style={{fontFamily:'Montserrat', marginTop:'10px', marginBottom:'10px'}}>No recipes planned for this week</div>
				}
				<Tooltip title={recipeList.length > 0 ? "View your shopping list" : "Add recipes to the chosen weeks first"}>
					<div>
						<button disabled={recipeList.length === 0} onClick={handleViewShoppingListClick} style={recipeList.length === 0 ? { color: '#666666', background:"#d6d6d6" } : {}} className="shopping-list-button" variant="contained">
							View Ingredient Shopping List
						</button>
					</div>
				</Tooltip>

				<Tooltip title={recipeList.length > 0 ? "Order the ingredients of the added recipes via Instacart" : "Add recipes to the chosen weeks to order"}>
					<div>
						<button disabled={recipeList.length === 0} onClick={handleOrderWithInstacartButtonClick} className="order-in-instacart-button" variant="contained">
							{isInstacartLoading ? <> <LoopIcon style={{ animation: 'spin 2s linear infinite' }} /></> : <>Get Ingredients from
								<img className="instacart-order-ingredients-button-image" style={recipeList.length === 0 ? { filter: 'brightness(0.5)' } : {}} src={InstacartLogo} alt={"instacart-logo"} /></>}
						</button>
					</div>
				</Tooltip>
				<Tooltip title={recipeList.length > 0 ? "Order the ingredients of the added recipes via AmazonFresh" : "Add recipes to the chosen weeks to order"}>
					<div>

						<button disabled={recipeList.length === 0} onClick={handleOrderWithAmazonButtonClick} className="order-in-amazon-button" variant="contained">
							{isAmazonLoading ? <> <LoopIcon style={{ animation: 'spin 2s linear infinite' }} /></> : <>Get Ingredients from
								<img className="amazon-order-ingredients-button-image" style={recipeList.length === 0 ? { filter: 'brightness(0.5)' } : {}} src={AmazonLogo} alt={"amazon-logo"} /></>}
						</button>
					</div>
				</Tooltip>
				<Tooltip title={recipeList.length > 0 ? "Order the ingredients of the added recipes via Wholefoods" : "Add recipes to the chosen weeks to order"}>
					<div>

						<button disabled={recipeList.length === 0} onClick={handleOrderWithWholeFoodsButtonClick} className="order-in-wholefoods-button" variant="contained">
							{isWholeFoodsLoading ? <> <LoopIcon style={{ animation: 'spin 2s linear infinite' }} /></> : <>Get Ingredients from
								<img className="whole-foods-order-ingredients-button-image" style={recipeList.length === 0 ? { filter: 'brightness(0.5)' } : {}} src={WholeFoodsLogo} alt={"wholefoods-logo"} /></>}
						</button>
					</div>
				</Tooltip>
				{errorState === 1 && <Alert severity="error" onClose={() => { setErrorState(0) }}>Error loading your ingredients, please try again in a few minutes</Alert>}


			</div>
			{isShoppingListOpen && <ShoppingListDialog week={selectedWeek} onClose={()=>{setIsShoppingListOpen(false)}}></ShoppingListDialog>}
		</>
	)
}
export default OrderIngredients;
