import React from 'react';
import { Button, TextField } from '@material-ui/core'
import _ from 'lodash';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Draggable } from 'react-beautiful-dnd';


const ModifyRecipeInstructionsRow = (props) => {
    let instructionSteps = props.recipe.analyzedInstructions.steps
    let instructionStepIndex = props.instructionStepIndex
    let instructionStep = props.instructionStep


    const handleStepTextChange = (event) => {
        let recipeClone = _.cloneDeep(props.recipe)
        let instructionStepsClone = instructionSteps.concat([])
        instructionStepsClone[event.target.name].step = event.target.value
        recipeClone['analyzedInstructions']['steps'] = instructionStepsClone
        props.setRecipe(recipeClone)
        props.setStagedChanges(true)

        // event.target.value
    };

    const deleteInstruction = (event) => {
        let recipeClone = _.cloneDeep(props.recipe)
        let instructionStepsClone = instructionSteps.concat([])
        instructionStepsClone.splice(parseInt(props.instructionStepIndex), 1)
        recipeClone['analyzedInstructions']['steps'] = instructionStepsClone
        props.setRecipe(recipeClone)
        props.setStagedChanges(true)

    };


    return (
        <Draggable key={instructionStepIndex} draggableId={instructionStepIndex} index={parseInt(instructionStepIndex)} isDragDisabled={props.isReadOnly}>
            {(provided) => (
                <div className="modify-recipe-instruction-row" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                    {!props.isReadOnly && <DragIndicatorIcon style={{color:'grey'}}/>}
                    <span className="modify-recipe-recipe-instruction-number">{(parseInt(instructionStepIndex) + 1)}</span>
                    <TextField multiline className="modify-recipe-instruction-textfield" name={instructionStepIndex} style={{marginBottom:props.isReadOnly?'-3px':'0px'}} value={instructionStep.step} onChange={handleStepTextChange} InputProps={{ disableUnderline: props.isReadOnly, readOnly: props.isReadOnly }}  />
                    {!props.isReadOnly && <Button className="modify-recipe-ingredient-delete-button" variant="outlined" onClick={deleteInstruction}>X</Button>}
                </div>
            )}

        </Draggable>

    )
}
export default ModifyRecipeInstructionsRow